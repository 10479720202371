import { useState } from "react";
import { useQueryClient } from "react-query";
import styled from "styled-components";
import Pagination from "../../components/Pagination";
import { Sidebar } from "../../components/Sidebar";
import { NavHeader } from "../../components/NavHeader";
import Filter from "../../components/Filter";

export const PendingVerification = () => {
    const [search, setSearch] = useState("");
    const [searchFilter, setSearchFilter] = useState<any>()
  const queryClient = useQueryClient();
  const allRiders: any = queryClient.getQueryData("PENDINGRIDERS");
  const getSearchedArray = allRiders?.filter((item: any) =>
    item?.fullName.toLowerCase().includes(search.toLowerCase())
  );
 
  return (
    <Body>
      <Sidebar />
      <Wrapper>
        <NavHeader title="Pending Verification" />
        {/* <Filter handleSearchFilter={setSearchFilter}/> */}
        <Table>
          {getSearchedArray ? (
            <Pagination type={'pendingriders'} data={getSearchedArray} pageLimit={5} dataLimit={10} />
          ) : null}
        </Table>
      </Wrapper>
    </Body>
  );
};
const Body = styled.main`
  display: flex;
`;
const Wrapper = styled.aside`
  width: 85vw;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  margin-left: 15vw;
  background-color: #fafafa;
`;

const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border: 7px solid red;
  border-radius: 50%;
`;
const Pending = styled.div`
  background: #fef6f0;
  border: 1px solid #ffa35f;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px auto;
  width: 86%;
  padding: 5px 2%;
`;
const PendingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const Text = styled.p`
    font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  color: #fa7f24;
`;
const PendingNumber = styled.p`
    font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  color: #8c8888;
`;
const ViewPending = styled.button`
  width: 106px;
  height: 42px;
  background: #fa7f24;
  border-radius: 12px;
    font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  /* identical to box height */
  border: none;
  outline: none;
  color: #ffffff;
  cursor: pointer;
`;
const SearchIcon = styled.p`
  height: 20px;
  width: 20px;
  margin-left: 2%;
`;

const InputWrapper = styled.div`
  width: 90%;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 5px;
`;
const Inputs = styled.input`
  width: 100%;
  background-color: #ffffff;
  border: none;
  outline: none;
  padding: 10px 0;
  border-radius: 8px;
  padding-left: 1%;
`;
const Table = styled.main`
  margin: 20px auto;
  width: 90%;
`;
const Row = styled.div`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 16px;
  margin: 10px 0;
`;

const ID = styled.p``;

const Types = styled.p`
  color: #000000;
`;

const ViewDetails = styled.p`
  cursor: pointer;
  color: #3f4196;
`;
