import React, { useEffect, useState } from 'react';
import { DrawingManager } from '@react-google-maps/api';
import { useQueryClient } from "react-query";

const MyPolygon = (props) => {
    const QueryClient = useQueryClient();
    const [google, setGoogle] = useState(null);

  useEffect(() => {
    if (window.google) {
      setGoogle(window.google);
    } else {
      const script = document.createElement('script');
      script.src =
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyBY-n1MeFROu7RsloOqyhnPmsjmDBRYXBA&libraries=geometry,drawing';
      document.head.appendChild(script);
      script.addEventListener('load', () => {
        setGoogle(window.google);
      });
    }
  }, []);
  const onPolygonComplete = (polygon) => {
    const latLngArray = polygon
      .getPath()
      .getArray()
      .map((latLng) => {
        return [ latLng.lat(), latLng.lng() ];
      });
      latLngArray.push(latLngArray[0])
      QueryClient.setQueryData('PATHS', latLngArray)
    // setLatLngs(latLngArray);
  };
  const onLoad = (data) => {

  };

  if (!google) {
    return null;
  }
  return (
    <DrawingManager
    onLoad={onLoad}
    onPolygonComplete={onPolygonComplete}
    drawingControl={true}
    editable
    options={{
      editable: true,
      visible: true,
      drawingControlOptions: {
        // eslint-disable-next-line no-undef
        position: google.maps.ControlPosition.TOP_CENTER,
      },
    }}
  />
  );
};

export default MyPolygon;
