/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import styled from "styled-components";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { getRiderName } from "../utils/utils";
import TransactionHistory from "../assets/transactionhistory.svg";

export const Sidebar = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const [pathname, setPathname] = React.useState(location.pathname);
  const newPathName = () => {
    setPathname(location.pathname);
  };
  const Baseurl = process.env.REACT_APP_BASEURL;
  const getToken: any = localStorage.getItem("eftd-admin-token");
  const tokenObject: any = JSON.parse(getToken);
  const token = tokenObject?.data?.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const getRiders = async () => {
    const response = await axios.get(
      `${Baseurl}/api/admin/riders/verifiedriders`,
      config
    );
    return response?.data?.data;
  };

  const getBatteries = async () => {
    const response = await axios.get(
      `${Baseurl}/api/admin/battery/getbatteries`,
      config
    );
    return response?.data?.result;
  };

  const getBikes = async () => {
    const response = await axios.get(
      `${Baseurl}/api/admin/bike/getbike`,
      config
    );
    return response?.data?.result;
  };
  const getUser = async () => {
    const response = await axios.get(
      `${Baseurl}/api/admin/auth/getadmin/`,
      config
    );
    return response?.data?.result;
  };

  const getSwaps = async () => {
    const response = await axios.get(
      `${Baseurl}/api/admin/swap/getswaps`,
      config
    );
    return response?.data?.result;
  };
  const getOrders = async () => {
    const response = await axios.get(
      `${Baseurl}/api/admin/orders?limit=0&skip=0&orderStatus=cancelled`,
      config
    );
    return response?.data?.data?.documents;
  };

  const { data: orders } = useQuery("CANCELLEDORDERS", getOrders);
  const { data: bikes } = useQuery("BIKES", getBikes);
  const { data: riders } = useQuery("RIDERS", getRiders);
  queryClient.setQueryData("RidersObject", getRiderName(riders));
  const { data: batteries } = useQuery("BATTERIES", getBatteries);
  const { data: admins } = useQuery("ALLADMIN", getUser);
  // const { data: swaphistory } = useQuery("SWAPHISTORY", getSwaps);
  const logout = () => {
    localStorage.removeItem("eftd-admin-token");
    queryClient.clear();
    navigate("/login");
  };
  return (
    <Wrapper>
      <List>
        <Links>
          <NonlinkListitems style={{ textDecoration: "none" }}>
            <Image src="/menu.png" alt="" />
            <Text>eFTD Africa</Text>
          </NonlinkListitems>
        </Links>
        <Links>
          <ListItem
            style={{ textDecoration: "none" }}
            to={"/dashboard"}
            onClick={newPathName}
            pathname={pathname}
          >
            <Image src="/dashboard.png" alt="" />
            <Text>Dashboard</Text>
          </ListItem>
        </Links>
        <Links>
          <ListItem
            style={{ textDecoration: "none" }}
            to={"/admin"}
            onClick={newPathName}
            pathname={pathname}
          >
            <Image src="/profileicon.png" alt="" />
            <Text>Admin</Text>
          </ListItem>
        </Links>
        <Links>
          <ListItem
            style={{ textDecoration: "none" }}
            to={"/orderhistory"}
            onClick={newPathName}
            pathname={pathname}
          >
            <Image src="/order.png" alt="" />
            <Text>Order</Text>
          </ListItem>
        </Links>
        <Links>
          <ListItem
            style={{ textDecoration: "none" }}
            to={"/vehicles"}
            onClick={newPathName}
            pathname={pathname}
          >
            <Image src="/bike.png" alt="" />
            <Text>Bikes</Text>
          </ListItem>
        </Links>
        <Links>
          <ListItem
            style={{ textDecoration: "none" }}
            to={"/customers"}
            onClick={newPathName}
            pathname={pathname}
          >
            <Image src="/people.svg" alt="" />
            <Text>Customers</Text>
          </ListItem>
        </Links>
        <Links>
          <ListItem
            style={{ textDecoration: "none" }}
            to={"/batteries"}
            onClick={newPathName}
            pathname={pathname}
          >
            <Image src="/battery.png" alt="" />
            <Text>Batteries</Text>
          </ListItem>
        </Links>
        <Links>
          <ListItem
            style={{ textDecoration: "none" }}
            to={"/riders"}
            onClick={newPathName}
            pathname={pathname}
          >
            <Image src="/rider.png" alt="" />
            <Text>Riders</Text>
          </ListItem>
        </Links>
        <Links>
          <ListItem
            style={{ textDecoration: "none" }}
            to={"/swapstations"}
            onClick={newPathName}
            pathname={pathname}
          >
            <Image src="/swapstation.png" alt="" />
            <Text>Swap Stations</Text>
          </ListItem>
        </Links>
        <Links>
          <ListItem
            style={{ textDecoration: "none" }}
            to={"/subscriptions"}
            onClick={newPathName}
            pathname={pathname}
          >
            <Image src="/subscribe.png" alt="" />
            <Text>Subscriptions</Text>
          </ListItem>
        </Links>
        <Links>
          <ListItem
            style={{ textDecoration: "none" }}
            to={"/transactions"}
            onClick={newPathName}
            pathname={pathname}
          >
            <Image src={TransactionHistory} alt="" />
            <Text>Transactions</Text>
          </ListItem>
        </Links>
        <Zoneslink>
          <Zones style={{ textDecoration: "none" }}>
            <Summary>
              <Image src="/zone.png" alt="" />
              <Text>Zones</Text>
              <img src="/caret.png" alt="" />
            </Summary>
            <div>
              <ZoneItem
                style={{ textDecoration: "none" }}
                to={"/pickupzones"}
                onClick={newPathName}
                pathname={pathname}
              >
                Pickup Zones
              </ZoneItem>
              <ZoneItem
                style={{ textDecoration: "none" }}
                to={"/deliveryzones"}
                onClick={newPathName}
                pathname={pathname}
              >
                Delivery Zones
              </ZoneItem>
            </div>
          </Zones>
        </Zoneslink>
       
      </List>
    </Wrapper>
  );
};

const Wrapper = styled.aside`
  width: 15vw;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: #fff;
`;

const List = styled.ul`
  height: 100%;
  width: 100%;
`;

const Links = styled.li`
  height: 30px;
  padding-left: 30px;
`;
const Zoneslink = styled.li`
  padding-left: 30px;
`;
const Text = styled.p`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  /* line-height: 30px; */
  text-decoration: none;
`;
const ListItem = styled(Link)<{ pathname: string }>`
  color: #141414;
  height: 40px;
  margin: 10px auto;
  display: flex;
  gap: 10px;
  align-items: center;
  /* padding-left: 40px; */
  width: 100%;
  font-size: 14px;
  cursor: pointer;
  background-color: ${(props) =>
    props.to === props.pathname
      ? `#FFE5D1;
`
      : `#ffffff`};
`;

const NonlinkListitems = styled.div`
  color: #141414;
  height: 40px;
  margin: 10px auto;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  font-size: 14px;
  cursor: pointer;
`;

const ZoneItem = styled(Link)<{ pathname: string }>`
  color: #141414;
  height: 40px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  font-size: 14px;
  cursor: pointer;
  background-color: ${(props) =>
    props.to === props.pathname
      ? `#FFE5D1;
`
      : `#ffffff`};
`;

const Zones = styled.details`
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      /* height: 40px; */
      padding: 15px 0px;
      background: #ffe5d1;
      width: 100%;
      margin-bottom: 5px;
    }
  }
`;
const Summary = styled.summary`
  color: #141414;
  height: 40px;
  margin: 10px 0;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;

const Image = styled.img`
  height: 18px;
  width: 18px;
`;
