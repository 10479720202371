import  { useEffect, useState , useRef, useCallback} from 'react';
import { useQuery } from 'react-query';
import { Polygon } from '@react-google-maps/api';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const MyPolygon = () => {
  const colors = ['black', 'green', 'red', 'yellow', 'white', 'blue', 'purple', 'pink', 'gray', 'orange']
  const {pathname: location } = useLocation();
    const Baseurl = process.env.REACT_APP_BASEURL;
    const Googlekey = process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY
  const getToken = localStorage.getItem("eftd-admin-token");
  const tokenObject = JSON.parse(getToken);
  const token = tokenObject?.data?.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
    const [google, setGoogle] = useState(null);
    // const [paths, setPaths] = useState([]);

  const options = {
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    zIndex: 1,
  };
  

  useEffect(() => {
    if (window.google) {
      setGoogle(window.google);
    } else {
      const script = document.createElement('script');
      script.src =
        `https://maps.googleapis.com/maps/api/js?key=${Googlekey}&libraries=geometry,drawing`;
      document.head.appendChild(script);
      script.addEventListener('load', () => {
        setGoogle(window.google);
      });
    }
  }, []);

  const getDeliveryCoordinates = async () => {
    const response = await axios.get(`${Baseurl}/api/admin/zone/get-delivery-zones`, config);
    return response?.data?.result
  }

  const getPickupCoordinates = async () => {
    const response = await axios.get(`${Baseurl}/api/admin/zone/get-pickup-zones`, config);
    return response?.data?.result
  }

   const {data: paths} = useQuery("DELIVERYCOORDINATESARRAY", getDeliveryCoordinates)
   const {data: pickupPaths} = useQuery("PICKUPCOORDINATESARRAY", getPickupCoordinates)

  const getLatLng = (arr) => {
    const result = arr?.map(subarray => ({lat: subarray[0], lng: subarray[1]}))
    return result;
  };
  const [path, setPath] = useState([
    { lat: 52.52549080781086, lng: 13.398118538856465 },
    { lat: 52.48578559055679, lng: 13.36653284549709 },
    { lat: 52.48871246221608, lng: 13.44618372440334 },
    { lat: 52.52549080781086, lng: 13.398118538856465 }
  ]);

  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPath(nextPath);
    }
  }, [setPath]);
  const onLoad = useCallback(
    polygon => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove());
    polygonRef.current = null;
  }, []);
  // eslint-disable-next-line no-undef
  // const onEdit = useCallback(() => {
  //   if (polygonRef.current) {
  //     const nextPath = polygonRef.current
  //       .getPath()
  //       .getArray()
  //       .map(latLng => {
  //         return { lat: latLng.lat(), lng: latLng.lng() };
  //       });
  //     setPath(nextPath);
  //   }
  // }, [setPath]);

  if (!google) {
    return null;
  }
  return (
    <>
    {location === "/deliveryzones" && paths?.map((path,index) => (
            <Polygon
            key={index * Math.random()}
            paths={getLatLng(path?.region?.coordinates[0]) }
            editable
            draggable
            options={{...options, fillColor: colors[index], strokeColor: colors[index]}}
            onClick={()=>console.log('i was clicked')}
            onMouseOver={()=>console.log('mouse over')}
            onMouseUp={()=>console.log('i was clicked')}
            // Event used when dragging the whole Polygon
            onDragEnd={()=>console.log('i was clicked')}
            onLoad={()=>console.log('i was clicked')}
            onUnmount={()=>console.log('i was clicked')}
        />
        ))
    }
    {location === "/pickupzones" && pickupPaths?.map((path,index) => {
          // console.log(getLatLng(path?.region?.coordinates[0]))
          return  <Polygon
            key={index * Math.random()}
            editable
            draggable
            path={getLatLng(path?.region?.coordinates[0]) }
            options={{...options, fillColor: colors[index], strokeColor: colors[index]}}
        />
    })
    }
     {location === "/testzone" && 
            <Polygon
            // Make the Polygon editable / draggable
            // editable
            draggable
            path={path}
            // Event used when manipulating and adding points
            onMouseUp={onEdit}
            // Event used when dragging the whole Polygon
            onDragEnd={onEdit}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onClick={()=>console.log('na me be this')}
          />
    }
    </>
  );
};

export default MyPolygon;
