import { useState } from "react";
import { useQueryClient } from "react-query";
import Select from "react-select";
import styled from "styled-components";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Spinner from "./Spinner";
import { newArray } from "../utils/utils";

export default function Filter({
  handleSearchFilter,
  searcName = "search",
  loading = false,
  showStatusFilter = true,
  showRiderFilter = false,
  showSubscriptionFilter=false,
  showDateFilter=true,
  showAccountTypeFilter= false
}) {
  const queryClient = useQueryClient();
  const riders = queryClient.getQueryData("RIDERS");
  const [searchFilter, setSearchFilter] = useState({
    startDate: "",
    endDate:''
  });

  const handleChange = (e) => {
    e.preventDefault()
    setSearchFilter((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  //Date component

  

  const handleSearch = () => {
    const filteredObject = Object.fromEntries(
      Object.entries(searchFilter).filter(([key, value]) => value !== "")
    );
    handleSearchFilter(filteredObject);
  };

  const handleClear = () => {
    setSearchFilter({
      startDate: "",
      endDate:'',
      search: '',
      deliveryRider: '',
      pickupRider: '',
      accountType: '',
      subscription:'',
      orderStatus:''
    })
    handleSearchFilter({});
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      // Trigger the filter action with the current filters
      const filteredObject = Object.fromEntries(
        Object.entries(searchFilter).filter(([key, value]) => value !== "")
      );
      handleSearchFilter(filteredObject);
    }
  };

  const statusOptions = [
    { value: "", label: "Status" },
    { value: "cancelled", label: "Cancelled" },
    { value: "delivered", label: "Delivered" },
    { value: "failed-after-payment", label: "Failed" },
    { value: "in-transit", label: "In Transit" },
    { value: "ordered", label: "Ordered" },
    { value: "pickup", label: "Picked Up" },
    { value: "processing", label: "Processing" },
    { value: "verified", label: "Verified" },
  ];

  const SubscriptionOptions = [
    { value: "", label: "Subscription" },
    { value: "REGULAR", label: "REGULAR" },
  ];


  const AccountTypeOptions = [
    { value: "", label: "Account Type" },
    { value: "business", label: "Business" },
    { value: "individual", label: "Individual" },
  ];



  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      // color: state.isSelected ? "#212529" : "#fff",
      // backgroundColor: state.isSelected ? "#a0a0a0" : "#212529",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#ffffff",
      padding: "0px 10px",
      border: "1px solid #212529",
      width: "200px",
      boxShadow: "none",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#212529" }),
  };

  const DateDropdown = ({ handleClose }) => {
    return (
      <>
        <div
          style={{
            backgroundColor: "#ced3d890",
            boxShadow: " 0px 12px 34px 0px #00000026",
            position: "absolute",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "30px",
              justifyContent: "space-between",
              fontSize: "10px",
            }}
          >
            <h3 style={{ margin: "auto 4px" }}>Filter by date range</h3>
            <img
              src="/closed.png"
              alt="close"
              onClick={handleClose}
              style={{
                widows: "20px",
                height: "20px",
                margin: "5px",
                cursor: "pointer",
              }}
            />
          </div>
          <div style={{ backgroundColor: "white", borderRadius: "4px" }}>
            <div
              style={{
                display: "flex",
                width: "300px",
                justifyContent: "space-between",
                margin: "auto 0.5em",
                padding: "10px",
                gap: "10px",
              }}
            >
              <div style={{ width: "50%" }}>
                <label style={{ fontSize: "10px" }}>From</label>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    // label="Basic example"
                    onKeyDown={handleKeyPress}
                    inputFormat="dd/MM/yyyy"
                    value={searchFilter.startDate}
                    onChange={(value) => {
                      setSearchFilter((prev) => {
                        return { ...prev, startDate: value.toString() };
                      });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div style={{ width: "50%" }}>
                <label style={{ fontSize: "10px" }}>To</label>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                   onKeyDown={handleKeyPress}
                    inputFormat="dd/MM/yyyy"
                    value={searchFilter.endDate}
                    onChange={(value) => {
                      setSearchFilter((prev) => {
                        return { ...prev, endDate: value.toString() };
                      });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const [open, setOpen] = useState(false);
  return (
    <Body>
      <div className="filter">
        <div style={{ width: "100%" }}>
          <p
            style={{
              width: "100%",
              display: "flex",
              padding: "0px 10px",
              border: "1px solid #CED3D8",
              borderRadius: "5px",
              alignItems: "center",
            }}
          >
            <input
              placeholder="Search"
              onChange={handleChange}
              name={searcName}
              type="text"
              onKeyDown={handleKeyPress}
              value={searchFilter.search}
              style={{
                width: "95%",
                height: "7px",
                padding: "1em 0px",
                border: "none",
                fontSize: "13px",
                outline: "none",
              }}
            />

            <img
              src="/search.png"
              alt="search"
              style={{
                width: "16px",
                height: "16px",
              }}
            />
          </p>
        </div>
        {showDateFilter&& <div
          style={{ position: "relative", width: "100%", marginLeft: "10px" }}
        >
          <div
            style={{
              display: "flex",
              cursor: "pointer",
              height: "24px",
              border: "1px solid #CED3D8",
              padding: "5px",
              justifyContent: "space-between",
              borderRadius: "5px",
            }}
            onClick={() => setOpen(!open)}
          >
            <div style={{ display: "flex" }}>
              <img
                src="/date.png"
                alt="filter"
                style={{
                  width: "13px",
                  height: "14px",
                  marginRight: "10px",
                  marginTop: "3px",
                }}
              />
              <p style={{ fontSize: "13px", marginTop: "3px" }}>Date range</p>
            </div>
            <img
              className="dropdown-image pointer"
              src="/drop.png"
              alt="Dropdown"
              style={{
                width: "10px",
                height: "6px",
                marginTop: "8px",
              }}
            />
          </div>
          {open && <DateDropdown handleClose={() => setOpen(false)} />}
        </div>
          }

        {showRiderFilter && (
          <div style={{ width: "100%", display: "flex", gap: "10px" }}>
            <Select
              options={newArray(riders)}
              placeholder="Pickup rider"
              onChange={(value) =>
                setSearchFilter((prev) => {
                  return { ...prev, pickupRider: value.value };
                })
              }
              styles={customStyles}
              isSearchable={true}
              onKeyDown={handleKeyPress}
            />
            <Select
              options={newArray(riders)}
              placeholder="Delivery rider"
              name="deliveryRider"
              onChange={(value) =>
                setSearchFilter((prev) => {
                  return { ...prev, deliveryRider: value.value };
                })
              }
              styles={customStyles}
              isSearchable={true}
              onKeyDown={handleKeyPress}
            />
          </div>
        )}
        {showStatusFilter && (
          <div style={{ width: "100%" }}>
            <Select
              options={statusOptions}
              defaultValue={statusOptions[0]}
              name="orderStatus"
              onKeyDown={handleKeyPress}
              onChange={(value) =>
                setSearchFilter((prev) => {
                  return { ...prev, orderStatus: value.value };
                })
              }
            />
          </div>
        )}
         {showSubscriptionFilter && (
          <div style={{ width: "100%" }}>
            <Select
              options={SubscriptionOptions}
              defaultValue={SubscriptionOptions[0]}
              name="subscription"
              onKeyDown={handleKeyPress}
              onChange={(value) =>
                setSearchFilter((prev) => {
                  return { ...prev, subscription: value.value };
                })
              }
            />
          </div>
        )}
        {showAccountTypeFilter && (
          <div style={{ width: "100%" }}>
            <Select
              options={AccountTypeOptions}
              defaultValue={AccountTypeOptions[0]}
              name="accountType"
              onKeyDown={handleKeyPress}
              onChange={(value) =>
                setSearchFilter((prev) => {
                  return { ...prev, accountType: value.value };
                })
              }
            />
          </div>
        )}
        <button
          style={{
            fontSize: "14px",
            backgroundColor: "#F66D00",
            borderColor: "transparent",
            borderRadius: "0.5em",
            color: "white",
            marginBottom: "5px",
            cursor: "pointer",
            padding: "5px 20px",
          }}
          onClick={handleSearch}       
        >
          {loading ? <Spinner /> : "Filter"}
        </button>
        <button
          style={{
            fontSize: "14px",
            backgroundColor: "black",
            borderColor: "transparent",
            borderRadius: "0.5em",
            color: "white",
            marginBottom: "5px",
            cursor: "pointer",
            padding: "5px 20px",
          }}
          onClick={handleClear}
        >
          {loading ? <Spinner /> : "Clear"}
        </button>
      </div>
    </Body>
  );
}

const Body = styled.main`
  width: 90%;
  margin: 20px auto;
  padding: 20px 10px;
  background-color: #ffffff;
  .filter {
    width: 100%;
    display: flex;
    margin: auto;
    gap: 20px;
  }
`;
