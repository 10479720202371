import { useQuery } from "react-query";
import axios from "axios";

export function useRoles() {
    const Baseurl = process.env.REACT_APP_BASEURL;
    const getToken: any = localStorage.getItem("eftd-admin-token");
    const tokenObject: any = JSON.parse(getToken);
    const token = tokenObject?.data?.token;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const getAdmin = async () => {
      const response = await axios.get(`${Baseurl}/api/admin/auth/currentadmin`, config)
      return response?.data?.data?.isSuperAdmin
    }

    const {data } = useQuery('CURRENT_ADMIN', getAdmin)

    return data
  }