import React from "react";
import { Navigate } from "react-router-dom";

function ProtectedRoute({ children }: any) {
  const isAuthenticated = localStorage.getItem("eftd-admin-token");
  if (!isAuthenticated) {
    return <Navigate to={"/login"} />;
  }

  return children;
}

export default ProtectedRoute;
