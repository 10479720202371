import React, { useEffect, useState } from 'react'
import axios from "axios";
import styled from 'styled-components'
import { useParams } from "react-router-dom";
import { Documents } from '../../components/Documents';
import { NavHeader } from '../../components/NavHeader';
import { NextOfKin } from '../../components/NextOfKin';
import { PersonalDetails } from '../../components/PersonalDetails';
import { Sidebar } from '../../components/Sidebar';
import Spinner from '../../components/Spinner';
import BasicModal from '../../components/Modal';

export const PendingRiderVerification = () => {
    const Baseurl = process.env.REACT_APP_BASEURL;
   const getToken: any = localStorage.getItem("eftd-admin-token");
   const tokenObject: any = JSON.parse(getToken);
   const token = tokenObject?.data?.token;
   const config = {
     headers: {
       Authorization: `Bearer ${token}`,
     },
   };
  const { pendingriderId } = useParams();
    const [rider, setRiders] = useState<any>({});
    const [openModal, setOpenModal] = useState(false)
    const [message, setMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const ModalContent = (response: any) => {
      switch (response) {
        case "success":
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Image2>
                <img src="/check.png" alt="" width={"75%"} height={"75%"} />
              </Image2>

              <Text>Rider has been Verified</Text>
            </div>
          );
        case "failed":
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Image>
                <img src="/exclamation.png" alt="" width={"100%"} height={"100%"} />
              </Image>

              <Text>{errorMessage ?? 'Request has been Declined'}</Text>
            </div>
          );
      }
    };

    const verify = () => {
      axios
        .post(
          `${Baseurl}/api/admin/riders/confirmRider`,
          { riderId: pendingriderId },
          config
        )
        .then((res) => {
          setMessage("success");
          setOpenModal(true);
        })
        .catch((err) => {
          setErrorMessage(err?.response?.data?.message)
          setMessage("failed");
          setOpenModal(true);
        });
    }
   useEffect(() => {
     axios
       .get(
         `${Baseurl}/api/admin/riders/getriders/${pendingriderId}`,
         config
       )
       .then((res) => {
         setRiders(res?.data?.data);
         setIsLoading(false);
       });
   }, [pendingriderId]);


  return (
    <Body>
      <Sidebar />
      <Wrapper>
        <NavHeader title={"Pending Request"} />
        <InputWrapper>
          <SearchIcon>
            <img width={"100%"} src="/searchicongrey.png" alt="searchicon" />
          </SearchIcon>
          <Inputs placeholder="Search" />
        </InputWrapper>
        {isLoading ? (
          <Spinner componentlevel />
        ) : (
          <React.Fragment>
            <PersonalDetails
              name={rider?.fullName}
              profilePhoto={rider?.profilePhoto}
              phoneNumber={rider?.phoneNumber}
              email={rider?.email}
              address={rider?.riderAddress}
              isVerified={rider?.status === "VERIFIED"}
            />
            <Decision>
              <Verify onClick={verify}>Verify</Verify>
              <Decline onClick={()=>{
                setOpenModal(true)
                setMessage('failed')}}
              >Decline</Decline>
            </Decision>
            <BasicModal
              content={ModalContent(message)}
              open={openModal}
              close={() => setOpenModal(false)}
            />
          </React.Fragment>
        )}
      </Wrapper>
    </Body>
  );
}

const Body = styled.main`
  display: flex;
`;
const Wrapper = styled.aside`
  width: 85vw;
  height: 200vh;
  overflow-y: scroll;
  margin-left: 15vw;
  background-color: #fafafa;
`;

const SearchIcon = styled.p`
  height: 20px;
  width: 20px;
  margin-left: 2%;
`;
const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border: 7px solid red;
  border-radius: 50%;
`;

const Image2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border: 7px solid green;
  border-radius: 50%;
`;

const Text = styled.p`
    font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 48px;
  text-align: center;

  color: #4d4949;
`;
const InputWrapper = styled.div`
  width: 96%;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 5px;
`;
const Inputs = styled.input`
  width: 100%;
  background-color: #ffffff;
  border: none;
  outline: none;
  padding: 10px 0;
  border-radius: 8px;
  padding-left: 1%;
`;

const Decision = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #ffffff;
  border-radius: 12px;
  width: 96%;
  margin: 20px auto;
  padding: 20px 0px;
`;

const Verify = styled.button`
  width: 426px;
  height: 72px;
  background: #4cbd5e;
  border-radius: 16px;
    font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  /* identical to box height */
    border: none;
  color: #ffffff;
`;

const Decline = styled.button`
  width: 426px;
  height: 72px;
  background: #e73838;
  border-radius: 16px;
    font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  /* identical to box height */
  border: none;
  color: #ffffff;
`;
