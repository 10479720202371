import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import { getDate, getTime } from "../utils/utils";

const data: any[] = [
  {
    name: "oyinkansola odeniyi",
    activity: "logged on",
    time: "3d",
  },
  {
    name: "oyinkansola odeniyi",
    activity: "updated order #FD7286925 to delivered",
    time: "3d",
  },
  {
    name: "oyinkansola odeniyi",
    activity: "logged on",
    time: "3d",
  },
];

export const AdminActivity = () => {
  const navigate = useNavigate();
  const Baseurl = process.env.REACT_APP_BASEURL;
  const getToken: any = localStorage.getItem("eftd-admin-token");
  const tokenObject: any = JSON.parse(getToken);
  const token = tokenObject?.data?.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getActivity = async () => {
    const response = await axios.get(`${Baseurl}/api/admin/activity`, config);
    return response?.data?.result?.documents;
  };

  const { data: activity } = useQuery("ADMINACTIVITY", getActivity);

  return (
    <Wrapper>
      <Header>Admin Activity</Header>
      {activity?.slice(0,3)?.map((item: any, index: any) => (
        <Row key={index}>
          <Name>{`${item?.admin?.name} ${item?.action}`}</Name>
          <Date>{`${getDate(item?.createdAt)} ${getTime(
            item?.createdAt
          )} `}</Date>
        </Row>
      ))}
      {data.length > 2 && (
        <ViewAll onClick={() => navigate("/activity")}>View More</ViewAll>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #ffffff;
  width: 48%;
  margin: 20px auto;
  /* height: 300px; */
  border-radius: 16px;
  border: 1px solid #E0E0E0;
  padding: 20px;
`;
const Date = styled.p`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 33px;
  /* identical to box height */
  color: #828282;
`;
const Header = styled.h1`
 color: var(--e-ftd-orange, #FE7F1F);
/* Small Bold */
font-family: Red Hat Display;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
padding: 5px 0px;
width: 90%;
  margin: 5px auto;
`;

const Row = styled.div`
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  border-top: 1px solid #E0E0E0;
  width: 90%;
  margin: 5px auto;
  padding: 5px 0px;
`;
const Name = styled.p`
  font-weight: 500;
  color: #4F4F4F;
`;



const ViewAll = styled.p`
  font-size: 20px;
  text-align: center;
  color:var(--e-ftd-orange, #FE7F1F);;
  width: 90%;
  margin: 10px auto;
  padding: 5px 0px;
  cursor: pointer;
  font-weight:500;
`;
