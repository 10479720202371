import React,{useState} from 'react'
import styled from 'styled-components'
import { InterCountry } from '../../components/InterCountry';
import { Interstate } from '../../components/Interstate';
import { NavHeader } from '../../components/NavHeader';
import { Sidebar } from '../../components/Sidebar';

export const Regions = () => {
     const [page, setPage] = useState("interstate");
  return (
    <Body>
      <Sidebar />
      <Wrapper>
        <NavHeader title='Regions and Pricing'/>
        <NavRegionHeader>
          <Button onClick={() => setPage("interstate")}>Interstate</Button>
          <Button onClick={() => setPage("intercountry")}>Intercountry</Button>
        </NavRegionHeader>
        {page === "interstate" ? <Interstate/> : <InterCountry/> }
      </Wrapper>
    </Body>
  );
}

const Body = styled.main`
  display: flex;
`;
const Wrapper = styled.aside`
  width: 85vw;
  overflow-y: scroll;
  margin-left: 15vw;
  background-color: #fafafa;
`;

const NavRegionHeader = styled.nav`
  height: 58px;
  left: 356px;
  top: 155px;

  background: #ffffff;
  border-radius: 12px;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const Button = styled.p`
  cursor: pointer;
  border-bottom: 3px solid #777272;
    font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #777272;
`;