import React, { useState } from 'react'
import styled from 'styled-components';
import { NavHeader } from '../../components/NavHeader';
import { Sidebar } from '../../components/Sidebar';
import { formatAmount, getFormattedDate, getTime } from "../../utils/utils"
import Filter from '../../components/Filter';
import axios from 'axios';
import { useQuery } from 'react-query';
import Spinner from '../../components/Spinner';
import redArrow from "../../assets/redarrow.svg";
import greenArrow from "../../assets/greenArrow.svg";
import TransactionAmount from "../../assets/transactionamount.svg";
import TransactionCount from "../../assets/transactioncount.svg";


export const TransactionHistory = () => {
  const Baseurl = process.env.REACT_APP_BASEURL;
  const getToken: any = localStorage.getItem("eftd-admin-token");
  const [page, setPage] = useState(1);
  const tokenObject: any = JSON.parse(getToken);
  const [searchFilter, setSearchFilter] = useState<any>()
  const token = tokenObject?.data?.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getTransactionHistory = async () => {
    const response = await axios.get(
      `${Baseurl}/api/admin/transaction?page=${page}&limit=25`,
      {...config, params: {...searchFilter}}
    );
    return response?.data?.data;
  };

  const getTransactionSummary = async () => {
    const response = await axios.get(
      `${Baseurl}/api/admin/dashboard/transaction-summary`,
      config
    );
    return response?.data?.data?.transactionSummaryData;
  };

  const { data: transactions , isLoading} = useQuery(["ALLTransactions", page, searchFilter ], getTransactionHistory);
  const { data: transactionSummary } = useQuery("TRANSACTIONSUMMARY", getTransactionSummary);

  function goToNextPage() {
    setPage((page: number) => page + 1);
  }
  function gotolastPage() {
    setPage(transactions?.paging?.totalPages);
  }

  function goToPreviousPage() {
    setPage((page: number) => page - 1);
  }

  function changePage(event: any) {
    const pageNumber = Number(event.target.textContent);
    setPage(pageNumber);
  }

  const getPaginationGroup = () => {
    let total = Math.ceil(transactions?.paging?.totalPages) || 0;
    let pagination = new Array(total).fill(0).map((_, idx) => idx + 1);
    if (pagination.length > 8) {
      pagination.length = 9;
    }
    return pagination;
  };

  return (
    <Body>
    <Sidebar />
    <Wrapper>
      <NavHeader title="Transaction History" />
      <Overview>
        <h1>Overview</h1>
        <div className='transaction-overview-card-wrapper'>
          <div className='transaction-overview-card'>
            <img src={TransactionCount} alt="" />
            <div>
              <h2>{transactionSummary?.transactionCount}</h2>
              <p>Transaction Count</p>
            </div>
          </div>
          <div className='transaction-overview-card'>
          <img src={TransactionAmount} alt="" />
            <div>
              <h2>{formatAmount(transactionSummary?.totalAmount / 100)}</h2>
              <p>Transaction Value</p>
            </div>
          </div>
        </div>

      </Overview>
      <Filter handleSearchFilter={setSearchFilter} showStatusFilter={false}/>

      {/* <Table/> */}
      <HistoryList>
          <Listdiv>
            <ListAddress >Customer's Name</ListAddress>
            <ListAddress>Type</ListAddress>
            <ListAddress >Date</ListAddress>
            <ListAddress>Time</ListAddress>
            
            <ListAddress>Amount</ListAddress>
          </Listdiv>
          {isLoading ? <Spinner componentlevel /> : transactions?.documents?.map((info: any, index: any) => (
            <Listdiv key={index}>
              <ListDate>{info?.userId?.businessName || `${info?.userId?.firstName} ${info?.userId?.lastName}`}</ListDate>
              <ListDate className='type'><img src={!info?.isInflow ? redArrow: greenArrow } alt="" /> <span>{info?.paymentType}</span></ListDate>
              <ListDate>{getFormattedDate(info?.createdAt)}</ListDate>
              <ListDate>{getTime(info?.createdAt)}</ListDate>
              <ListDate className={!info?.isInflow ? 'paid' : 'received'} >{`N ${formatAmount(info?.amount / 100)}`}</ListDate>
            </Listdiv>
          ))}
        </HistoryList>
        <Paginationwrap className="pagination">
            <button className={`prev currentPage`}>{page}</button>
            {/* previous button */}
            <button
              onClick={goToPreviousPage}
              className={`prev ${page === 1 ? "disabled" : ""}`}
            >
              {"<"}
            </button>

            {/* show page numbers */}
            {getPaginationGroup().map((item, index) => (
              <button
                key={index}
                onClick={changePage}
                className={`paginationItem ${page === item ? "active" : null}`}
              >
                <span>{item}</span>
              </button>
            ))}

            {/* next button */}
            <button
              onClick={goToNextPage}
              className={`next ${
                page >= transactions?.paging?.totalPages ? "disabled" : ""
              }`}
            >
              {">"}
            </button>
            <button onClick={gotolastPage} className={`next disabled`}>
              {transactions?.paging?.totalPages}
            </button>
          </Paginationwrap>
    </Wrapper>
    </Body>
  );
}

const Body = styled.main`
  display: flex;
`;

const Wrapper = styled.main`
  width: 85vw;
  overflow-y: scroll;
  margin-left: 15vw;
  background-color: #fafafa;
  margin-bottom: 10vh;
  height: 100%;
  @media (max-width: 425px) {
  }
`;

const Overview = styled.div`
  width: 90%;
  margin: auto;
  background-color: #ffffff;
  /* height: 3rem; */
  padding: 20px;

  h1 {
    margin-bottom: 20px;
  }

  .transaction-overview-card-wrapper {
    display: flex;
    gap: 10px;

    .transaction-overview-card {
      width: 50%;
      border: 1px solid #E5EBF0;
      padding: 30px 40px;
      display: flex;
      gap: 30px;
    }
  }

`
const HistoryList = styled.section`
  background-color: #fafafa;
  width: 100%;
  @media (max-width: 425px) {
    width: 100%;
    background-color: #ffffff;
  }
`;

const Listdiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: 50px;
  margin: 0px auto;
  padding: 0px 20px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.01);
  /* border-bottom: 1px solid #E0E0E0; */
  @media (max-width: 425px) {
    width: 90%;
  }

 
  &:nth-child(odd){
    background-color: #F9F9F9;
  }
  &:nth-child(1) {
    background-color: #ffffff;
  }
`;

const ListImage = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e6ebf8;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ListAddress = styled.p`
  font-weight: 300;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  text-align: left;
  width: 20%;
  &.action {
    text-align: right;
    width: 10%;
  }
  &.date {
    width: 10%;
  }
  &.designation {
    width: 10%;
   
  }
  &.email {
    width: 25%;
  }
`;

const ListAmount = styled.p`
  color: #ed2727;
  cursor: pointer;
`;

const ListDate = styled.p`
  color: #333;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  text-align: left;
  width: 20%;
  &.action {
    text-align: right;
    width: 10%;
    cursor:pointer;
  }
  &.date {
    width: 10%;
  }
  &.type {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  &.paid {
    color: #ed2727;
    font-weight: 600;
  }

  &.received {
    color: #219653;
    font-weight: 600;
  }

`;

const Paginationwrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #4a4a68;

  > button {
    background: #f4f9ff;
    box-shadow: 0px 0px 7px rgba(34, 103, 206, 0.5);
    border-radius: 5px;
  }
  .paginationItem {
    border: none;
    background: #f4f9ff;
    padding: 10px;
    height: 20px;
    width: 20px;
    position: relative;
    margin: 30px 5px;
    cursor: pointer;
  }
  .paginationItem.active {
    border: 1px solid #333333;
  }
  .prev,
  .next {
    background: #f4f9ff;
    border: none;
    padding: 10px;
    height: 20px;
    width: 20px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    margin: 0 10px;
    cursor: pointer;
  }
`;