/* eslint-disable no-undef */

import styled from "styled-components";
import { Sidebar } from "../../components/Sidebar";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import MyPolygon from "../../components/Mypolygon";
import Mydrawingmanager from "../../components/MydrawingManager";
import { useMutation, useQueryClient } from "react-query";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import Riderzones from "../../components/Riderzones";
import Feedback from "../../components/Feedback";
import Spinner from "../../components/Spinner";

const containerStyle = {
  width: "85vw",
  height: "100%",
};
const GoogleApiKey = process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY;
const libraries = ["places", "geometry", "visualization", "drawing"];
const Geofence = () => {
  const { pathname: path } = useLocation();
  const Baseurl = process.env.REACT_APP_BASEURL;
  const getToken = localStorage.getItem("eftd-admin-token");
  const tokenObject = JSON.parse(getToken);
  const token = tokenObject?.data?.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const queryClient = useQueryClient();
  const riders = queryClient.getQueryData("RIDERS");
  const coordinates = queryClient.getQueryData("PATHS");
  const [openFeedback, setOpenFeedback] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [state, setState] = useState({
    coordinates: [],
    name: "",
    rider: "",
    type: "",
  });
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GoogleApiKey,
    libraries,
  });

  const onLoad = (data) => {};
  const createZones = async (data) => {
    const response = await axios.post(
      `${Baseurl}/api/admin/zone/register`,
      data,
      config
    );
    return response?.data;
  };

  const createZonesMutation = useMutation((data) => createZones(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("DELIVERYCOORDINATESARRAY");
      queryClient.invalidateQueries("PICKUPCOORDINATESARRAY");
      setFeedback("success");
      setOpenFeedback(true);
    },
    onError: () => {
      setFeedback("failed");
      setOpenFeedback(true);
    },
  });

  const createZone = () => {
    // if(coordinates){
    //   setState((prev)=> {
    //     return {...prev, coordinates: coordinates}
    //   })
    // }
    const requestdata = { ...state, coordinates };
    try {
      createZonesMutation.mutateAsync(requestdata);
    } catch (err) {
      console.log(err);
    }
  };

  if (loadError) return <>Error loading maps</>;
  if (!isLoaded) return <>Loading Maps</>;
  return (
    <div style={{ width: "100%", height: "100vh", display: "flex" }}>
      <Sidebar />
      <div style={{ marginLeft: "15vw", width: "85vw", position: "relative" }}>
        <Title>
          {path === "/deliveryzones" ? "Delivery Zones" : "Pickup Zones"}
        </Title>
        <Getdata>
          <Dropdown
            name="rider"
            onChange={(e) =>
              setState((prev) => {
                return { ...prev, rider: e.target.value };
              })
            }
          >
            <option>Select Rider</option>
            {riders?.map((rider) => (
              <option key={rider._id} value={rider._id}>
                {rider.fullName}
              </option>
            ))}
          </Dropdown>
          <Dropdown
            name="type"
            onChange={(e) =>
              setState((prev) => {
                return { ...prev, type: e.target.value };
              })
            }
          >
            <option>Select Zone Type</option>
            {["Pickup", "Delivery"]?.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Dropdown>

          <input
            value={state?.name}
            onChange={(e) =>
              setState((prev) => {
                return { ...prev, name: e.target.value };
              })
            }
            placeholder="Enter Zone Name"
          />
          <button onClick={createZone}>
            {createZonesMutation.isLoading ? (
              <Spinner componentlevel={false} />
            ) : (
              "Create Zone"
            )}
          </button>
        </Getdata>
        <Riderzones />
        {path === "/deliveryzones" && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            zoom={10}
            center={{ lat: 6.50158, lng: 3.52249 }}
            onLoad={onLoad}
          >
            <MyPolygon />
            <Mydrawingmanager />
          </GoogleMap>
        )}
        {path === "/pickupzones" && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            zoom={10}
            center={{ lat: 6.50158, lng: 3.52249 }}
            // center={{ lat: 52.52047739093263, lng: 13.36653284549709 }}
            onLoad={onLoad}
          >
            <MyPolygon />
            <Mydrawingmanager />
          </GoogleMap>
        )}
        {path === "/testzone" && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            zoom={10}
            // center={{ lat: 6.50158, lng: 3.52249 }}
            center={{ lat: 52.52047739093263, lng: 13.36653284549709 }}
            onLoad={onLoad}
          >
            <MyPolygon />
            <Mydrawingmanager />
          </GoogleMap>
        )}
      </div>
      <Feedback
        feedback={feedback}
        open={openFeedback}
        close={() => setOpenFeedback(false)}
      />
    </div>
  );
};

const Getdata = styled.section`
  width: 170px;
  background-color: #ffffff;
  height: 150px;
  position: absolute;
  top: 100px;
  left: 10px;
  z-index: 4;
  display: flex;
  flex-direction: column;
  gap: 5%;
  padding: 10px 10px;
  border-radius: 0px 0px 8px 8px;

  > input {
    height: 30px;
    outline: none;
    border: none;
    background-color: #f2f2f2;
    border-radius: 5px;
    padding-left: 10px;
  }
  > button {
    height: 30px;
    outline: none;
    border: none;
    background-color: #f66d00;
    color: #ffffff;
    border-radius: 5px;
  }
  option {
    width: 150px;
  }
`;

const Title = styled.h1`
  top: 65px;
  left: 10px;
  z-index: 4;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #04030b;
  position: absolute;
`;

const Dropdown = styled.select`
  height: 30px;
  font-size: 14px;
  border-radius: 5px;
  background-color: #f2f2f2;
  outline: none;
  border: none;
  width: 100%;
`;

export { Geofence };
