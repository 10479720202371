import React from "react";
import styled from "styled-components";
const stuff = [
  { Weight: 0.5, price: 2600 },
  { Weight: 0.5, price: 2600 },
  { Weight: 0.5, price: 2600 },
  { Weight: 0.5, price: 2600 },
  { Weight: 0.5, price: 2600 },
  { Weight: 0.5, price: 2600 },
  { Weight: 0.5, price: 2600 },
  { Weight: 0.5, price: 2600 },
  { Weight: 0.5, price: 2600 },
];
export const Pricing = () => {
  return (
    <Wrapper>
      <Between>Between</Between>
      <Heading>
        <select className="select">
          <option value={"Zone1"}>Zone 1</option>
          <option value={"Zone2"}>Zone 2</option>
          <option value={"Zone3"}>Zone 3</option>
        </select>
        <And>and</And>
        <select className="select">
          <option value={"Zone1"}>Zone 1</option>
          <option value={"Zone2"}>Zone 2</option>
          <option value={"Zone3"}>Zone 3</option>
        </select>
      </Heading>
      <Row>
        <WeightWrap>Weights</WeightWrap>
        <PriceWrap>Pricing</PriceWrap>
      </Row>
      {stuff.map(
        ((item, index) => (
          <Row key={index}>
            <WeightWrap>
              <Weight>{item.Weight}</Weight>
              <Hr/>
            </WeightWrap>

            <PriceWrap>
              <Price type="number" value={item.price} ></Price>
              <DeleteICon>
                <img src="trash.png" alt="trash" />
              </DeleteICon>
            </PriceWrap>
          </Row>
        ))
      )}

      <AddWeight>Add Weight</AddWeight>
      <WrapButton>
        <Update>Update</Update>
        <Delete>Delete Pricelist</Delete>
      </WrapButton>
    </Wrapper>
  );
};

const Wrapper = styled.form`
  background: #ffffff;
  border-radius: 12px;
  width: 86%;
  margin: 30px auto;
  height: 100vh;
  padding: 2%;
`;
const Between = styled.h1`
    font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #807c7c;
  margin-bottom: 20px;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const And = styled.p`
    font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  color: #807c7c;
`;

const AddWeight = styled.button`
  width: 100%;
  margin: auto;
  height: 57px;
  background: #3f4196;
  border-radius: 18px;
    font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  border: none;
  color: #ffffff;
`;
const Update = styled.button`
  width: 48%;
  height: 55px;
  background: #ffffff;
  border: 1px solid #7d7fca;
  border-radius: 18px;
    font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  color: #3f4196;
`;

const Delete = styled.button`
  width: 48%;
  height: 55px;

  background: #ffffff;
  border: 1px solid #fb8a8a;
  border-radius: 18px;
    font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  color: #ed1616;
`;

const WrapButton = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0px;
`;

const Weight = styled.p`
  width: 106px;
  height: 52px;
  background: #f9f9f9;
  border-radius: 12px;
  display: grid;
  place-items: center;
    font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  color: #9c9696;
`;

const WeightWrap = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  color: #807c7c;
`;

const Price = styled.input`
  border: none;
  outline: none;

  background: #f9f9f9;
  border-radius: 12px;
    font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  padding-left: 20px;
  /* identical to box height */
  width: 206px;
  height: 52px;
  background: #f9f9f9;
  border-radius: 12px;

  color: #9c9696;
`;
const DeleteICon = styled.div`
  width: 78px;
  height: 52px;
  background: #fff2f2;
  border: 1px solid #ed1616;
  border-radius: 12px;
  display: grid;
  place-items: center;
  cursor: pointer;
`;
const PriceWrap = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  color: #807c7c;
`;
const Hr = styled.hr`
  /* height: 0.3px; */
   /* border-style: 0.3px dashed #6a6cc0; */
  width: 80%;
  margin: auto;
  /* background: #6a6cc0; */
  border: 0.3px dashed #6a6cc0;
`;