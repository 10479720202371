import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import styled from "styled-components";
import { calculateSum, splitArrays } from "../utils/utils";

ChartJS.register(ArcElement, Tooltip, Legend);


const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    labels: {
      render: "value",
      precision: 0,
      showZero: true,
      fontSize: 12,
      fontColor: "#fff",
      fontStyle: "normal",
      fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      textShadow: true,
      shadowOffsetX: -5,
      shadowOffsetY: 5,
      shadowColor: "rgba(255,0,0,0.75)",
      arc: true,
      position: "default",
      overlap: true,
      showActualPercentages: true,
    },
  },
};
export const Piechart = ({orderInfo, title}: any) => {
  const [idsArray, countsArray] = splitArrays(orderInfo);


  const data = {
    labels: idsArray,
    datasets: [
      {
        label: "# of Votes",
        data: countsArray,
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <Wrapper>
      <Title>Total {title}: {calculateSum(countsArray)}</Title>
      <div style={{height: '300px'}}>
      <Pie data={data} width={200} height={200} options={options} />
      </div>
      <Legends>
        {
         orderInfo && orderInfo.map((fields: any, index:any) => {

            return (
              <div style={{display:'flex', alignItems:'center',gap: '5px'}} key={index}>
              <span style={{background: data?.datasets[0]?.backgroundColor[index]}}  className="circle"></span>
              <span>{fields._id || fields?.subscriptionName}</span>
              </div>
            )
          })
        }
      </Legends>
      {/* <Info>
      {
        orderInfo && orderInfo.map((fields: any, index:any) => {

            return (
              <div key={index} style={{background: Number(index) % 2 === 0  ? '#FFD9BC': '#FDECDF'}} >
              <p  className="circle">{fields._id || fields?.subscriptionName}</p>
              <p>{fields.count || fields?.numberOfSubscriptions}</p>
              </div>
            )
          })
        }
      </Info> */}

    </Wrapper>
  );
};

const Wrapper = styled.section`
  /* height: 900px; */

`

const Title = styled.h1`
  color: var(--e-ftd-orange, #FE7F1F);
/* Small */
font-family: Red Hat Display;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;

`

const Legends = styled.div`
margin: 20px auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 300px;
  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;

  }
`
const Info = styled.div`
  width: 100%;
  margin: 20px auto;
  > div {
    margin: 0px auto;
    width: 300px;
    color: #000000;
    display: flex;
    align-items: center;
    
    

    
  }
  p {
    padding: 20px;
    width: 50%;
  }
  div > p:last-child {
      border-left: 1px solid #FCCEAA;
     
    }
`
