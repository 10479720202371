import React, { useState } from "react";
import styled from "styled-components";

type Props = {
  totalPages: any;
  currentPageNo: any;
};

export default function PaginationComponent({
  totalPages,
  currentPageNo
}: Props) {
  const [currentPage, setCurrentPage] = useState(1);

  function goToNextPage() {
    setCurrentPage((page) => page + 1);
    currentPageNo((page: number) => page + 1);
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
    currentPageNo((page: number) => page + 1);
  }

  function gotolastPage() {
    setCurrentPage(totalPages);
    currentPageNo(totalPages);

  }

  function changePage(event: any) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
    currentPageNo(pageNumber);
  }



  const getPaginationGroup = () => {
    let total = Math.ceil(totalPages) || 0;
    let pagination = new Array(total).fill(0).map((_, idx) => idx + 1);
    if (pagination.length > 8){
      pagination.length = 9
    }
    return pagination
  };


  return (
    <Paginationwrap className="pagination">
    <button className={`prev currentPage`}>{currentPage}</button>
    {/* previous button */}
    <button
      onClick={goToPreviousPage}
      className={`prev ${currentPage === 1 ? "disabled" : ""}`}
    >
      {"<"}
    </button>

    {/* show page numbers */}
    {getPaginationGroup().map((item, index) => (
      <button
        key={index}
        onClick={changePage}
        className={`paginationItem ${
            currentPage === item ? "active" : null
        }`}
      >
        <span>{item}</span>
      </button>
    ))}

    {/* next button */}
    <button
      onClick={goToNextPage}
      className={`next ${currentPage >= totalPages ? "disabled" : ""}`}
    >
      {">"}
    </button>
    <button
      onClick={gotolastPage}
      className={`next disabled`}
    >
      {totalPages}
    </button>
  </Paginationwrap>
  );
}

const Paginationwrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #4a4a68;

  > button {
    background: #f4f9ff;
    box-shadow: 0px 0px 7px rgba(34, 103, 206, 0.5);
    border-radius: 5px;
  }
  .paginationItem {
    border: none;
    background: #f4f9ff;
    padding: 10px;
    height: 20px;
    width: 20px;
    position: relative;
    margin: 30px 5px;
    cursor: pointer;
  }
  .paginationItem.active {
    border: 1px solid #333333;
  }
  .prev,
  .next {
    background: #f4f9ff;
    border: none;
    padding: 10px;
    height: 20px;
    width: 20px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    margin: 0 10px;
    cursor: pointer;
  }
`;

