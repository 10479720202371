import axios from "axios";
import React from "react";
import { useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import { BsPerson } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

interface props {
  title: string;
}

export const NavHeader = ({ title }: props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [dropdown, setDropdown] = React.useState(false);
  const Baseurl = process.env.REACT_APP_BASEURL;
  const getToken: any = localStorage.getItem("eftd-admin-token");
  const tokenObject: any = JSON.parse(getToken);
  const token = tokenObject?.data?.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const getUser = async () => {
    const response = await axios.get(
      `${Baseurl}/api/admin/auth/currentadmin`,
      config
    );
    return response?.data?.data;
  };
  const { data: user } = useQuery("ADMIN", getUser);

  const handleDropDown = () => {
    setDropdown(!dropdown);
  };
  const logout = () => {
    localStorage.removeItem("eftd-admin-token");
    queryClient.clear();
    navigate("/login");
  };
  return (
    <Header>
      <ArrowTitleWrapper>
        <Title>{title}</Title>
      </ArrowTitleWrapper>

      <Profile>
        <Notification>
          <img width={25} src="/notifications.svg" alt="notifications icon" />
        </Notification>
        <p
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            background: "#000000",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BsPerson style={{ color: "white", fontSize: "24px" }} />
        </p>
        <div>
          <p className="name">{user?.name}</p>
          <p className="role">{user?.isSuperAdmin ? "Super Admin" : "Admin"}</p>
        </div>
        <div
          style={{ cursor: "pointer" }}
          className="dropdown"
          onClick={handleDropDown}
        >
          <img width={10} height={10} src="/dropdown.svg" alt="" />
        </div>
        {dropdown && (
          <div className="dropdown-content">
            <p onClick={logout}>Logout</p>
          </div>
        )}
      </Profile>
    </Header>
  );
};

const Header = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 92%;
  background-color: #ffffff;
  margin-left: 3%;
  /* margin-left: 15vw; */
  padding: 0.5em 1.5em;
  border-radius: 0.5em;
`;

const ArrowTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4%;
  width: 50%;
`;
const BackArrow = styled.div`
  /* height: 44px; */
  /* width: 52px; */
  /* border-radius: 8px;
  background: #ffffff; */
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03); */
  /* border-radius: 8px; */
  /* display: grid;
  place-items: center; */
  cursor: pointer;
`;
const Notification = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const Profile = styled.div`
  display: flex;
  gap: 10px;
  .name {
    color: #000000;
    font-weight: 600;
    text-transform: capitalize;
  }
  .role {
    color: #f66d00;
  }
`;
const Title = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #04030b;
`;
