const colours = {
  home: "#ebf0fc",
  primary: "#FE7F20",
  white: "#ffffff",
  black: "#000000",
  background: "#E1F8FA",
  secondary: "#47ECF5",
  third: "#3f4196",
  orange: '#F66D00'
};

export default colours;
