import { Link } from "react-router-dom";
import styled from "styled-components";
import { useQuery, useQueryClient } from "react-query";
import { AdminActivity } from "../../components/AdminActivity";
import { Cityperformance } from "../../components/Cityperformance";
import { NavHeader } from "../../components/NavHeader";
import { Piechart } from "../../components/Piechart";
import { Sidebar } from "../../components/Sidebar";
import { Swapstation } from "../../components/Swapstation";
import { TotalAmount } from "../../components/TotalAmount";
import axios from "axios";
import Spinner from "../../components/Spinner";
import { Cardwithicon } from "../../components/Cardwithicon";
import PackageIcon from "../../assets/package.svg";
import riderIcon from "../../assets/ridericon.svg";
import usersIcon from "users.svg";
import { Linechart } from "../../components/Linechart";
import { Verticalbarchart } from "../../components/VerticalBarChart";
import { getDataForRevenueAnalysis, getDataForSubscriberRevenue, getDateRangeFromDuration } from "../../utils/utils";
import { useState } from "react";

const Dashboard = () => {
  const Baseurl = process.env.REACT_APP_BASEURL;
  const getToken: any = localStorage.getItem("eftd-admin-token");
  const tokenObject: any = JSON.parse(getToken);
  const token = tokenObject?.data?.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const queryClient = useQueryClient();
  const admins: any = queryClient.getQueryData("ALLADMIN");
  const CANCELLEDORDERS: any = queryClient.getQueryData("CANCELLEDORDERS");
  const RIDERS: any = queryClient.getQueryData("RIDERS");
  const [filter, setFilter]= useState('365d')
  const amountLost = CANCELLEDORDERS?.reduce((total: number, item: any) => {
    if (!!item?.estimatedCost) {
      total += Number(item?.estimatedCost);
    }

    return total;
  }, 0);
  const getOrders = async () => {
    const response = await axios.get(
      `${Baseurl}/api/admin/orders/order-count`,
      config
    );
    return response?.data?.total;
  };
  const getCustomers = async () => {
    const response = await axios.get(
      `${Baseurl}/api/admin/customers/customerCount`,
      config
    );
    return response?.data?.total;
  };
  const { data: ordersCount, isLoading } = useQuery("ORDERSCOUNT", getOrders);
  const { data: customersCount } = useQuery("CUSTOMERCOUNT", getCustomers);

  const getPaidOrders = []?.filter(
    (item: any) => item?.payment?.transactionId
  );
  const amountCompleted = getPaidOrders?.reduce((total: number, item: any) => {
    if (!!item?.estimatedCost) {
      total += Number(item?.estimatedCost);
    }

    return total;
  }, 0);

  const getDashboardOrders = async (startDate: string, endDate: string) => {
    const response = await axios.get(`${Baseurl}/api/admin/dashboard/order-count`,  { ...config,params:{
      startDate, endDate
    }})
    return response?.data?.data
  }
  const getSubscriptionData = async (startDate: string, endDate: string) => {
    const response = await axios.get(`${Baseurl}/api/admin/dashboard/subscription-count`, { ...config,params:{
      startDate, endDate
    }})
    return response?.data?.data
  }
  const getDeliveryAnalysis = async (startDate: string, endDate: string) => {
    const response = await axios.get(`${Baseurl}/api/admin/dashboard/delivery-analysis`, { ...config,params:{
      startDate, endDate
    }})
    return response?.data?.data
  }
  const getRevenueByCustomerAnalysis = async (startDate: string, endDate: string) => {
    const response = await axios.get(`${Baseurl}/api/admin/dashboard/revenue-by-customer`, { ...config,params:{
      startDate, endDate
    }})
    return response?.data?.data
  }
  const getRevenueByDeliveryAnalysis = async (startDate: string, endDate: string) => {
    const response = await axios.get(`${Baseurl}/api/admin/dashboard/revenue-by-delivery`, { ...config,params:{
      startDate, endDate
    }})
    return response?.data?.data
  }
  

  const { data: orderData } = useQuery(['TOTAL_ORDERS',filter], ()=>getDashboardOrders(getDateRangeFromDuration(filter)?.startDate,getDateRangeFromDuration(filter)?.endDate))
  const { data: subData } = useQuery(['TOTAL_SUBSCRIPTIONS',filter],()=> getSubscriptionData(getDateRangeFromDuration(filter)?.startDate,getDateRangeFromDuration(filter)?.endDate ))
  const { data: deliveryAnalysis } = useQuery(['DELIVERY_ANALYSIS', filter], () => getDeliveryAnalysis(getDateRangeFromDuration(filter)?.startDate,getDateRangeFromDuration(filter)?.endDate ))
  const { data: subscriberAnalysis } = useQuery(['REVENUE_ANALYSIS', filter], () => getRevenueByCustomerAnalysis(getDateRangeFromDuration(filter)?.startDate,getDateRangeFromDuration(filter)?.endDate ))
  const { data: revenueDeliveryAnalysis } = useQuery(['DELIVERY_REVENUE_ANALYSIS', filter], () => getRevenueByDeliveryAnalysis(getDateRangeFromDuration(filter)?.startDate,getDateRangeFromDuration(filter)?.endDate ))
  const date = [
    {name: '7 days', value: '7d'},
    {name: '30 days', value: '30d'},
    {name: '1 year', value: '365d'}
  ]
  const [transformedDataset , uniqueDays] = getDataForRevenueAnalysis(revenueDeliveryAnalysis?.revenueByDeliveryTypeData)
  const [subscriptionDataset , days] = getDataForSubscriberRevenue(subscriberAnalysis?.getRevenueByCustomerData)
  return (
    <Body>
      <Sidebar />
      {isLoading ? (
        <Wrapper>
          <Spinner componentlevel />
        </Wrapper>
      ) : (
        <Wrapper>
          <NavHeader title="Dashboard" />
          <div style={{display: 'flex', justifyContent:'flex-end', width: '90%', margin: 'auto'}}>
            <Dropdown
              name="assignedRider"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              {date?.map((dateformat: any) => (
                <option value={dateformat.value}>{dateformat.name}</option>
              ))}
            </Dropdown>
          </div>
          <TopWrapper className="amount-info">
            <Cardwithicon
              title={"Orders"}
              amount={ordersCount || 0}
              icon={PackageIcon}
              backgroundColor="#FFF4EC"
            />
             <Cardwithicon
              title={"Customers"}
              amount={customersCount || 0}
              icon={'/users.svg'}
              backgroundColor="#B1F6F62B"
            />
            <Cardwithicon title={"Riders"} amount={RIDERS?.length || 0} icon={"/motorcycle-helmet.svg"} backgroundColor="#FFEDDF" />
           

            <Cardwithicon
              title={"Revenue Generated"}
              amount={`N${(amountCompleted /100).toFixed(2).toLocaleString()}`}
              textcolor="#219653"
              icon={'/Graph.svg'}
              backgroundColor="#E5EEED"
            />
            <Cardwithicon
              title={"Amount lost "}
              amount={`N${(amountLost /100).toFixed(2).toLocaleString()}`}
              textcolor="#E12F2F"
              backgroundColor="#F6A9A92B"
              icon={'/Send.svg'}
            />
            <Cardwithicon
              title={"Customer Wallet balance "}
              amount={`N${(amountLost /100).toFixed(2).toLocaleString()}`}
              textcolor="#F66D00"
              backgroundColor="#FFEDDF"
              icon={'/Vector.svg'}
            />
          </TopWrapper>
          <TopWrapper>
            <Top>
              <Piechart title = {'orders'} orderInfo={orderData?.orderCount} />
            </Top>
            <Top>
              <Piechart title = {'customers'} orderInfo={subData?.subscriptionCount} />
            </Top>
          </TopWrapper>

          {/* <Cityperformance /> */}
          {/* <Swapstation /> */}
          <TopWrapper className="info">
            <Linechart info = {deliveryAnalysis}/>
            <AdminActivity />
          </TopWrapper>
          <TopWrapper className="amount-info">
          <Verticalbarchart title={'Customer Type'}  labels={days} datas={subscriptionDataset}/>
          <Verticalbarchart title={'Delivery Location'} labels={uniqueDays} datas={transformedDataset}/>
           
            {/* <Verticalbarchart/> */}
          </TopWrapper>
          {/* <TopWrapper>
            <TotalAmount
              link="/admin"
              title={"Total Number of Admins"}
              amount={admins?.length}
              isSelected={true}
            />
            <TotalAmount
              title={"Total Customer Wallet Balance"}
              amount={"NGN 0"}
              isSelected={false}
            />
          </TopWrapper> */}
          {/* <Region>
            <Text>Region and Pricing</Text>
            <ViewList>
              <Link to={"/regions"}>View List</Link>
            </ViewList>
          </Region>
          <Region>
            <Text>Total Customers</Text>
            <ViewList>
              <Link to={"/customers"}>View List</Link>
            </ViewList>
          </Region>
          <Region>
            <Text>No of batteries</Text>
            <ViewList>
              <Link to={"/batteries"}>View List</Link>
            </ViewList>
          </Region> */}
        </Wrapper>
      )}
    </Body>
  );
};

export default Dashboard;

const Body = styled.main`
  display: flex;
`;
const Wrapper = styled.section`
  width: 85vw;
  height: 200vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  margin-left: 15vw;
  background-color: #fafafa;
`;

const Top = styled.div`
  width: 49%;
  background-color: #ffffff;
  padding: 20px 0;
  border-radius: 16px;
`;

const TopWrapper = styled.div`
  display: flex;
  width: 96%;
  margin: 0 auto;
  margin-top: 20px;
  &.amount-info {
    justify-content: space-between;
    /* background: #FFF; */
    gap: 10px;
    flex-wrap: wrap;
    padding: 1%;
  }
  &.info {
    width: 94%;
    background: #FFF;
    padding: 1%;
  }
`;

const Dropdown = styled.select`
  height: 40px;
  font-size: 16px;
  padding-left: 10px;
  background: #FFFFFF;
  color: #828282;
/* Gray 3 */

border: 1px solid #828282;
border-radius: 5px;
`;

const Region = styled.div`
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 92%;
  margin: 20px auto;
  padding: 10px 2%;
`;

const ViewList = styled.button`
  width: 223px;
  height: 58px;
  background: #3f4196;
  border-radius: 16px;
  border: none;
  outline: none;

  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  /* identical to box height */

  color: #ffffff;
`;

const Text = styled.p`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  color: #4d4949;
`;
