import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
import { Sidebar } from "../../components/Sidebar";
import styled from "styled-components";
import { NavHeader } from "../../components/NavHeader";
import { PersonalDetails } from "../../components/PersonalDetails";
import { NextOfKin } from "../../components/NextOfKin";
import { Documents } from "../../components/Documents";
import { getDate, getTime } from "../../utils/utils";
import { Box } from "../../components/Box";
import OrderDetails from "../../components/OrderDetails";

export const SingleRider = () => {
  const Baseurl = process.env.REACT_APP_BASEURL;
  const { riderId } = useParams();
  const [state, setState] = useState("p2p");
  const [orderIds, setOrderIds] = useState([]);
  const getToken: any = localStorage.getItem("eftd-admin-token");
  const tokenObject: any = JSON.parse(getToken);
  const token = tokenObject?.data?.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const getRider = async () => {
    const response = await axios.get(
      `${Baseurl}/api/admin/riders/getriders/${riderId}`,
      config
    );
    return response?.data?.data;
  };
  const getRiderpickuphistory = async () => {
    const response = await axios.get(
      `${Baseurl}/api/admin/orders?limit=100&page=1&skip=0&pickupRider=${riderId}`,
      config
    );
    return response?.data?.data?.documents;
  };
  const getRiderdeliveryhistory = async () => {
    const response = await axios.get(
      `${Baseurl}/api/admin/orders?limit=100&page=1&skip=0&deliveryRider=${riderId}`,
      config
    );
    return response?.data?.data?.documents;
  };

  const { data: rider, isLoading } = useQuery(`RIDER_${riderId}`, getRider);
  const { data: pickupriderHistory, isLoading: riderhistoryLoading } = useQuery(
    `RIDERHISTORY_${riderId}`,
    getRiderpickuphistory
  );
  const { data: deliveryriderHistory, isLoading: deliveryriderhistoryLoading } =
    useQuery(`DELIVERYRIDERHISTORY_${riderId}`, getRiderdeliveryhistory);



  const getData = (status: string, datalist: any) => {
    return datalist?.filter((data: any) => data.orderStatus === status);
  };


  return (
    <Body>
      <Sidebar></Sidebar>
      <Wrapper>
        <NavHeader title={`Rider Information`} />
        <PersonalDetails
          name={rider?.fullName}
          profilePhoto={rider?.profilePhoto}
          phoneNumber={rider?.phoneNumber}
          email={rider?.email}
          address={rider?.riderAddress}
          isVerified={rider?.status === "VERIFIED"}
        />
        {/* <Tab>
          <P2p stage={state} onClick={() => setState("p2p")}>
            Pickups
          </P2p>
          <Deliveries stage={state} onClick={() => setState("delivery")}>
            Delivery
          </Deliveries>
        </Tab>
        {state === "p2p" && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "20px",
                flexWrap: "wrap",
                gap: "20px",
                width: "95%",
                margin: "20px auto",
              }}
            >
              <Box
                number={getData("processing", pickupriderHistory)?.length || 0}
                status={"Processing"}
                textColor={"#B247DD"}
                BackColor={"#FCF3FF"}
              />
              <Box
                number={getData("pickup", pickupriderHistory)?.length || 0}
                status={"Picked up"}
                textColor={"#C4C919"}
                BackColor={"#FEFEED"}
              />
              <Box
                number={getData("delivered", pickupriderHistory)?.length || 0}
                status={"Delivered"}
                textColor={"#1CA71C"}
                BackColor={"#F0FFF0"}
              />
              <Box
                number={getData("cancelled", pickupriderHistory)?.length || 0}
                status={"cancelled"}
                textColor={"#3737D0"}
                BackColor={"#F9F9FF"}
              />
              <Box
                number={getData("in-transit", pickupriderHistory)?.length || 0}
                status={"In Transit"}
                textColor={"#333333"}
                BackColor={"#E1F8F9"}
              />
            </div>
            <OrderDetails
              data={pickupriderHistory}
              pageLimit={undefined}
              dataLimit={10}
              orderIds={setOrderIds}
              currentPageNo={undefined}
            />
          </>
        )} */}
        {/* {state === "delivery" && (
          <>
            {" "}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "20px",
                flexWrap: "wrap",
                gap: "20px",
                width: "95%",
                margin: "20px auto",
              }}
            >
              <Box
                number={getData("processing", deliveryriderHistory)?.length || 0}
                status={"Processing"}
                textColor={"#B247DD"}
                BackColor={"#FCF3FF"}
              />
              <Box
                number={getData("pickup", deliveryriderHistory)?.length || 0}
                status={"Picked up"}
                textColor={"#C4C919"}
                BackColor={"#FEFEED"}
              />
              <Box
                number={getData("delivered", deliveryriderHistory)?.length || 0}
                status={"Delivered"}
                textColor={"#1CA71C"}
                BackColor={"#F0FFF0"}
              />
              <Box
                number={getData("cancelled", deliveryriderHistory)?.length || 0}
                status={"cancelled"}
                textColor={"#3737D0"}
                BackColor={"#F9F9FF"}
              />
              <Box
                number={getData("in-transit", deliveryriderHistory)?.length || 0}
                status={"In Transit"}
                textColor={"#333333"}
                BackColor={"#E1F8F9"}
              />
            </div>
            <OrderDetails
              data={deliveryriderHistory}
              pageLimit={undefined}
              dataLimit={10}
              orderIds={setOrderIds}
              currentPageNo={undefined}
            />
          </>
        )} */}
      </Wrapper>
    </Body>
  );
};

const Body = styled.main`
  display: flex;
`;
const Wrapper = styled.aside`
  width: 85vw;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  margin-left: 15vw;
  background-color: #fafafa;
`;

const HistoryList = styled.section`
  background-color: #fafafa;
  width: 100%;
  @media (max-width: 425px) {
    width: 100%;
    background-color: #ffffff;
  }
`;

const Listdiv = styled.div`
  display: flex;
  align-items: center;
  gap: 1%;
  width: 90%;
  margin: 10px auto;
  padding: 0px 20px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
`;

const ListImage = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e6ebf8;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ListAddress = styled.p`
  font-weight: 300;
  color: #3f4196;
  margin: 0;
  width: 30%;
`;

const ListOrders = styled.p`
  font-weight: 300;
  color: #3f4196;
  margin: 0;
  width: 10%;
  text-align: left;
`;

const ListDate = styled.p`
  font-weight: 300;
  color: #3f4196;
  margin: 0;
  width: 20%;
  text-align: left;
`;

const ListAmount = styled.p`
  color: #11b649;
  width: 10%;
`;

const Tab = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: auto;
  padding: 16px;
  border-radius: 12px;
`;

const Pickups = styled.p<{ stage: string }>`
  width: 50%;
  height: 4rem;
  background-color: ${(props) =>
    props.stage === "pickup" ? "#f2f6ff" : "#ffffff"};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const Deliveries = styled.p<{ stage: string }>`
  width: 50%;
  height: 4rem;
  background-color: ${(props) =>
    props.stage === "delivery" ? "#f2f6ff" : "#ffffff"};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const P2p = styled.p<{ stage: string }>`
  width: 50%;
  height: 4rem;
  background-color: ${(props) =>
    props.stage === "p2p" ? "#f2f6ff" : "#ffffff"};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
