import React, {useState} from 'react'
import styled from 'styled-components'
import axios from 'axios';
import { NavHeader } from '../../components/NavHeader';

const fakedata = [
  {
    date: "01/01/2022 08:30 am",
    rider: "Johnson Okoro",
    batterylev: "30% 3.6 kwh",
    bikeNO: "FST4637F8",
    swapStation: "yaba",
  },
  {
    date: "01/01/2022 08:30 am",
    rider: "Johnson Okoro",
    batterylev: "30% 3.6 kwh",
    bikeNO: "FST4637F8",
    swapStation: "yaba",
  },
  {
    date: "01/01/2022 08:30 am",
    rider: "Johnson Okoro",
    batterylev: "30% 3.6 kwh",
    bikeNO: "FST4637F8",
    swapStation: "yaba",
  },
  {
    date: "01/01/2022 08:30 am",
    rider: "Johnson Okoro",
    batterylev: "30% 3.6 kwh",
    bikeNO: "FST4637F8",
    swapStation: "yaba",
  },
  {
    date: "01/01/2022 08:30 am",
    rider: "Johnson Okoro",
    batterylev: "30% 3.6 kwh",
    bikeNO: "FST4637F8",
    swapStation: "yaba",
  },
  {
    date: "01/01/2022 08:30 am",
    rider: "Johnson Okoro",
    batterylev: "30% 3.6 kwh",
    bikeNO: "FST4637F8",
    swapStation: "yaba",
  },
  {
    date: "01/01/2022 08:30 am",
    rider: "Johnson Okoro",
    batterylev: "30% 3.6 kwh",
    bikeNO: "FST4637F8",
    swapStation: "yaba",
  },
  {
    date: "01/01/2022 08:30 am",
    rider: "Johnson Okoro",
    batterylev: "30% 3.6 kwh",
    bikeNO: "FST4637F8",
    swapStation: "yaba",
  },
  {
    date: "01/01/2022 08:30 am",
    rider: "Johnson Okoro",
    batterylev: "30% 3.6 kwh",
    bikeNO: "FST4637F8",
    swapStation: "yaba",
  },
  {
    date: "01/01/2022 08:30 am",
    rider: "Johnson Okoro",
    batterylev: "30% 3.6 kwh",
    bikeNO: "FST4637F8",
    swapStation: "yaba",
  },
  {
    date: "01/01/2022 08:30 am",
    rider: "Johnson Okoro",
    batterylev: "30% 3.6 kwh",
    bikeNO: "FST4637F8",
    swapStation: "yaba",
  },
  {
    date: "01/01/2022 08:30 am",
    rider: "Johnson Okoro",
    batterylev: "30% 3.6 kwh",
    bikeNO: "FST4637F8",
    swapStation: "yaba",
  },

  {
    date: "01/01/2022 08:30 am",
    rider: "Johnson Okoro",
    batterylev: "30% 3.6 kwh",
    bikeNO: "FST4637F8",
    swapStation: "yaba",
  },
  {
    date: "01/01/2022 08:30 am",
    rider: "Johnson Okoro",
    batterylev: "30% 3.6 kwh",
    bikeNO: "FST4637F8",
    swapStation: "yaba",
  },

];

export const BatterSwap = () => {
   const Baseurl = process.env.REACT_APP_BASEURL;
   const getToken: any = localStorage.getItem("eftd-admin-token");
   const tokenObject: any = JSON.parse(getToken);
   const token = tokenObject?.data?.token;
   const config = {
     headers: {
       Authorization: `Bearer ${token}`,
     },
   };
  const [batteryCompanyId, setBatteryCompanyId]= useState("");
 const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
   e.preventDefault();

   axios
     .post(
       `${Baseurl}/api/admin/battery/registerbattery`,
       { batteryCompanyId },
       config
     )
     .then((res) => console.log(res))
     .catch((err) => console.log(err));
 };
  return (
    <Wrapper>
      <NavHeader title="Battery Swap History" />
      <InputWrapper>
        <SearchIcon>
          <img width={"100%"} src="/searchicongrey.png" alt="" />
        </SearchIcon>
        <Inputs placeholder="Search" />
      </InputWrapper>
      
      <Body>
        {fakedata.map((item, index) => (
          <Card key={index}>
            <TextWrapper>
              <LeftText>Date:</LeftText>
              <RightText>{item.date}</RightText>
            </TextWrapper>
            <TextWrapper>
              <LeftText>Rider:</LeftText>
              <RightText>{item.rider}</RightText>
            </TextWrapper>
            <TextWrapper>
              <LeftText>Battery Level:</LeftText>
              <RightText>{item.batterylev}</RightText>
            </TextWrapper>
            <TextWrapper>
              <LeftText>Bike No.:</LeftText>
              <RightText>{item.bikeNO}</RightText>
            </TextWrapper>
            <TextWrapper>
              <LeftText>Swap station:</LeftText>
              <RightText>{item.swapStation}</RightText>
            </TextWrapper>
          </Card>
        ))}
      </Body>
    </Wrapper>
  );
}

const Wrapper = styled.aside`
  width: 100vw;
  height: 200vh;
  overflow-y: scroll;
  background-color: #ffffff;
  padding-bottom: 40px;
`;

const SearchIcon = styled.p`
  height: 20px;
  width: 20px;
  margin-left: 2%;
`;

const InputWrapper = styled.div`
  width: 90%;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #fafafa;
  border-radius: 12px;
  padding: 5px;
`;
const Inputs = styled.input`
  width: 100%;
  background: #fafafa;
  border: none;
  outline: none;
  padding: 10px 0;
  border-radius: 8px;
  padding-left: 1%;
`;

const Body = styled.main`
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    width: 90%;
    margin: 0 auto;

`


const Card = styled.div`
  min-width:434px;
  width: 434px;
  height: 322px;
  background: #fafafa;
  border: 1px solid #c9caec;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
    justify-content: space-evenly;
`;
const TextWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    /* margin-bottom: 10px; */
    `
const LeftText = styled.p`
    font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 39px;
  color: #807c7c;
`;
const RightText = styled.p`
    font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 39px;
  /* identical to box height */

  color: #3f4196;
`;