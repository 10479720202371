import { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useQuery } from "react-query";
import { Sidebar } from "../../components/Sidebar";
import { NavHeader } from "../../components/NavHeader";
import { Link } from "react-router-dom";
import Spinner from "../../components/Spinner";
import Pagination from "../../components/Pagination";
import colours from "../../styles/appColours";
import { useRoles } from "../../hooks/useRoles";
import Filter from "../../components/Filter";


export const Riderspage = () => {
  const getToken:any = localStorage.getItem('eftd-admin-token')
  const [searchFilter, setSearchFilter] = useState<any>()
  const tokenObject:any = JSON.parse(getToken);
  const token = tokenObject?.data?.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const isSuperAdmin = useRoles()
  const Baseurl = process.env.REACT_APP_BASEURL;
  const [search, setSearch] = useState("");
  const getallRiders = async () => {
    const response = await axios.get(`${Baseurl}/api/admin/riders/getriders/`, {...config, params: {...searchFilter}})
    return response?.data?.data?.documents
  }

   const getPendingRiders = async () => {
    const response = await axios.get(`${Baseurl}/api/admin/riders/getpendingriders/`, config)
    return response?.data?.data
  }

  const {data: allRidersData, isLoading } = useQuery(['VERIFIEDRIDERS', searchFilter], getallRiders )
  const {data: allPendingRiders } = useQuery('PENDINGRIDERS', getPendingRiders )
  const verifiedRiders = allRidersData && allRidersData?.filter(
    (item: { isVerified: boolean; status: string }) =>
      item.isVerified && item.status === "VERIFIED"
  );
const getSearchedArray = verifiedRiders?.filter((item: any) =>
  item?.fullName.toLowerCase().includes(search.toLowerCase())
);
 

  return (
    <Body>
      <Sidebar />
      <Wrapper>
        <NavHeader title="Riders" />
        <Filter handleSearchFilter={setSearchFilter} showStatusFilter={false}/>
        {isSuperAdmin && 
        <Pending>
          <PendingWrapper>
            <Text>Pending Verifications</Text>
            <PendingNumber>{allPendingRiders?.length}</PendingNumber>
          </PendingWrapper>
          <ViewPending>
            <Link to={"/pendingriders"}>View</Link>
          </ViewPending>
        </Pending>
}
        {isLoading ? (
          <Spinner componentlevel />
        ) : (
          <Table>

            {getSearchedArray?.length > 0 ? (
              <>
                <Pagination
                  type="riders"
                  data={getSearchedArray}
                  pageLimit={5}
                  dataLimit={10}
                />
              </>
            ) : (
              <h1>No Posts to display</h1>
            )}
          </Table>
        )}
      </Wrapper>
    </Body>
  );
};
const Body = styled.main`
display: flex;
`
const Wrapper = styled.aside`
  width: 85vw;
  overflow-y: scroll;
  margin-left: 15vw;
 background: #F8F9FB;
`;

const Pending = styled.div`
  background: #ffffff;
  border: 1px solid ${colours.primary};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px auto;
  width: 86%;
  padding: 5px 2%;
`;
const PendingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const Text = styled.p`
    font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */
  color: #000000;

`;
const PendingNumber = styled.p`
    font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  color: ${colours.primary};
`;
const ViewPending = styled.button`
  width: 106px;
  height: 42px;
  background: ${colours.primary};
  border-radius: 5px;
    font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  /* identical to box height */
  border: none;
  outline: none;
  color: #ffffff;
  cursor: pointer;
  a {
    color: ${colours.white};
  }
`;
const SearchIcon = styled.p`
  height: 20px;
  width: 20px;
  margin-left: 2%;
`;

const InputWrapper = styled.div`
  width: 90%;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 5px;
`;
const Inputs = styled.input`
  width: 100%;
  background-color: #ffffff;
  border: none;
  outline: none;
  padding: 10px 0;
  border-radius: 8px;
  padding-left: 1%;
`;
const Table = styled.main`
  margin: 20px auto;
  width: 90%;
`;
