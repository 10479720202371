import React, { useState } from 'react'
import { BsPerson } from 'react-icons/bs';
import styled from 'styled-components'
import { Capitalize } from '../utils/utils';
interface props {
  name?: string;
  profilePhoto: string;
  phoneNumber: string;
  email:string;
  bikeNo?: string;
  address: string;
  isVerified: boolean;
}
export const PersonalDetails = ({name,profilePhoto,phoneNumber,email,bikeNo,address, isVerified}:props) => {
  const [state, setState] = useState("day");
  return (
    <Wrapper>
      <Body>
        <UserProfile>
          <UserImage>
          <Image src={profilePhoto} alt="" />
          </UserImage>
          <UserName>{`${Capitalize(name || '')}`}</UserName>
          <Active
            style={{
              color: isVerified ? " #219653" : " #EB5757",
              background: isVerified ? "#C5F8C4" : "#FFE8E8",
            }}
          >
            {isVerified ? "Active Rider" : "Inactive Rider"}
          </Active>
          <UserDatas>
            <Title>Personal Details</Title>
            <label htmlFor="">Full Name</label>
            <UserInfo
              disabled={true}
              value={`${Capitalize(name || '')}`}
            />
            <label htmlFor="">Email Address</label>
            <UserInfo
              disabled={true}
              style={{ background: "#F2F2F2" }}
              value={email}
            />
            <label htmlFor="">Phone Number</label>
            <UserInfo disabled={true} value={phoneNumber} />
            <label htmlFor="">Bike No.</label>
            <UserInfo disabled={true} value={bikeNo} />
            <label htmlFor="">Address</label>
            <UserInfo disabled={true} value={address} />
          </UserDatas>
          {isVerified &&
          <UserDatas>
            <Title>Rider Activity</Title>
            <Tab>
            <Time style={{background: state === "day" ? ' #F66D00' : "#FCCEAA", borderRadius: "10px 0px 0px 10px"}} onClick={()=>setState('day')}>Today</Time>
            <Time style={{background: state === "week" ? ' #F66D00' : "#FCCEAA"}} onClick={()=>setState('week')}>Weekly</Time>
            <Time style={{background: state === "month" ? ' #F66D00' : "#FCCEAA",  borderRadius: "0px 10px 10px 0px"}} onClick={()=>setState('month')}>Monthly</Time>

            </Tab>
            <UserRole>Completed Trips: {''}</UserRole>
            <UserRole>Pickups: {""}</UserRole>
            <UserRole>Deliveries: {""}</UserRole>
            <UserRole>Battery swaps:{""} </UserRole>
          </UserDatas>
}
        </UserProfile>
      </Body>
    </Wrapper>
  );
}

const Wrapper = styled.div`
    background: #FFFFFF;
    border-radius: 12px;
    width: 92%;
    margin: auto;
    padding: 10px 2%;
`
const Time = styled.p`
  width: 100%;
  height: 2.3rem;
  background-color: "#FCCEAA";
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Tab = styled.div`
   display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: auto;
  padding: 16px;

`


const Body = styled.div`
    display: flex;
    gap: 30px;
    margin: 10px 0px;
`

const ImageWrap = styled.div`
  width: 270px;
  height: 270px;
  background: #ffffff;
  border: 1px solid #aac1fc;
  border-radius: 40px;
`;
const Image = styled.img`
  border-radius: 40px;
  width: 100%;
  height: 100%;
`;
const DetailsWrapper = styled.div`

`
const Line = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 30px;
`
const Left = styled.p`
    font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #b0a7a7;
`;

const Right = styled.p`
    font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #6c6868;
`;

const UserProfile = styled.main`
  background-color: #ffffff;
  width: 60%;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 16px;
  padding: 20px 0px 60px 0px;

  @media (max-width: 600px) {
    width: 95%;
  }
`;
const UserImage = styled.div`
  height: 88px;
  width: 88px;
  background-color: #f2efef;
  border-radius: 50%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const UserName = styled.h2`
  color: #333333;
  font-size: 16px;
`;

const Active = styled.h2`
  font-size: 16px;
  padding: 5px 20px;
  border-radius: 5px;
`;

const UserRole = styled.p`
  display: flex;
  align-items: center;
  gap: 3px;
`;
const UserDatas = styled.div`
  display: flex;
  padding: 30px;
  flex-direction: column;
  width: 80%;
  border: 0.5px solid #13131a;
  text-align: left;
  border-radius: 5px;
  margin-top: 20px;

  label {
    color: #333333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-top: 10px;
  }

  @media (max-width: 600px) {
    width: 95%;
  }
`;
const UserInfo = styled.input`
  padding: 5px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  border-radius: 5px;
  border: 0.5px solid #13131a;
  padding: 10px;
`;

const Copyable = styled.div`
  padding: 5px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #333333;
  border-radius: 5px;
  border: 0.5px solid #13131a;
  background: #f2f2f2;
  padding: 10px;
`;
const Title = styled.h2`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: left;

  /* New Orange */

  color: #f66d00;
`;

const Text = styled.p`
  text-align: left;
  /* width: 80%; */
  margin: 0 auto;
  font-weight: 300;
  color: #4d4949;
`;