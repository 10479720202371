import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 2,
  borderRadius:"4px",
  p: 4,
};

type Props = {
    title?: string;
    content: any;
    open: boolean;
    close: any;
    height?: any;
    width?: any;
}

export default function BasicModal({title,content, open,close, height, width}: Props) {
  return (
    <>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{...style, height: height, width: width}}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <div id="modal-modal-description" >
            {content}
          </div>
        </Box>
      </Modal>
    </>
  );
}
