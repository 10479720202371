import styled from "styled-components";
import { NavHeader } from "../../components/NavHeader";
import { Sidebar } from "../../components/Sidebar";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import { Box } from "../../components/Box";
import OrderDetails from "../../components/OrderDetails";
import Spinner from "../../components/Spinner";
import Feedback from "../../components/Feedback";
import { useRoles } from "../../hooks/useRoles";
import Filter from "../../components/Filter";

export const Orderhistory = () => {
  const queryClient = useQueryClient();
  const history = useNavigate();
  const location = useLocation();
  const savedpageNumber = location?.search.charAt(location?.search.length - 1);
  const riders: any = queryClient.getQueryData("RIDERS");
  const [feedback, setFeedback] = useState("");
  const [open, setOpen] = useState(false);
  const [updateStatus, setUpdateStatus] = useState("");
  const [orderIds, setOrderIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(+savedpageNumber || 1);
  const [searchFilter, setSearchFilter] = useState<any>({});
  const [loading, setLoading] = useState({
    update: false,
    search: false,
  });
  const isSuperAdmin = useRoles();
  const Baseurl = process.env.REACT_APP_BASEURL;
  const getToken: any = localStorage.getItem("eftd-admin-token");
  const tokenObject: any = JSON.parse(getToken);
  const token = tokenObject?.data?.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const getOrders = async () => {
    const response = await axios.get(
      `${Baseurl}/api/admin/orders?limit=20&page=${currentPage}`,
      {...config, params: { ...searchFilter }}
    );

    return response?.data?.data;
  };


  const getOrderCount = async () => {
    const response = await axios.get(
      `${Baseurl}/api/admin/orders/order-count`,
      config
    );
    return response?.data;
  };

  const {
    data: orderCount,
    refetch: refetchCount,
  } = useQuery("ORDERCOUNT",()=>getOrderCount());

  

  useEffect(() => {
    const search: any = localStorage.getItem("searchFilter");
    const pageNum: any = localStorage.getItem("currentPage");
    const getSearch = JSON.parse(search);
    const getPageNo = JSON.parse(pageNum);

    setCurrentPage(getPageNo)
    setSearchFilter((prev: any) => {
      return { ...prev, ...getSearch };
    });
  
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', currentPage.toString() );
    history({ search: searchParams.toString() });
  }, [currentPage, history, location.search]);


  useEffect(()=> {
    localStorage.setItem("searchFilter", JSON.stringify(searchFilter));
    localStorage.setItem('currentPage', JSON.stringify(currentPage));
  },[searchFilter, currentPage])

  const {
    data: orderData,
    isLoading: isLoadingOrderData,
    refetch: refetchData,
  } = useQuery(
    ["ORDERS", currentPage, searchFilter],
    () => getOrders()
  );

  const userAccess = (userPermission: boolean) => {
    let status = [
      {
        value: "cancelled",
        name: "Cancelled",
      },
      {
        value: "in-transit",
        name: "In Transit",
      },
    ];
    // if (userPermission) {
    //   status = [
    //     {
    //       value: "cancelled",
    //       name: "Cancelled",
    //     },
    //     {
    //       value: "delivered",
    //       name: "Delivered",
    //     },
    //     {
    //       value: "in-transit",
    //       name: "In Transit",
    //     },
    //     {
    //       value: "pickup",
    //       name: "Picked Up",
    //     },
    //     {
    //       value: "processing",
    //       name: "Processing",
    //     }
    //   ];
    // } else {
    //   status = [
    //     {
    //       value: "cancelled",
    //       name: "Cancelled",
    //     },
    //     {
    //       value: "in-transit",
    //       name: "In Transit",
    //     },
    //   ];
    // }
    return status;
  };


  const getCount = (stat: any) => {
    const count = orderCount?.data?.filter(
      (item: any, index: any) => item?._id === stat
    );
    if (count?.length > 0) {
      return count[0]?.count;
    }
    return 0;
  };

  const updateOrdersFunction = async () => {
    if (orderIds.length < 1 || !updateStatus) {
      throw new Error("Error");
    } else {
      const data: any = { orderStatus: updateStatus, orderIds };
      const response = await axios.patch(
        `${Baseurl}/api/admin/orders/multiple-orders`,
        data,
        config
      );
      return response;
    }
  };

  const updateOrders = async () => {
    try {
      setLoading({ ...loading, update: true });
      await updateOrdersFunction();
      refetchData();
      await refetchCount();
      setFeedback("success");
      setOpen(true);
      setLoading({ ...loading, update: false });
    } catch (err) {
      setLoading({ ...loading, update: false });
      setFeedback("failed");
      setOpen(true);
    }
  };

  const handleSearchFilter = (status: string) => {
    setSearchFilter((prev: any) => ({...prev, orderStatus: status}))
    setCurrentPage(1)
  }
  
  return (
    <Body>
      <Sidebar />

      <Wrapper>
        <NavHeader title="Order Management" />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "20px",
            flexWrap: "wrap",
            gap: "20px",
            width: "95%",
            margin: "30px auto",
          }}
        >
           <Box
            onClick={() => setSearchFilter((prev: any) => {
              const { orderStatus, ...rest } = prev;
              return rest;
            })}
            number={orderCount?.total || 0}
            status={"All Orders"}
            textColor={!searchFilter.orderStatus ? '#ffffff': "#333333"}
            BackColor={!searchFilter.orderStatus ? '#FE7F20' : "#ffffff"} 
            statusColor={!searchFilter.orderStatus? '#ffffff': "#3737D0"}   
            />
          <Box
               onClick={()=>handleSearchFilter('processing')}
            number={getCount("processing") || 0}
            status={"Processing"}
            textColor={searchFilter.orderStatus === 'processing' ? '#ffffff':"#333333"}
            BackColor={ searchFilter.orderStatus === 'processing' ? '#FE7F20' :"#ffffff"}
            statusColor={searchFilter.orderStatus === 'processing' ? '#ffffff' : "#DBC658"}   
          />
          <Box
              onClick={()=>handleSearchFilter('pickup')}
            number={getCount("pickup")|| 0}
            status={"Picked up"}
            textColor={searchFilter.orderStatus === 'pickup' ? '#ffffff':"#333333"}
            BackColor={searchFilter.orderStatus === 'pickup' ? '#FE7F20' :"#ffffff"}
            statusColor={searchFilter.orderStatus === 'pickup' ? '#ffffff': "#7FE0DF"}   
          />
          <Box
              onClick={()=>handleSearchFilter('in-transit')}
            number={getCount("in-transit") || 0}
            status={"In Transit"}
            textColor={searchFilter.orderStatus === 'in-transit' ? '#ffffff':"#333333"}
            BackColor={searchFilter.orderStatus === 'in-transit' ? '#FE7F20' :"#ffffff"}
            statusColor={searchFilter.orderStatus === '"in-transit' ? '#ffffff': "#6B8D95"}   
          />

          <Box
            onClick={()=>handleSearchFilter('delivered')}
            number={getCount("delivered") || 0}
            status={"Delivered"}
            textColor={searchFilter.orderStatus === 'delivered' ? '#ffffff':"#333333"}
            BackColor={searchFilter.orderStatus === 'delivered' ? '#FE7F20' :"#ffffff"}
            statusColor={searchFilter.orderStatus === 'delivered' ? '#ffffff': "#8C3606"}   
          />
          <Box
            onClick={()=>handleSearchFilter('cancelled')}
            number={getCount("cancelled") || 0}
            status={"Cancelled"}
            textColor={searchFilter.orderStatus === 'cancelled' ? '#ffffff':"#333333"}
            BackColor={searchFilter.orderStatus === 'cancelled' ? '#FE7F20' :"#ffffff"}
            statusColor={searchFilter.orderStatus === 'cancelled' ? '#ffffff': "#CC444A"}   
          />
          <Box
              onClick={()=>handleSearchFilter('verified')}
            number={getCount("verified") || 0}
            status={"Verified"}
            textColor={searchFilter.orderStatus === 'verified' ? '#ffffff':"#333333"}
            BackColor={searchFilter.orderStatus === 'verified' ? '#FE7F20' :"#ffffff"}
            statusColor={searchFilter.orderStatus === 'verified' ? '#ffffff': "#FE7F20"}   
          />
          <Box
               onClick={()=>handleSearchFilter('ordered')}
            number={getCount("ordered") || 0}
            status={"Ordered"}
            textColor={searchFilter.orderStatus === 'ordered' ? '#ffffff':"#333333"}
            BackColor={searchFilter.orderStatus === 'ordered' ? '#FE7F20' :"#ffffff"}
            statusColor={searchFilter.orderStatus === 'ordered' ? '#ffffff': "#696969"}   
          />
          <Box
               onClick={()=>handleSearchFilter('failed-after-payment')}
            number={getCount("failed-after-payment") || 0}
            status={"Failed"}
            textColor={searchFilter.orderStatus === 'failed-after-payment' ? '#ffffff':"#333333"}
            BackColor={searchFilter.orderStatus === 'failed-after-payment' ? '#FE7F20' :"#ffffff"}
            statusColor={searchFilter.orderStatus === 'failed-after-payment' ? '#ffffff': "#1E1E20"}   
          />
         
        </div>
        <Filter handleSearchFilter={setSearchFilter} showRiderFilter={true} loading={isLoadingOrderData}/>
        <Updateorder>
          <div>
            <label htmlFor="">Status</label>
            <select onChange={(e) => setUpdateStatus(e.target.value)}>
              <option value={""}>Select</option>
              {userAccess(isSuperAdmin).map((status) => (
                <option value={status.value}>{status.name} </option>
              ))}
            </select>
          </div>
          <button onClick={updateOrders}>
            {loading.update ? <Spinner /> : "Update order"}
          </button>
        </Updateorder>
        <HistoryList>
          {isLoadingOrderData ? (
            <Spinner componentlevel={true} />
          ) : (
            <OrderDetails
              data={orderData?.documents}
              pageLimit={undefined}
              dataLimit={20}
              orderIds={setOrderIds}
              pageDetails={orderData?.paging}
              currentPageNo={setCurrentPage}
              pageNum={currentPage}
            />
          )}
        </HistoryList>
        <Feedback
          feedback={feedback}
          open={open}
          close={() => setOpen(false)}
        />
      </Wrapper>
    </Body>
  );
};

const Body = styled.main`
  display: flex;
`;
const Wrapper = styled.main`
  width: 85vw;
  overflow-y: scroll;
  margin-left: 15vw;
  background-color: #fafafa;
  margin-bottom: 10vh;
  @media (max-width: 425px) {
  }
`;

const HistoryList = styled.section`
  background-color: #fafafa;
  width: 100%;
  @media (max-width: 425px) {
    width: 100%;
    background-color: #ffffff;
  }
`;

const Filterbox = styled.div`
  background: #ffffff;
  border-radius: 10px;
  margin: 40px auto;
  width: 95%;
  > h1 {
    padding: 30px 30px 10px 30px;
  }
  > div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5%;
    padding: 30px 150px 30px 30px;
    > div {
      display: flex;
      flex-direction: column;
      margin-top: 30px;

      > input {
        background: #ffffff;
        border: 1px solid #333333;
        border-radius: 10px;
        padding: 10px 10px;
      }

      > select {
        background: #ffffff;
        border: 1px solid #333333;
        border-radius: 10px;
        padding: 10px 0px;
        width: 200px;
        cursor: pointer;
      }
      > label {
        padding-left: 20px;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    > button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      background: #333333;
      border-radius: 5px;
      color: #ffffff;
      border: none;
      font-weight: 900;
      font-size: 18px;
      line-height: 24px;
    }
    .cancel-button {
      background: #ffffff;
      color: #333333;
      border: 1px solid #333333;
    }
  }
`;

const Updateorder = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 50px 0px 90px 0px;
  gap: 10px;

  > div {
    display: flex;
    flex-direction: column;

    > select {
      background: #ffffff;
      border: 1px solid #333333;
      border-radius: 10px;
      padding: 10px 0px;
      width: 280px;
      cursor: pointer;
    }
    > label {
      padding-left: 20px;
    }
  }
  > button {
    background: #333333;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 40px;
    border: none;
    color: #ffffff;
    cursor: pointer;
  }
`;

