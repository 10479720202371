import axios from "axios";
import React, { useState } from "react";
import { useQueryClient, useQuery, useMutation } from "react-query";
import styled from "styled-components";
import { NavHeader } from "../../components/NavHeader";
import { Sidebar } from "../../components/Sidebar";
import Spinner from "../../components/Spinner";
import Feedback from "../../components/Feedback";
import colours from "../../styles/appColours";
import Filter from "../../components/Filter";

export const SwapStation = () => {
  const queryClient = useQueryClient();
  const [searchFilter, setSearchFilter] = useState<any>()
  const batteries: any = queryClient.getQueryData("BATTERIES");
  const riders: any = queryClient.getQueryData("RIDERS");
  const bikes: any = queryClient.getQueryData("BIKES");
  const Baseurl = process.env.REACT_APP_BASEURL;
  const getToken: any = localStorage.getItem("eftd-admin-token");
  const tokenObject: any = JSON.parse(getToken);
  const token = tokenObject?.data?.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [formdata, setFormdata] = useState({
    rider: "",
    station: "",
    oldBattery: "",
    newBattery: "",
    bike: "",
  });
  const [openModal, setOpenMOdal] = useState(false);
  const [feedback, setFeedback] = useState("");
  const getSwaps = async () => {
    const response = await axios.get(
      `${Baseurl}/api/admin/swap/getswaps`,
      config
    );
    return response?.data?.result;
  };

  let { data: swaphistory, isLoading } = useQuery("SWAPS", getSwaps);


  const handleChange = (e: any) => {
    setFormdata((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const swapBattery = async () => {
    const response = await axios.post(
      `${Baseurl}/api/admin/swap/swap-batteries`,
      formdata,
      config
    );
    return response?.data;
  };

  const swapBatteryMutation = useMutation(() => swapBattery(), {
    onSuccess: () => {
      queryClient.invalidateQueries("SWAPS");
      setFeedback("success");
      setOpenMOdal(true);
    },
    onError: () => {
      setFeedback("failed");
      setOpenMOdal(true);
    },
  });
  const handleSubmit = async () => {
    try {
      swapBatteryMutation.mutateAsync()
    } catch (err){
      setFeedback("failed");
      setOpenMOdal(true);
    }
  };

  return (
    <Body>
      <Sidebar />
      <Wrapper>
        <NavHeader title="Swap Stations" />
        <Filter handleSearchFilter={setSearchFilter}/>
        {/* <SwapForm onSubmit={handleSubmit}>
          <MiddleInput>
            <Dropdown
              name="rider"
              value={formdata?.rider}
              onChange={(e) =>
                setFormdata((prev) => {
                  return { ...prev, rider: e.target.value };
                })
              }
            >
              <option>Select rider</option>
              {riders?.map((rider: any) => (
                <option value={rider._id}>{rider.fullName}</option>
              ))}
            </Dropdown>
            <Dropdown
              name="bike"
              value={formdata?.bike}
              onChange={(e) =>
                setFormdata((prev) => {
                  return { ...prev, bike: e.target.value };
                })
              }
            >
              <option>Select bike</option>
              {bikes?.map((bike: any, key: any) => (
                <option key={key} value={bike._id}>
                  {bike.bikeNumber}
                </option>
              ))}
            </Dropdown>
          </MiddleInput>

          <MiddleInput>
            <Dropdown
              name="battery"
              value={formdata?.oldBattery}
              onChange={(e) =>
                setFormdata((prev) => {
                  return { ...prev, oldBattery: e.target.value };
                })
              }
            >
              <option>Select Old Battery</option>
              {batteries?.map((battery: any) => (
                <option value={battery._id}>{battery.batteryNumber}</option>
              ))}
            </Dropdown>
            <Dropdown
              name="battery"
              value={formdata?.newBattery}
              onChange={(e) =>
                setFormdata((prev) => {
                  return { ...prev, newBattery: e.target.value };
                })
              }
            >
              <option>Select New Battery</option>
              {batteries?.map((battery: any) => (
                <option value={battery._id}>{battery.batteryNumber}</option>
              ))}
            </Dropdown>
          </MiddleInput>
          <SwapInput
            name="station"
            onChange={handleChange}
            type="text"
            placeholder="Station"
          />
          <UpdateButton onClick={handleSubmit}>Submit</UpdateButton>
        </SwapForm> */}
        <SwapHistory>
          {isLoading ? (
            <Spinner componentlevel />
          ) : (
            <Table>
            <RowHeader>
              <IDHeader>Date</IDHeader>
              <IDHeader>Rider Name</IDHeader>
              <IDHeader>Previous Battery No.</IDHeader>
              <IDHeader>Swapped Battery No</IDHeader>
              <IDHeader>Battery %</IDHeader>
              <IDHeader>Swap Station</IDHeader>
            </RowHeader>
            {bikes?.documents?.map((item: any, index: any) => (
              <Row key={index}>
                <ID2>{"23/06/21"}</ID2>
                <ID2>{'Test Test'}</ID2>
                <ID2>{'00001'}</ID2>
                <ID2>{'00002'}</ID2>
                <ID2>{'98%'}</ID2>
                <ID2>Surulere</ID2>
              </Row>
            ))}
          </Table>
          )}
        </SwapHistory>
      </Wrapper>
      <Feedback
        feedback={feedback}
        open={openModal}
        close={() => setOpenMOdal(false)}
      />
    </Body>
  );
};
const Body = styled.main`
  display: flex;
`;
const Wrapper = styled.aside`
  width: 85vw;
  height: 200vh;
  overflow-y: scroll;
  margin-left: 15vw;
  background-color: #fafafa;
`;


const SwapForm = styled.div`
  background-color: #ffffff;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border-radius: 16px;
`;

const SwapInput = styled.input`
  width: 98%;
  background-color: #fdfcfc;
  border: none;
  outline: none;
  /* padding: 10px 0; */
  height: 48px;
  border-radius: 12px;
  margin-bottom: 10px;
  padding-left: 2%;
  font-size: 22px;
  font-weight: 400;
  color: #9d9595;
`;
const MiddleInput = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;
const UpdateButton = styled.button`
  width: 80%;
  margin: 5px auto;
  background-color: #3f4196;
  border: none;
  border-radius: 12px;
  /* padding: 10px 0; */
  color: #ffffff;
  height: 48px;
    font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
`;

const SwapHistory = styled.section`
  /* background-color: #ffffff; */
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border-radius: 16px;
`;

const SwapInfo = styled.p`
  color: #3f4196;
  /* width: 90%; */
  font-size: 20px;
`;

const SwapDate = styled.p`
  color: #777272;
  /* width: 10%; */
  font-size: 20px;
`;

const SwapTitle = styled.h2`
  width: 92%;
  margin: 20px auto;
  color: #8c8c8c;
  font-weight: 500;
  font-size: 28px;
`;
const Dropdown = styled.select`
  height: 40px;
  font-size: 20px;
  padding-left: 10px;
  border: none;
  border-radius: 8px;
  background-color: #f8f7f7;
  width: 100%;
`;

const Table = styled.main`
  margin: 40px auto;
  width: 90%;
`;
const RowHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 16px;
  margin: 5px 0;
 



`;
const Row = styled.div`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  margin: 0px 0;
  border-bottom: 1px solid#E0E0E0;
`;


const ID2 = styled.p`
  color: #333333;
  text-align: left;
  font-size: 14px;
  width: 16%;
  &.edit {
    text-align: right;
  }
`;

const IDHeader = styled(ID2)`
  color: #333333;
  font-weight: 700;
  text-align: left;
  font-size: 14px;
  width: 16%;
  &.edit {
    text-align: right;
  }
`;