import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import styled from "styled-components";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
  },
};

// const labels = ["January", "February", "March", "April", "May", "June", "July"];

export function Verticalbarchart({ datas, labels, title }: any) {
  

  const data = {
    labels,
    datasets: datas || [],
  };
  return (
    <Wrapper>
      <Titles>Revenue by {title}</Titles>
      <Bar width={200} height={200} options={options} data={data} />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  /* height: 900px; */
  background: #ffffff;
  width: 45%;
  height: 400px;
  border-radius: 5px;
  border: 1px solid var(--gray-6, #f2f2f2);
`;
const Titles = styled.h1`
  color: var(--e-ftd-orange, #fe7f1f);
  /* Small */
  font-family: Red Hat Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
