import axios from "axios";
import { useState } from "react";
import {  useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { NavHeader } from "../../components/NavHeader";
import Feedback from "../../components/Feedback";
import { Sidebar } from "../../components/Sidebar";
import { getDate, getTime } from "../../utils/utils";
import Spinner from "../../components/Spinner";
import Filter from "../../components/Filter";
import BasicModal from "../../components/Modal";
import { Pagination } from "@mui/material";



export const Batteries = () => {
    const queryClient = useQueryClient()
    const [searchFilter, setSearchFilter] = useState<any>()
    const batteries: any = queryClient.getQueryData('BATTERIES')
    const Baseurl = process.env.REACT_APP_BASEURL;
    const getToken: any = localStorage.getItem("eftd-admin-token");
    const tokenObject: any = JSON.parse(getToken);
    const token = tokenObject?.data?.token;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const [batteryNumber, setBatteryCompanyId] = useState<any>("");
    const [openModal, setOpenMOdal] = useState(false);
    const [open, setOpen] = useState(false);
    const [feedback, setFeedback] = useState("");
    const [search, setSearch] = useState('')

  const registerBattery = async (batteryNumber: any) => {
    const response = await axios.post(
      `${Baseurl}/api/admin/battery/registerbattery`,
      { batteryNumber },
      config
    );
    return response?.data;
  };

  const registerBatteryMutation = useMutation(
    (batteryNumber) => registerBattery(batteryNumber),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("BATTERIES");
        setFeedback("success");
        setOpenMOdal(true);
      },
      onError: () => {
        setFeedback("failed");
        setOpenMOdal(true);
      },
    }
  );

  const handleSubmit = async (e: any) => {
    try {
      registerBatteryMutation.mutateAsync(batteryNumber);
    } catch (err) {
      console.log(err);
    }
  };

  const getSearchedArray = batteries?.filter((item: any) =>
    item?.batteryNumber.toLowerCase().includes(search.toLowerCase())
  );

  const RegisterBike = () => {
    return (
      <Createbattery>
        <Title>Register a Battery </Title>
        <InputWrapper>
          <Labels>Bike Number</Labels>
          <Inputs
            name="battery"
            onChange={(e) => setBatteryCompanyId(e.target.value)}
            value={batteryNumber}
            type="text"
            placeholder="Battery Number"
          />
        </InputWrapper>
        <Button onClick={handleSubmit}>Save</Button>
      </Createbattery>
    );
  };

  return (
    <Body>
      <Sidebar />
      <Wrapper>
        <NavHeader title="Batteries" />
        <Filter handleSearchFilter={setSearchFilter}/>
        <div style={{width: '90%', margin: 'auto', display: 'flex', justifyContent: 'flex-end'}}>
        <CreateSubButton onClick={()=>{setOpen(true)}}>Register a Battery</CreateSubButton>
      </div>
       
        <Table>
          <RowHeader>
            <IDHeader>Date</IDHeader>
            <IDHeader>Battery Number</IDHeader>
            <IDHeader>State of Charge</IDHeader>
            <IDHeader>Status</IDHeader>
            <IDHeader>Swap Count</IDHeader>
            <IDHeader>Action</IDHeader>
          </RowHeader>
          {!batteries ? (
            <Spinner componentlevel />
          ) : (
            getSearchedArray?.map((item: any, index: string) => (
              <Row key={index}>
                <ID2>
                  {`${getDate(item?.createdAt)}`}{" "}
                  <span style={{ fontSize: "10px", color: "#828282" }}>
                    {" "}
                    {`${getTime(item?.createdAt)}`}
                  </span>
                </ID2>
                <ID2>XXZ229</ID2>
                <ID2>30%</ID2>
                <ID2>
                  <li style={{ color: "#F03738" }}>warning</li>
                </ID2>
                <ID2>19</ID2>

                <ID2>
                  <ViewDetails> View More</ViewDetails>
                </ID2>
              </Row>
            ))
          )}
          <div
            style={{
              // marginTop: "8%",
              // display: "flex",
              // width: "20%",
              width: "100%",
              marginTop: "8%  ",
              display: "flex",
              justifyContent: "flex-end",
              // justifySelf: "start",
            }}
          >
            <Pagination />
          </div>
        </Table>
        <BasicModal
          width={"500px"}
          content={<RegisterBike />}
          open={open}
          close={() => setOpen(false)}
        />
        <Feedback
          feedback={feedback}
          open={openModal}
          close={() => setOpenMOdal(false)}
        />
      </Wrapper>
    </Body>
  );
};
const Body = styled.main`
  display: flex;
  background-color: #f5f8fa;
`;

const Button = styled.button`
  background: #f66d00;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  margin: 10px auto;
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;

  /* identical to box height, or 34px */

  text-align: center;

  color: #ffffff;
  border: none;
`;
const Wrapper = styled.aside`
  width: 82vw;
  margin-left: 15vw;
  height: 100vh;
  overflow-y: scroll;
  background-color: #f5f8fa;
`;

const Labels = styled.label`
  width: 15%;
  color: #a09e9e;
`;

const InputWrapper = styled.div`
  width: 100%;
  margin: 20px auto;
  /* display: flex;
  align-items: center;
  justify-content: flex-start; */
  background-color: #ffffff;
  border-radius: 16px;
  padding: 5px 0px;
`;
const Inputs = styled.input`
  width: 100%;
  background-color: #ffffff;
  border: none;
  outline: none;
  padding: 10px 0;
  border-radius: 4px;
  padding-left: 2%;
  border: 1px solid #828282;
`;
const Createbattery = styled.form`
  width: 90%;
  margin: 15px auto;
  display: flex;
  flex-direction: column;
  border-radius: 1px;
`;
const Title = styled.h1`
  margin: 0px auto;
  width: 90%;

  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 63px;
  text-align: center;
  /* identical to box height */

  color: #2b2929;
`;
const CreateSubButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 18px;
  background: #f66d00;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  font-weight: 800;
`;

const ViewDetails = styled.p`
  color: #f66d00;
  cursor: pointer;
  font-weight: 500;
`;

const Table = styled.main`
  margin: 40px auto;
  width: 90%;
  padding: 1em 0.5em;
  background-color: #ffffff;
  border-radius: 0.5em;
`;

const RowHeader = styled.div`
  display: flex;
  /* background-color: #ffffff; */
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 16px;
  font-size: 14px;
  margin: 5px 0;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
  padding: 10px 20px;
  margin: 0px 0;
  border-bottom: 1px solid#E0E0E0;
  :nth-child(odd) {
    background-color: #e0e0e0;
  }
`;

const ID2 = styled.p`
  color: #333333;
  text-align: left;
  font-size: 14px;
  width: 16%;
  &.edit {
    text-align: right;
  }
`;

const IDHeader = styled(ID2)`
  color: #333333;
  font-weight: 700;
  text-align: left;
  font-size: 14px;
  width: 16%;
  &.edit {
    text-align: right;
  }
`;
