import { Pricing } from './Pricing';

export const Interstate = () => {
  return (
    <>
      <Pricing />
      <Pricing />
      <Pricing />
    </>
  );
}
