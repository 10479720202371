import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useRoles } from "../hooks/useRoles";
import {
  getDate,
  getTime,
  switchOrderStatus,
  switchstatus,
} from "../utils/utils";
import Feedback from "./Feedback";
import BasicModal from "./Modal";
import Spinner from "./Spinner";

type Props = {
  data: any[];
  pageLimit: any;
  dataLimit: any;
  orderIds?: any;
  pageDetails?: any;
  currentPageNo: any;
  pageNum: number;
};
const OrderDetails = ({
  data,
  pageLimit,
  dataLimit,
  orderIds,
  pageDetails,
  currentPageNo,
  pageNum
}: Props) => {
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [pages] = useState(Math.round(data?.length / dataLimit));
  const [currentPage, setCurrentPage] = useState(1);
  const [inputvalue, setInputValue] = useState(1)
  const [feedback, setFeedback] = useState({
    message: "success",
    open: false,
  });
  const [id, setId] = useState("");
  const [open, setOpen] = useState({
    open: false,
    loading:false,
  })
  const isSuperAdmin = useRoles()
  const Baseurl = process.env.REACT_APP_BASEURL;
  const getToken: any = localStorage.getItem("eftd-admin-token");
  const tokenObject: any = JSON.parse(getToken);
  const token = tokenObject?.data?.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const getUserRole: any = tokenObject?.data?.user?.role;
  const userAccess = () => {
    if (getUserRole === "manager") {
      return true;
    }
    return false;
  };
  function goToNextPage() {
    setCurrentPage((page: number) => page + 1);
    currentPageNo((page: number) => page + 1);
  }
  function gotolastPage() {
    setCurrentPage(pageDetails?.totalPages);
    currentPageNo(pageDetails?.totalPages);
  }

  function goToPreviousPage() {
    setCurrentPage((page: number) => page - 1);
    currentPageNo((page: number) => page - 1);
  }

  function changePage(event: any) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
    currentPageNo(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return data?.slice(startIndex, endIndex) || [];
  };

  const getPaginationGroup = () => {
    let total = Math.ceil(pageDetails?.totalPages) || 0;
    let pagination = new Array(total).fill(0).map((_, idx) => idx + 1);
    if (pagination.length > 8){
      pagination.length = 9
    }
    return pagination
  };

  // const handleItemSelect = (event: any) => {
  //   const itemId = event.target.value;
  //   if (event.target.checked) {
  //     setSelectedItems([...selectedItems, itemId]);
  //   } else {
  //     setSelectedItems(selectedItems.filter((id: any) => id !== itemId));
  //   }
  // };

const retryOrder = () => {
  setOpen({...open, loading: true})
  axios
  .post(`${Baseurl}/api/admin/orders/failed-dhl-orders/${id}`,{}, config)
  .then((res) => {
    setOpen({open: false, loading: false})
    setFeedback({ open: true, message: "success" });
  })
  .catch((err) => {
    setOpen({open: false, loading: false})
    setFeedback({ open: true, message: "failed" });
  });
}

const setStat = false

  return (
    <Wrapper>
      <TableHead>
        <p className="radiobutton">1</p>
        <p>Order ID</p>
        <p>Date</p>
        <p className="details">Pickup</p>
        <p className="details">Delivery</p>
        <p>Channel</p>
        <p className="action">Status</p>
        <p className="action">Action</p>
      </TableHead>
      {data?.map((item, idx) => (
        <Tablecontent key={idx}>
          <div className="radiobutton">
            <input
              type="checkbox"
              id={item.orderId}
              value={item.orderId}
              checked={selectedItems.includes(item.orderId)}
              onChange={(event) => {
                const itemId = event.target.value;
                if (setStat) {
                  if (event.target.checked) {
                    return setSelectedItems([...selectedItems, itemId]);
                  } else {
                    return setSelectedItems(
                      selectedItems.filter((id: any) => id !== itemId)
                    );
                  }
                } else {
                  if (
                    event.target.checked &&
                    ![
                      "verified",
                      "processing",
                      "cancelled",
                      "processing",
                      "delivered",
                      "in-transit",
                    ].includes(item.orderStatus)
                  ) {
                    setSelectedItems([...selectedItems, itemId]);
                  } else {
                    setSelectedItems(
                      selectedItems.filter((id: any) => id !== itemId)
                    );
                  }
                }
              }}
              onMouseDown={orderIds(selectedItems)}
            />
          </div>
          <Orderid>{item?.orderId}</Orderid>
          <Date>
            <div>
              <p>{getDate(item?.createdAt)}</p>
              <h1>{getTime(item?.createdAt)}</h1>
            </div>
          </Date>
          <Details className="details">
            <div>
              <h1>Name</h1>
              <p>
                {item?.pickUpDetails?.length > 0
                  ? item?.pickUpDetails[0]?.senderName
                  : "Not Available"}
              </p>
            </div>
            <div>
              <h1>Address</h1>
              <p>
                {item?.pickUpDetails?.length > 0
                  ? item?.pickUpDetails[0]?.fullAddress
                  : "Not Available"}
              </p>
              <p>
                {item?.pickUpDetails?.length > 0
                  ? item?.pickUpDetails[0]?.state
                  : "Not Available"}
              </p>
              <p>
                {item?.pickUpDetails?.length > 0
                  ? item?.pickUpDetails[0]?.phoneNumber
                  : "Not Available"}
              </p>
            </div>
          </Details>
          <Details className="details">
            <div>
              <h1>Name</h1>
              <p>
                {item?.deliveryDetails[0]?.recipientName || "Not Available"}
              </p>
            </div>
            <div>
              <h1>Address</h1>
              <p>
                {item?.deliveryDetails?.length > 0
                  ? item?.deliveryDetails[0]?.fullAddress
                  : "Not Available"}
              </p>
              <p>
                {item?.deliveryDetails?.length > 0
                  ? item?.deliveryDetails[0]?.state
                  : "Not Available"}
              </p>
              <p>
                {item?.deliveryDetails?.length > 0
                  ? item?.deliveryDetails[0]?.phoneNumber
                  : "Not Available"}
              </p>
            </div>
          </Details>
          <Channel>{item?.channel || ''}</Channel>
          <div className="status">
            <Status
              style={{
                backgroundColor: switchstatus(item.orderStatus),
                color: !["cancelled", "processing", "failed-after-payment"].includes(item.orderStatus)
                  ? "#ffffff"
                  : "#000000",
              }}
            >
              {switchOrderStatus(item?.orderStatus)}
            </Status>
            {item?.orderStatus === "failed-after-payment" && (
              <div
                onClick={() => {
                  setId(item?.orderId)
                  setOpen({...open, open: true})
                }}
                style={{ cursor: "pointer", display:'flex', alignItems: 'center', justifyContent:'center', gap:'10px' }}
              >
                <p>Retry</p>
                <img src="/retry.svg" alt="" />
              </div>
            )}
          </div>
          <Link to={`/order/${item?.orderId}`}>
            <ViewDetails>View more </ViewDetails>
          </Link>
        </Tablecontent>
      ))}
      <Paginationwrap className="pagination">
        <button className={`prev currentPage`}>{pageNum}</button>
        {/* previous button */}
        <button
          onClick={goToPreviousPage}
          className={`prev ${pageNum === 1 ? "disabled" : ""}`}
        >
          {"<"}
        </button>

        {/* show page numbers */}
        {getPaginationGroup().map((item, index) => (
          <button
            key={index}
            onClick={changePage}
            className={`paginationItem ${
              pageNum === item ? "active" : null
            }`}
          >
            <span>{item}</span>
          </button>
        ))}

        {/* next button */}
        <button
          onClick={goToNextPage}
          className={`next ${pageNum >= pageDetails?.totalPages ? "disabled" : ""}`}
        >
          {">"}
        </button>
        <input className="next pagination-input-field" type="number" value={inputvalue} onChange={(e:any)=> {setInputValue(e.target.value)}} />
         <button
          onClick={()=> {
            setCurrentPage(inputvalue)
            currentPageNo(inputvalue)
          }}
          className={`next`}
          disabled={!inputvalue || Number(inputvalue) > pageDetails?.totalPages}
        >
          {">>>"}
        </button>
        <button
          onClick={gotolastPage}
          className={`next disabled`}
        >
          {pageDetails?.totalPages}
        </button>
      </Paginationwrap>
      <BasicModal
        content={
          <ModalContent>
            <h1>Are you sure you want to retry this order?</h1>
            <div>
              <button className="yes" onClick={retryOrder}>
                {open.loading ? (
                  <Spinner componentlevel={false} />
                ) : (
                  "Yes"
                )}
              </button>
              <button onClick={() => setOpen({...open, open: false})}>No</button>
            </div>
          </ModalContent>
        }
        open={open.open}
        close={() => setOpen({...open, open: false})}
      />
      <Feedback
        feedback={feedback?.message}
        open={feedback.open}
        close={() => setFeedback({ ...feedback, open: false })}
      />
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background: #ffffff;
  border-radius: 10px;
  width: 95%;
  margin: auto;
  margin-bottom: 25px;
`;

const TableHead = styled.div`
  display: flex;
  padding: 10px 10px;

  > p {
    width: 11%;
    margin: 0;
    padding: 0;
  }
  .radiobutton {
    width: 5%;
  }
  .details {
    width: 20%;
  }
  .action {
    text-align: center;
  }
`;

const ModalContent = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  > div {
    display: flex;
    gap: 40px;
    > button {
      border: none;
      padding: 5px 40px;
      border-radius: 10px;
    }
    .yes {
      background-color: #f66d00;
      color: #ffffff;
    }
  }
`;

const Tablecontent = styled.div`
  display: flex;
  border-top: 1px solid #bdbdbd;
  padding: 30px 10px;
  > p {
    width: 11%;
  }
  > div {
    width: 11%;
    margin: 0;
    padding: 0;
    box-sizing: content-box;
  }
  .radiobutton {
    width: 5%;
    > input {
      cursor: pointer;
    }
  }
  .details {
    width: 20%;
    /* padding: 0px 10px; */
  }
  .status {
    div {
      font-size: 18px;
      line-height: 24px;

      text-align: center;

      color: #de3444;
      margin-top: 10px;
      font-weight: 900;
    }
  }
  > a {
    width: 11%;
    text-align: left;
  }
`;
const ViewDetails = styled.p`
  text-align: center;
  text-decoration-line: underline;
  color: #333333;
`;

const Status = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 30px;
  background: #fcceaa;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  text-transform: capitalize;
`;

const Channel = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  /* text-align: center; */

  color: #000000;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  > div {
    padding: 0px 20px 0px 0px;
    > h1 {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      /* text-align: left; */
      color: #828282;
    }
    > p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      /* text-align: left; */
      color: #333333;
    }
  }
`;
const Date = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    > h1 {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #828282;
    }
    > p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      /* text-align: left; */
      color: #333333;
    }
  }
`;

const Orderid = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: left;
  color: #828282;
`;
const Paginationwrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #4a4a68;

  > button {
    background: #f4f9ff;
    box-shadow: 0px 0px 7px rgba(34, 103, 206, 0.5);
    border-radius: 5px;
  }
  .paginationItem {
    border: none;
    background: #f4f9ff;
    padding: 10px;
    height: 20px;
    width: 20px;
    position: relative;
    margin: 30px 5px;
    cursor: pointer;
  }
  .paginationItem.active {
    border: 1px solid #333333;
  }
  .prev,
  .next {
    background: #f4f9ff;
    border: none;
    padding: 10px;
    height: 20px;
    width: 20px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    margin: 0 10px;
    cursor: pointer;
  }
`;
export default OrderDetails;
