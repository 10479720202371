import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { getDate } from "../utils/utils";
import axios from "axios";
import BasicModal from "./Modal";
import Feedback from "./Feedback";
import Spinner from "./Spinner";
import { useRoles } from "../hooks/useRoles";

const Riderzones = () => {
  const colors = [
    "black",
    "green",
    "red",
    "yellow",
    "white",
    "blue",
    "purple",
    "pink",
    "gray",
    "orange",
  ];
  const [id, setId] = useState("");
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<any>([])
  const [state, setState] = useState("delete");
  const [rider, setRider] = useState<any>("");
  const [openFeedback, setOpenFeedback] = useState(false);
  const [feedback, setFeedback] = useState("");
  const { pathname: location } = useLocation();
  const queryClient = useQueryClient();
  const Deliveryzones: any = queryClient.getQueryData(
    "DELIVERYCOORDINATESARRAY"
  );
  const PickupZones: any = queryClient.getQueryData("PICKUPCOORDINATESARRAY");
  const riders: any = queryClient.getQueryData("RidersObject");
  const getriders: any = queryClient.getQueryData("RIDERS");
  const Baseurl = process.env.REACT_APP_BASEURL;
  const getToken = localStorage.getItem("eftd-admin-token") as string;
  const tokenObject = JSON.parse(getToken);
  const token = tokenObject?.data?.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const isSuperAdmin = useRoles();
  const deletezone = async () => {
    const response = await axios.delete(
      `${Baseurl}/api/admin/zone/${id}`,
      config
    );
    return response?.data?.result;
  };
  const Editzone = async (name: any) => {
    const response = await axios.put(
      `${Baseurl}/api/admin/zone/${id}`,
      {rider: name},
      config,
    );
    return response?.data?.result;
  };

  const deleteZonesMutation = useMutation(() => deletezone(), {
    onSuccess: () => {
      queryClient.invalidateQueries("DELIVERYCOORDINATESARRAY");
      queryClient.invalidateQueries("PICKUPCOORDINATESARRAY");
      setFeedback("success");
      setOpenFeedback(true);
    },
    onError: () => {
      setFeedback("failed");
      setOpenFeedback(true);
    },
  });
  const EditZonesMutation = useMutation(name => Editzone(name), {
    onSuccess: () => {
      queryClient.invalidateQueries("DELIVERYCOORDINATESARRAY");
      queryClient.invalidateQueries("PICKUPCOORDINATESARRAY");
      setFeedback("success");
      setOpenFeedback(true);
      setOpen(false)
    },
    onError: () => {
      setFeedback("failed");
      setOpenFeedback(true);
    },
  });

  const deleteSingleZone = () => {
    try {
      deleteZonesMutation.mutateAsync();
    } catch (err) {
      console.log(err);
    }
  };

  const EditSingleZone = () => {
    try {
      EditZonesMutation.mutateAsync(rider);
    } catch (err) {
      console.log(err);
    }
  };

  const Content = () => {
    switch (state) {
      case "delete": {
        return (
          <ModalContent>
            <h1>Are you sure you want to delete this zone?</h1>
            <div>
              <button className="yes" onClick={deleteSingleZone}>
                {deleteZonesMutation.isLoading ? (
                  <Spinner componentlevel={false} />
                ) : (
                  "Yes"
                )}
              </button>
              <button onClick={() => setOpen(false)}>No</button>
            </div>
          </ModalContent>
        );
      }
      case "edit": {
        return (
          <ModalContent>
            <h1>Edit Zone</h1>
            <Dropdown name="rider" onChange={(e) => setRider(e.target.value)}>
              <option>Select rider</option>
              {getriders?.map((rider: any) => (
                <option key={rider._id} value={rider._id}>
                  {rider.fullName}
                </option>
              ))}
            </Dropdown>
            <div>
              <SubmitButton onClick={EditSingleZone}>
                {EditZonesMutation.isLoading ? (
                  <Spinner componentlevel={false} />
                ) : (
                  "Edit Rider"
                )}
              </SubmitButton>
            </div>
          </ModalContent>
        );
      }
    }
  };


  useEffect(()=> {
    if (location === "/deliveryzones") {
      setData(Deliveryzones)
    } else {
      setData(PickupZones)
    }
  },[Deliveryzones, PickupZones, location])
 

  return (
    <Wrapper>
      {data?.map((info: any, index: any) => (
        <Details key={index + info?.name}>
          <summary style={{ background: colors[index] }}>
            <span>{info?.name + " zone"}</span> <img src="/caret.png" alt="" />
          </summary>
          <div>
            {isSuperAdmin && (
              <div>
                <img
                  onClick={() => {
                    setState("edit");
                    setId(info?._id);
                    setOpen(true);
                  }}
                  width={15}
                  height={15}
                  src="/edit.png"
                  alt=""
                />
                <img
                  onClick={() => {
                    setState("delete");
                    setId(info?._id);
                    setOpen(true);
                  }}
                  width={15}
                  height={15}
                  src="delete.png"
                  alt=""
                />
              </div>
            )}
            <p>
              Date Created: <strong>{getDate(info?.createdAt)}</strong>
            </p>
            <p>
              Rider: <strong>{riders[info?.rider]}</strong>
            </p>
          </div>
        </Details>
      ))}
      <BasicModal
        content={Content()}
        open={open}
        close={() => setOpen(false)}
        width={'300px'}
      />
      <Feedback
        feedback={feedback}
        open={openFeedback}
        close={() => setOpenFeedback(false)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 170px;
  background-color: #ffffff;
  max-height: 450px;
  overflow: scroll;
  position: absolute;
  top: 300px;
  left: 10px;
  z-index: 4;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 10px 10px;
  border-radius: 8px;
`;

const ModalContent = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
  > div {
    display: flex;
    gap: 40px;
    > button {
      border: none;
      padding: 5px 40px;
      border-radius: 10px;
    }
    .yes {
      background-color: #f66d00;
      color: #ffffff;
    }
  }
`;

const Details = styled.details`
  display: flex;
  align-items: center;
  justify-content: center;
  > summary {
    background: #f2f2f2;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #545454;
    height: 40px;
    border-radius: 5px;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    cursor: pointer;
  }
  > div {
    padding: 10px;
    > p {
      font-size: 10px;
      margin-top: 5px;
    }
    > div {
      text-align: right;
      > img {
        margin-right: 5px;
        cursor: pointer;
      }
    }
    strong {
      font-size: 10px;
    }
  }
`;

const Dropdown = styled.select`
  height: 30px;
  font-size: 14px;
  border-radius: 5px;
  background-color: #f2f2f2;
  outline: none;
  border: none;
  width: 100%;
`;


const SubmitButton = styled.button`
 background: #F66D00;
  border-radius: 5px;
  width: 100%;
  height: 30px;
  margin: 10px auto;
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;

  /* identical to box height, or 34px */

  text-align: center;

  color: #ffffff;
  border:none;
`;

export default Riderzones;
