import React from "react";
import styled from "styled-components";
import { GetSubscription } from "../../components/GetSubscription";
import { NavHeader } from "../../components/NavHeader";
import { Sidebar } from "../../components/Sidebar";
import { useRoles } from "../../hooks/useRoles";

export const SubscriptionPage = () => {
  return (
    <Body>
      <Sidebar />
      <Wrapper>
      <NavHeader title={"Subscriptions"} />
        <GetSubscription/>
      </Wrapper>
    </Body>
  );
};
const Body = styled.main`
  display: flex;
`;
const Wrapper = styled.aside`
  width: 85vw;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  margin-left: 15vw;
  background-color: #fafafa;
`;
