import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(LocalizedFormat);

export const getDate = (date) => dayjs(date).format("D-MM-YYYY");

export const getFormattedDate = (date) =>
  dayjs(date).locale("en").format("dddd, D [of] MMM. YYYY");

export const getTime = (date) => dayjs(date).format("LT");

export const getRiderName = (arr) => {
  let result = {};
  for (let i = 0; i < arr?.length; i++) {
    result[arr[i]._id] = arr[i].fullName;
  }
  return result;
};

export function getDateRangeFromDuration(duration) {
  const endDate = dayjs().endOf("day");
  const startDate = dayjs().subtract(parseInt(duration), "day").startOf("day");

  return {
    startDate: startDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    endDate: endDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
  };
}

export const formatAmount = (value) => {
  const formattedNum = value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formattedNum;
};
export const switchstatus = (status) => {
  switch (status) {
    case "delivered":
      return "#22A74C";
    case "processing":
      return "#E7D788";
    case "in-transit":
      return "#545454";
    case "pickup":
      return "#DE3444";
    case "cancelled":
      return "#FCCEAA";
    case "failed-after-payment":
      return "#FFE8E8";
    default:
      return;
  }
};

export const switchOrderStatus = (type) => {
  switch (type) {
    case "pickup":
      return "Picked Up";
    case "processing":
      return "Processing";
    case "verified":
      return "Admin Verified";
    case "in-transit":
      return "In Transit";
    case "delivered":
      return "Delivered";
    case "cancelled":
      return "Cancelled";
    case "ordered":
      return "Payment Successful";
    case "failed-after-payment":
      return "Failed";
    default:
      return "";
  }
};

export const Capitalize = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const formatDescription = (text) => {
  const getstring = text[0].split("\n");
  return getstring;
};

export function splitArrays(data) {
  const ids = [];
  const counts = [];
  if (data) {
    for (const item of data) {
      ids.push(item._id || item.subscriptionName);
      counts.push(item.count || item.numberOfSubscriptions);
    }
  }

  return [ids, counts];
}


export function calculateSum(array) {
  let total_sum = 0;
  for (let num of array) {
    total_sum += num;
  }
  return total_sum;
}

function generateRandomColor() {
  const randomR = Math.floor(Math.random() * 256); // Random red component (0-255)
  const randomG = Math.floor(Math.random() * 256); // Random green component (0-255)
  const randomB = Math.floor(Math.random() * 256); // Random blue component (0-255)
  return `rgb(${randomR}, ${randomG}, ${randomB})`;
}

export function convertedData(inputData) {
  if (inputData) {
    const convertedData = inputData.deliveryAnalysis.reduce((result, item) => {
      item.deliveryTypes.forEach((deliveryType) => {
        const existingType = result.find(
          (entry) => entry.label === deliveryType.deliveryType
        );
        if (existingType) {
          existingType.data.push(deliveryType.count);
        } else {
          result.push({
            label: deliveryType.deliveryType,
            data: [deliveryType.count],
            borderColor: generateRandomColor(),
            backgroundColor: generateRandomColor(),
          });
        }
      });
      return result;
    }, []);
    return convertedData;
  }
  return [];
}

export const getDataForSubscriberRevenue = (input) => {
  if (input) {
    const days = [...new Set(input.map((item) => item.day))];
    const subscriptionDataset = input.reduce((result, item) => {
      item.data.forEach((dataItem) => {
        const { subscribed, count } = dataItem;
        const existingEntry = result.find((entry) => entry.label === (subscribed ? 'subscriber' : 'non-subscriber'));
    
        if (existingEntry) {
          existingEntry.data.push(count);
        } else {
          result.push({
            label: subscribed ? 'subscriber' : 'non-subscriber',
            data: [count],
            backgroundColor: '#333',
          });
        }
      });
    
      return result;
    }, []);
    return [subscriptionDataset , days];
  }
  else {
    return [[],[]]
  }
};


export const getDataForRevenueAnalysis = (input) => {
  
  if (input) {
    const uniqueDays = [...new Set(input.map((item) => item.day))];
    const transformedDataset = input.reduce((result, item) => {
      item.deliveryTypes.forEach((deliveryType, index) => {
        const existingEntry = result.find(
          (entry) => entry.label === deliveryType.deliveryType
        );
        const count = deliveryType.totalAmount;

        if (existingEntry) {
          // Update existing entry
          existingEntry.data.push(count);
        } else {
          // Create a new entry
          result.push({
            label: deliveryType.deliveryType,
            data: [count],
            backgroundColor: generateRandomColor(),
          });
        }
      });

      return result;
    }, []);
    return [transformedDataset , uniqueDays];
  } else {
    return [[],[]]
  }
}

export const newArray = (input) => {
  if(input){
    const response = input.map(rider => ({
      value: rider._id,
      label: rider.fullName
    }));
  response.unshift({value: '', label: 'Select'})
  return response
  }
  return []
}