import styled from "styled-components";


const Spinner = ({componentlevel}: any) => (
  <Wrapper>
    <div className={componentlevel ? 'loader' : 'buttonloader'}></div>
  </Wrapper>
);

export default Spinner;

const Wrapper = styled.div`
width: 100%;
display: grid;
place-items: center;
`