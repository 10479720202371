import styled from "styled-components";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import { NavHeader } from "../../components/NavHeader";
import OrderDetails from "../../components/OrderDetails";
import Spinner from "../../components/Spinner";
import Feedback from "../../components/Feedback";
import colours from "../../styles/appColours";
import { BsPerson } from "react-icons/bs";
import { Capitalize, getDate } from "../../utils/utils";

export const SingleCustomer = () => {
  const { id } = useParams();
  const [order, setOrder] = useState<any>([]);
  const [totalOrders, setTotalOrders] = useState<any>({})
  const [orderIds, setOrderIds] = useState([]);
  const [customer, setCustomer] = useState<any>({});
  const [updateStatus, setUpdateStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState({
    update: false,
    search: false,
    data: false,
  });
  const [feedback, setFeedback] = useState("");
  const [open, setOpen] = useState(false);
  const Baseurl = process.env.REACT_APP_BASEURL;
  const getToken: any = localStorage.getItem("eftd-admin-token");
  const tokenObject: any = JSON.parse(getToken);
  const getUserRole: any = tokenObject?.data?.user?.role;
  const token = tokenObject?.data?.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  useEffect(() => {
    setLoading({ ...loading, data: true });
    axios
      .get(`${Baseurl}/api/admin/orders?limit=10&page=${currentPage}&userId=${id}`, config)
      .then((res) => {
        setLoading({ ...loading, data: false });
        setOrder(res?.data?.data?.documents);
        setTotalOrders(res?.data?.data?.paging);
      })
      .catch((err) => {
        setLoading({ ...loading, data: false });
      });
  }, [id, currentPage]);

  useEffect(() => {
    axios
      .get(`${Baseurl}/api/admin/customers/getcustomers/${id}`, config)
      .then((res) => {
        setCustomer(res?.data?.data);
      })
      .catch((err) => {});
  }, [id]);

  const userAccess = (userPermission: string) => {
    let status;
    if (userPermission === "manager") {
      status = [
        {
          value: "cancelled",
          name: "Cancelled",
        },
        {
          value: "delivered",
          name: "Delivered",
        },
        {
          value: "in-transit",
          name: "In Transit",
        },
        {
          value: "pickup",
          name: "Picked Up",
        },
        {
          value: "processing",
          name: "Processing",
        },
      ];
    } else {
      status = [
        {
          value: "cancelled",
          name: "Cancelled",
        },
        {
          value: "in-transit",
          name: "In Transit",
        },
      ];
    }
    return status;
  };

  const updateOrdersFunction = async () => {
    if (orderIds.length < 1) {
      throw new Error("Error");
    } else {
      const data: any = { orderStatus: updateStatus, orderIds };
      const response = await axios.patch(
        `${Baseurl}/api/admin/orders/multiple-orders`,
        data,
        config
      );
      return response;
    }
  };

  const updateOrders = async () => {
    try {
      setLoading({ ...loading, update: true });
      await updateOrdersFunction();
      setFeedback("success");
      setOpen(true);
      setLoading({ ...loading, update: false });
    } catch (err) {
      setLoading({ ...loading, update: false });
      setFeedback("failed");
      setOpen(true);
    }
  };

  return (
    <Body>
      <Sidebar />
      <Wrapper>
        <NavHeader
          title={
            `${Capitalize(customer?.firstName || '')} ${Capitalize(customer?.lastName || '')}` ||
            `${Capitalize(customer?.businessName || '')}`
          }
        />
        <UserProfile>
          <UserImage>
            <BsPerson style={{ fontSize: "50px" }} />
          </UserImage>
          <UserName>{`${Capitalize(customer?.firstName || '')} ${Capitalize(customer?.lastName || '')}` ||
            `${Capitalize(customer?.businessName || '')}`}</UserName>
          <Active
            style={{
              color: customer?.isVerified ? " #219653" : " #EB5757",
              background: customer?.isVerified ? "#C5F8C4" : "#FFE8E8",
            }}
          >
            {customer?.isVerified ? "Active User" : "Inactive User"}
          </Active>
          {customer?.subscription?.name && (
            <UserRole>{`${customer?.subscription?.name}: ${customer?.subscription?.noOfOrders} orders left`}</UserRole>
          )}

          <UserDatas>
            <Title>Personal Details</Title>
            <label htmlFor="">Full Name</label>
            <UserInfo
              disabled={true}
              value={`${Capitalize(customer?.firstName || '')} ${Capitalize(customer?.lastName || '')}`}
            />
            <label htmlFor="">Business Name(Optional)</label>
            <UserInfo
              disabled={true}
              style={{ background: "#F2F2F2" }}
              value={`${Capitalize(customer?.businessName || '')}`}
            />
            <label htmlFor="">Email Address</label>
            <UserInfo
              disabled={true}
              style={{ background: "#F2F2F2" }}
              value={customer?.email}
            />
            <label htmlFor="">Phone Number</label>
            <UserInfo disabled={true} value={customer?.phoneNumber} />
          </UserDatas>
          <UserDatas>
            <Title>Customer Activity</Title>
            <UserRole>Subscription Plan: {customer?.subscription?.name}</UserRole>
            <UserRole>Subscription Expiry Date: { customer?.subscription?.name ? getDate(customer?.subscription?.subscriptionEnd): null}</UserRole>
            <UserRole>Total orders: {totalOrders?.total_items || 0} </UserRole>
          </UserDatas>
        </UserProfile>
        <Updateorder>
          <div>
            <label htmlFor="">Status</label>
            <select onChange={(e) => setUpdateStatus(e.target.value)}>
              {userAccess(getUserRole).map((status) => (
                <option value={status.value}>{status.name} </option>
              ))}
            </select>
          </div>
          <button onClick={updateOrders}>
            {loading.update ? <Spinner /> : "Update order"}
          </button>
        </Updateorder>
        <HistoryList>
          {loading.data ? (
            <Spinner componentlevel={true} />
          ) : (
            <OrderDetails
              data={order}
              pageLimit={undefined}
              dataLimit={5}
              orderIds={setOrderIds}
              pageDetails={order?.paging}
              currentPageNo={setCurrentPage}
              pageNum={currentPage}
            />
          )}
        </HistoryList>
      </Wrapper>
      <Feedback feedback={feedback} open={open} close={() => setOpen(false)} />
    </Body>
  );
};

const Body = styled.main`
  display: flex;
`;
const Wrapper = styled.main`
  width: 85vw;
  height: 100vh;
  overflow-y: scroll;
  margin-left: 15vw;
  background-color: #fafafa;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
`;

const Updateorder = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 20px;
  gap: 10px;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;

    > select {
      background: #ffffff;
      border: 1px solid #333333;
      border-radius: 10px;
      padding: 10px 0px;
      width: 280px;
      cursor: pointer;
    }
    > label {
      padding-left: 20px;
    }
  }
  > button {
    background: #333333;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 40px;
    border: none;
    color: #ffffff;
    cursor: pointer;
  }
`;

const HistoryList = styled.section`
  background-color: #fafafa;
  width: 100%;
  @media (max-width: 425px) {
    width: 100%;
    background-color: #ffffff;
  }
`;
const UserProfile = styled.main`
  background-color: #ffffff;
  width: 60%;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 16px;
  padding: 20px 0px 60px 0px;

  @media (max-width: 600px) {
    width: 95%;
  }
`;
const UserImage = styled.div`
  height: 88px;
  width: 88px;
  background-color: #f2efef;
  border-radius: 50%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const UserName = styled.h2`
  color: #333333;
  font-size: 16px;
`;

const Active = styled.h2`
  font-size: 16px;
  padding: 5px 20px;
  border-radius: 5px;
`;

const UserRole = styled.p`
  display: flex;
  align-items: center;
  gap: 3px;
`;
const UserDatas = styled.div`
  display: flex;
  padding: 30px;
  flex-direction: column;
  width: 80%;
  border: 0.5px solid #13131a;
  text-align: left;
  border-radius: 5px;
  margin-top: 20px;

  label {
    color: #333333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-top: 10px;
  }

  @media (max-width: 600px) {
    width: 95%;
  }
`;
const UserInfo = styled.input`
  padding: 5px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  border-radius: 5px;
  border: 0.5px solid #13131a;
  padding: 10px;
`;

const Copyable = styled.div`
  padding: 5px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #333333;
  border-radius: 5px;
  border: 0.5px solid #13131a;
  background: #f2f2f2;
  padding: 10px;
`;
const Title = styled.h2`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: left;

  /* New Orange */

  color: #f66d00;
`;

const Text = styled.p`
  text-align: left;
  /* width: 80%; */
  margin: 0 auto;
  font-weight: 300;
  color: #4d4949;
`;
const NewText = styled(Text)`
  background-color: #ffffff;
  height: 50px;
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
`;

const Change = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;
`;

const Input = styled.input`
  display: block;
  height: 53px;
  width: 500px;
  border-radius: 8px;
  background-color: #fafafa;
  outline: none;
  border: none;
  padding-left: 20px;
  @media (max-width: 1024px) {
    width: 30vw;
  }
  @media (max-width: 600px) {
    width: 90vw;
    /* margin-bottom: 10px; */
  }
`;
const InputSection = styled.div`
  padding-top: 20px;
  /* height: 50%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
`;

const Span = styled.span`
  font-weight: 400;
  width: 200px;
  display: inline-block;
`;
