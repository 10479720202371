import React from 'react'
import styled from 'styled-components'
import { Link } from "react-router-dom";
interface Cardwithicon {
  title: string;
  amount: string;
  icon?: any;
  backgroundColor?: string;
  textcolor?:string;
}

export const Cardwithicon = ({
  title,
  amount,
  icon,
  backgroundColor,
  textcolor="#333333"
}: Cardwithicon) => {
  return (
    <Wrapper>
      <div style={{background: backgroundColor}} className='image-wrap'>
        <img src={icon} alt="" />
      </div>
      <div>
      <Amount style={{color: textcolor}}>{amount}</Amount>
      <Title>{title}</Title>
      </div>
     
      
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 29%;
  border-radius: 16px;
  padding: 20px;
  background-color: #ffffff;
  color: #F3FFF7;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;

  .image-wrap {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
  justify-content: center;
  align-items: center;
  }
`;

const Title = styled.h1`
    text-align: left;
    font-weight: 400;
    color:#333;
    margin: 0px 0px 10px 0px;
`;

const Amount = styled.p`
  text-align: left;
  font-size: 28px;
  font-weight: 500;
  color: #000000;
`;
