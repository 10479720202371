import {useEffect} from 'react';
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from './components/ProtectedRoute';
import Dashboard from "./pages/dashboard/Dashboard"
import { BikesPage } from './pages/dashboard/BikesPage';
import { Orderhistory } from './pages/dashboard/Orderhistory';
import { SwapStation } from './pages/dashboard/SwapStation';
import { Riderspage } from './pages/dashboard/Riderspage';
import { SubscriptionPage } from './pages/dashboard/SubscriptionPage';
import { CustomersList } from './pages/dashboard/CustomersList';
import { Batteries } from './pages/dashboard/Batteries';
import { BatterSwap } from './pages/dashboard/BatterSwap';
import { Activity } from './pages/dashboard/Activity';
import { TransactionHistory } from './pages/dashboard/TransactionHistory';
import { PendingVerification } from './pages/dashboard/PendingVerification';
import { PendingRiderVerification } from './pages/dashboard/PendingRiderVerification';
import { SingleRider } from './pages/dashboard/SingleRider';
import { Regions } from './pages/dashboard/Regions';
import { SingleOrder } from './pages/dashboard/SingleOrder';
import { Admin } from './pages/dashboard/Admin';
import Login from './pages/auth/Login';
import "./App.css";
import {Geofence} from './pages/dashboard/Geofence';
import { SingleCustomer } from './pages/dashboard/SingleCustomer';
require("typeface-poppins");
require("typeface-inter");
require("typeface-red-hat-display");


function App() {
 useEffect(() => {
   window.scrollTo({ behavior: "smooth", top: 0 });
 }, []);

  return (
    <div className="App">
      {/* <Sidebar/> */}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/customers"
          element={
            <ProtectedRoute>
              <CustomersList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/customers/:id"
          element={
            <ProtectedRoute>
              <SingleCustomer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/batteries"
          element={
            <ProtectedRoute>
              <Batteries />
            </ProtectedRoute>
          }
        />
        <Route
          path="/batteryswap"
          element={
            <ProtectedRoute>
              <BatterSwap />
            </ProtectedRoute>
          }
        />
        <Route
          path="/riders"
          element={
            <ProtectedRoute>
              <Riderspage />
            </ProtectedRoute>
          }
        />
         <Route
          path="/deliveryzones"
          element={
            <ProtectedRoute>
              <Geofence />
            </ProtectedRoute>
          }
        />
        <Route
          path="/riders/:riderId"
          element={
            <ProtectedRoute>
              <SingleRider />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pendingriders"
          element={
            <ProtectedRoute>
              <PendingVerification />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pendingriders/:pendingriderId"
          element={
            <ProtectedRoute>
              <PendingRiderVerification />
            </ProtectedRoute>
          }
        />
        <Route
          path="/activity"
          element={
            <ProtectedRoute>
              <Activity />
            </ProtectedRoute>
          }
        />
        <Route
          path="/transactions"
          element={
            <ProtectedRoute>
              <TransactionHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/subscriptions"
          element={
            <ProtectedRoute>
              <SubscriptionPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vehicles"
          element={
            <ProtectedRoute>
              <BikesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/orderhistory"
          element={
            <ProtectedRoute>
              <Orderhistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/order/:orderID"
          element={
            <ProtectedRoute>
              <SingleOrder />
            </ProtectedRoute>
          }
        />
        <Route
          path="/swapstations"
          element={
            <ProtectedRoute>
              <SwapStation />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pendingrider"
          element={
            <ProtectedRoute>
              <PendingRiderVerification />
            </ProtectedRoute>
          }
        />
        <Route
          path="/regions"
          element={
            <ProtectedRoute>
              <Regions />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <Admin />
            </ProtectedRoute>
          }
        />
         <Route
          path="/pickupzones"
          element={
            <ProtectedRoute>
              <Geofence />
            </ProtectedRoute>
          }
        />
           <Route
          path="/testzone"
          element={
            <ProtectedRoute>
              <Geofence />
            </ProtectedRoute>
          }
        />
        <Route
          path="*"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
