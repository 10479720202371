import {  useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { NavHeader } from "../../components/NavHeader";
import { Sidebar } from "../../components/Sidebar";
import Spinner from "../../components/Spinner";
import colours from "../../styles/appColours";
import { Capitalize, getDate } from "../../utils/utils";
import Filter from "../../components/Filter";

export const CustomersList = () => {
  const Baseurl = process.env.REACT_APP_BASEURL;
  const getToken: any = localStorage.getItem("eftd-admin-token");
  const tokenObject: any = JSON.parse(getToken);
  const token = tokenObject?.data?.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [searchFilter, setSearchFilter] = useState<any>()
  const [page, setPage] = useState(1)
  const getCustomers = async () => {
    const response = await axios.get(
      `${Baseurl}/api/admin/customers/getcustomers?limit=20&page=${page}`,
      {...config, params: {...searchFilter}}
    );
    return response?.data?.data
  };

  const { data: allCustomers, isLoading } = useQuery(["CUSTOMERS",  searchFilter, page], getCustomers);

  function goToNextPage() {
    setPage((page: number) => page + 1);
  }
  function gotolastPage() {
    setPage(allCustomers?.paging?.totalPages);
  }

  function goToPreviousPage() {
    setPage((page: number) => page - 1);
  }

  function changePage(event: any) {
    const pageNumber = Number(event.target.textContent);
    setPage(pageNumber);
  }

  const getPaginationGroup = () => {
    let total = Math.ceil(allCustomers?.paging?.totalPages) || 0;
    let pagination = new Array(total).fill(0).map((_, idx) => idx + 1);
    if (pagination.length > 8) {
      pagination.length = 9;
    }
    return pagination;
  };


  return (
    <Body>
      <Sidebar />
      <Wrapper>
        <NavHeader title="Customers" />
        <Filter handleSearchFilter={setSearchFilter} showAccountTypeFilter={true} showStatusFilter={false}/>
        <Table>
          <RowHeader>
            <IDHeader>Date Joined</IDHeader>
            <IDHeader>Name</IDHeader>
            <IDHeader>Email Address</IDHeader>
            <IDHeader>Phone Number</IDHeader>
            <IDHeader>Account type</IDHeader>
            <IDHeader>Subscription</IDHeader>
            <IDHeader>Verified</IDHeader>

            <IDHeader>Action</IDHeader>
          </RowHeader>
          {isLoading ? (
            <Spinner componentlevel />
          ) : (
            allCustomers?.documents.map((item: any, index: any) => (
              <Row key={index}>
                <ID>{getDate(item?.createdAt)}</ID>
                <ID>
                  {item?.businessName || `${item.firstName} ${item.lastName}`}
                </ID>
                <ID>{item?.email}</ID>
                <ID>{item?.phoneNumber}</ID>
                <ID>{Capitalize(item.accountType || "")}</ID>
                <ID>{item?.subscription?.name || "None"}</ID>
                <ID>{item?.isVerified ? "True" : "False"}</ID>
                <View>
                  <Link
                    style={{ color: `${colours.orange}` }}
                    to={`/customers/${item._id}`}
                  >
                    View more
                  </Link>
                </View>
              </Row>
            ))
          )}
          <Paginationwrap className="pagination">
            <button className={`prev currentPage`}>{page}</button>
            {/* previous button */}
            <button
              onClick={goToPreviousPage}
              className={`prev ${page === 1 ? "disabled" : ""}`}
            >
              {"<"}
            </button>

            {/* show page numbers */}
            {getPaginationGroup().map((item, index) => (
              <button
                key={index}
                onClick={changePage}
                className={`paginationItem ${page === item ? "active" : null}`}
              >
                <span>{item}</span>
              </button>
            ))}

        {/* next button */}
        <button
          onClick={goToNextPage}
          className={`next ${page >= allCustomers?.paging?.totalPages ? "disabled" : ""}`}
        >
          {">"}
        </button>
        <button
          onClick={gotolastPage}
          className={`next disabled`}
        >
          {allCustomers?.paging?.totalPages}
        </button>
      </Paginationwrap>
        </Table>
      </Wrapper>
    </Body>
  );
};
const Body = styled.main`
  display: flex;
`;
const Wrapper = styled.aside`
  width: 85vw;
  height: 200vh;
  overflow-y: scroll;
  margin-left: 15vw;
  background-color: #fafafa;
`;

const Paginationwrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #4a4a68;

  > button {
    background: #f4f9ff;
    box-shadow: 0px 0px 7px rgba(34, 103, 206, 0.5);
    border-radius: 5px;
  }
  .paginationItem {
    border: none;
    background: #f4f9ff;
    padding: 10px;
    height: 20px;
    width: 20px;
    position: relative;
    margin: 30px 5px;
    cursor: pointer;
  }
  .paginationItem.active {
    border: 1px solid #333333;
  }
  .prev,
  .next {
    background: #f4f9ff;
    border: none;
    padding: 10px;
    height: 20px;
    width: 20px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    margin: 0 10px;
    cursor: pointer;
  }
`;

const Table = styled.main`
  margin: 20px auto;
  width: 90%;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 12px;
`;
const Row = styled.div`
  background: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  gap: 0.25%;
  margin: 10px 0;
`;

const RowHeader = styled.div`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 16px;
  margin: 10px 0;
`;

const ID = styled.p`
  font-size: 16px;
  color: #333333;
  width: 13.5%;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  max-width: 13%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const View = styled.p`
  font-size: 16px;
  color: ${colours.orange};
  width: 13.5%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  max-width: 13%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const IDHeader = styled(ID)`
  color: #333333;
  font-weight: 700;
`;
