import { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import BasicModal from "./Modal";
import { useRoles } from "../hooks/useRoles";

import { formatDescription } from "../utils/utils";
import Feedback from "./Feedback";
import { CreateSubscription } from "./CreateSubscription";

export const GetSubscription = () => {
  const Baseurl = process.env.REACT_APP_BASEURL;
  const getToken: any = localStorage.getItem("eftd-admin-token");
  const tokenObject: any = JSON.parse(getToken);
  const token = tokenObject?.data?.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const isSuperAdmin = useRoles();
  const [subscription, getSubscription] = useState([]);
  const [modalState, setModalState] = useState("");
  const [feedback, setFeedback] = useState("");
  const [subid, setsubId] = useState("");
  const [state, setState] = useState("default");
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    axios
      .get(`${Baseurl}/api/admin/subscription/getplans`, config)
      .then((res: any) => {
        getSubscription(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteSub = (id: string) => {
    axios
      .delete(`${Baseurl}/api/admin/subscription/deleteplan/${id}`, config)
      .then((res) => {
        setState("success");
        window.location.reload();
      })
      .catch((err) => {
        setState("failed");
      });
  };

  const ModalContent = (response: any) => {
    switch (response) {
      case "edit": {
        return <CreateSubscription title="Edit"  id={subid}/>;
      }
      case "create": {
        return <CreateSubscription title="Create" />;
      }
      default:
        return (
          <div>
            <DeletePlan>Are you sure you want to delete this plan?</DeletePlan>
            <Wrap>
              <Yes onClick={() => deleteSub(subid)}>Yes</Yes>
              <Cancel onClick={() => setOpenModal(false)}>Cancel</Cancel>
            </Wrap>
          </div>
        );
    }
  };
  return (
    <Wrapper>
      {isSuperAdmin && (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <CreateSubButton
            onClick={() => {
              setModalState("create");
              setOpenModal(true);
            }}
          >
            Create Subscription
          </CreateSubButton>
        </div>
      )}
      <CardsWrapper>
        {subscription.map((subs: any, index: number | null | undefined) => (
          <Cards key={index}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <SubType>{subs?.name}</SubType>
              <SubAmount>
                {`N${Number(subs?.amount / 100).toLocaleString()}`}
              </SubAmount>
            </div>
            <SubInfo>
              <SubListItem>
                N{(subs?.baseFee / 100).toLocaleString()} per delivery within
                Lagos.
              </SubListItem>
              <SubListItem>
                {subs?.allowedDeliveries} pick ups and deliveries
              </SubListItem>
              <SubListItem>{subs?.description || "N/A"}</SubListItem>
              {subs?.benefits?.length > 0 &&
                formatDescription(subs?.benefits).map((benefit: string) => (
                  <SubListItem key={benefit}>{benefit}</SubListItem>
                ))}
              <SubListItem>{subs?.validity} day validity period</SubListItem>
            </SubInfo>
            {isSuperAdmin && (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <SubButton
                  style={{ color: "red" }}
                  onClick={() => {
                    setsubId(subs?._id);
                    setOpenModal(true);
                    setModalState("default");
                  }}
                >
                  Delete Subscription
                </SubButton>
                <SubButton
                  onClick={() => {
                    setsubId(subs?._id);
                    setOpenModal(true);
                    setModalState("edit");
                  }}
                >
                  Edit Subscription
                </SubButton>
              </div>
            )}

            <BasicModal
              open={openModal}
              close={() => setOpenModal(false)}
              height={"600px"}
              width={"600px"}
              content={ModalContent(modalState)}
            />
          </Cards>
        ))}
      </CardsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.main`
  min-height: 100vh;
  height: 100%;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* box-sizing: border-box; */
  /* border: 1px solid red; */
  width: 90%;
  margin: auto;
  @media (max-width: 425px) {
    margin-bottom: 40px;
  }
  @media (max-width: 375px) {
  }
`;

const CreateSubButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 18px;
  background: #f66d00;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  font-weight: 800;
`;

const Active = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 14px 16px; */
  gap: 10px;
  height: 30px;
  width: 90px;
  background: #e7fff1;
  border-radius: 5px;
  color: #6fcf97;
`;

const CardsWrapper = styled.section`
  background-color: #fafafa;
  /* height: 85vh; */
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 5%;
  column-gap: 5%;
  border-radius: 32px;
  margin: 0px;
  margin-top: 100px;
  padding: 0px;
  /* border: 1px solid green; */
  box-sizing: content-box;
  @media (max-width: 425px) {
    width: 90vw;
    gap: 20px;
  }
  @media (max-width: 375px) {
  }
`;
const ModalWrap = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 10px 0;
`;
const Cards = styled.section`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 40%;
  min-width: 350px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0px 38.5522px 66.0896px #f5f6f6;
  border: none;
  @media (max-width: 425px) {
    width: 90%;
    :last-child {
      margin-bottom: 40px;
    }
    :first-child {
      margin-top: 20px;
    }
  }
  @media (max-width: 375px) {
  }
`;

const SubType = styled.p`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;

  /* Gray 1 */

  color: #333333;
`;

const SubAmount = styled.p`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;

  /* Gray 1 */

  color: #333333;
`;

const SubInfo = styled.ul`
  color: #8c8888;
  font-size: 15px;
  font-style: normal;
  margin: 0px 0px 15px 0px;
  padding: 0px;
  line-height: 35px;
  text-align: left;
  list-style: none;
  margin-top: 20px;
`;

const SubListItem = styled.li`
  margin: 0;
`;

const SubButton = styled.p`
  cursor: pointer;
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #f66d00;
  text-align: left;
`;

const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border: 7px solid red;
  border-radius: 50%;
`;

const Text = styled.p`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 48px;
  text-align: center;

  color: #4d4949;
`;

const Button = styled.button`
  width: 220px;
  height: 56px;
  left: 75px;
  top: 366.89px;
  background: #ec2a2a;
  border-radius: 12px;
  outline: none;
  border: none;
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  /* identical to box height */
  text-align: center;
  color: #ffffff;
`;

const Yes = styled.span`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 600;
  font-size: 32.0385px;
  line-height: 48px;
  /* identical to box height */

  text-align: center;
  display: block;
  color: #fe7f20;
  cursor: pointer;
`;

const Cancel = styled.span`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 600;
  font-size: 32.0385px;
  line-height: 48px;
  /* identical to box height */
  cursor: pointer;
  text-align: center;
  display: block;
  color: #ec2a2a;
`;

const DeletePlan = styled.p`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 48px;
  text-align: center;

  color: #4d4949;
`;

const Wrap = styled.p`
  display: flex;
  justify-content: space-evenly;
`;
