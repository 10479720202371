import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import BasicModal from "../../components/Modal";
import { NavHeader } from "../../components/NavHeader";
import { Sidebar } from "../../components/Sidebar";
import Feedback from "../../components/Feedback";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getDate } from "../../utils/utils";
import { useRoles } from "../../hooks/useRoles";
import Filter from "../../components/Filter";

export const Admin = () => {
  const queryClient = useQueryClient();
  const Baseurl = process.env.REACT_APP_BASEURL;
  const [searchFilter, setSearchFilter] = useState<any>()
  const getToken: any = localStorage.getItem("eftd-admin-token");
  const tokenObject: any = JSON.parse(getToken);
  const token = tokenObject?.data?.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [state, setState] = useState("default");
  const [adminId, setAdminId]= useState('')
  const [openModal, setOpenModal] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [data, setData] = useState({
    name: "",
    role: "",
    email: "",
    phoneNumber: "",
    password: "",
  });
  const isSuperAdmin = useRoles();

  const getUser = async () => {
    const response = await axios.get(
      `${Baseurl}/api/admin/auth/getadmin/`,
      {...config, params: {...searchFilter}}
    );
    return response?.data?.result;
  };

  const deleteUser = async (id:string) => {
    const response = await axios.delete(
      `${Baseurl}/api/admin/auth/${id}/`,
      config
    );
    return response?.data;
  };

  const deleteAdmin = useMutation(deleteUser, {
    onSettled: ()=> {
      setState('success')
      queryClient.invalidateQueries('ALLADMIN')
    }
  })

  const handleDelete = () => {
    deleteAdmin.mutate(adminId);
  };
  const { data: admins } = useQuery(["ALLADMIN", searchFilter], getUser);

  const ModalContent = (response: any) => {
    switch (response) {
      case "success":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Image>
              <img src="/trash.png" alt="" width={"75%"} height={"75%"} />
            </Image>

            <Text>Admin Deleted successfully</Text>
          </div>
        );
      case "failed":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            There was an error
          </div>
        );
      case "create":
        return (
          <>
          <Title>Add New Admin</Title>
          <Createsub>
              <InputWrapper>
                <Labels>Name </Labels>
                <Inputs onChange={handleChange} name="name" />
              </InputWrapper>
              <InputWrapper>
                <Labels>Password </Labels>
                <Inputs onChange={handleChange} name="password" />
              </InputWrapper>
              <InputWrapper>
                <Labels>Email </Labels>
                <Inputs onChange={handleChange} name="email" type="email" />
              </InputWrapper>
              <InputWrapper>
                <Labels>Phone</Labels>
                <Inputs onChange={handleChange} name="phoneNumber" type="tel" />
              </InputWrapper>
            <InputWrapper>
            <Labels>Role</Labels>
                <Inputs onChange={handleChange} name="role" type="text" />
            </InputWrapper>
            <SubmitButton onClick={handleSubmit}>Save</SubmitButton>
          </Createsub>
          </>
        );
      default:
        return (
          <div>
            <DeletePlan>Are you sure you want to delete this Admin?</DeletePlan>
            <Wrap>
              <Yes onClick={() => handleDelete()}>Yes</Yes>
              <Cancel onClick={() => setState("failed")}>Cancel</Cancel>
            </Wrap>
          </div>
        );
    }
  };
  const handleChange = (e: any) => {
    setData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    axios
      .post(`${Baseurl}/api/admin/auth/register`, data, config)
      .then((res) => {
        setFeedback("success");
        setOpenFeedback(true);
        setOpenModal(false);
      })
      .catch((err) => {
        setFeedback("error");
        setOpenFeedback(true);
        setOpenModal(false);
      });
  };
  
  return (
    <Body>
      <Sidebar />

      <Wrapper>
        <NavHeader title="Admin" />
        <Filter handleSearchFilter={setSearchFilter} showStatusFilter={false} />
        {isSuperAdmin && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: '90%',
              justifyContent: 'flex-end',
              margin: '10px auto',
            }}
          >
            <CreateAdmin
              onClick={() => {
                setOpenModal(true);
                setState("create");
              }}
            >
              Create new admin
            </CreateAdmin>
          </div>
        )}

        <HistoryList>
          <Listdiv>
            <ListAddress className="date">Date</ListAddress>
            <ListAddress>Name</ListAddress>
            <ListAddress className="email">Email Address</ListAddress>
            <ListAddress  className="phone" >Phone Number</ListAddress>
            <ListAddress  className="role">Role</ListAddress>
            
            <ListAddress className="designation">Designation</ListAddress>
            <ListAddress className="action">Action</ListAddress>
          </Listdiv>
          {admins?.documents?.map((info: any, index: any) => (
            <Listdiv key={index}>
              <ListDate className="date">{`${getDate(info?.createdAt)}`}</ListDate>
              <ListDate>{info?.name}</ListDate>
              <ListDate className="email">{info?.email}</ListDate>
              <ListDate  className="phone">{info?.phoneNumber}</ListDate>
              <ListDate  className="role">{info?.role}</ListDate>
              <ListDate className="designation">
                {info?.isSuperAdmin ? "Super Admin" : "Admin"}
              </ListDate>

              <ListDate  className="action" onClick={() => {
                setAdminId(info?._id)
                setOpenModal(true)}
              }>
                <img src="/delete-red.svg" alt="" />
              </ListDate>
            </Listdiv>
          ))}
        </HistoryList>
        <BasicModal
          content={ModalContent(state)}
          open={openModal}
          close={() => {
            setOpenModal(false);
            setState("default");
          }}
          height={"100"}
          width={'500px'}
        />
        <Feedback
          feedback={feedback}
          open={openFeedback}
          close={() => setOpenFeedback(false)}
        />
      </Wrapper>
    </Body>
  );
};

const Body = styled.main`
  display: flex;
`;
const Wrapper = styled.main`
  width: 85vw;
  overflow-y: scroll;
  margin-left: 15vw;
  background-color: #fafafa;
  @media (max-width: 425px) {
  }
`;

const CreateAdmin = styled.button`
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 18px 18px;
background: #F66D00;
border-radius: 5px;
border:none;
color:#ffffff;
font-weight: 800;

`;

const HistoryList = styled.section`
  background-color: #fafafa;
  width: 100%;
  @media (max-width: 425px) {
    width: 100%;
    background-color: #ffffff;
  }
`;



const Listdiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: 50px;
  margin: 0px auto;
  padding: 0px 20px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.01);
  border-bottom: 1px solid #E0E0E0;
  @media (max-width: 425px) {
    width: 90%;
  }
`;

const ListImage = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e6ebf8;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ListAddress = styled.p`
  font-weight: 300;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  text-align: left;
  width: 15%;
  &.action {
    text-align: right;
    width: 10%;
  }
  &.date {
    width: 10%;
  }
  &.designation {
    width: 10%;
   
  }
  &.email {
    width: 25%;
  }
`;

const ListAmount = styled.p`
  color: #ed2727;
  cursor: pointer;
`;

const ListDate = styled.p`
  color: #333;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  text-align: left;
  width: 15%;
  &.action {
    text-align: right;
    width: 10%;
    cursor:pointer;
  }
  &.date {
    width: 10%;
  }
  &.designation {
    width: 10%;
    font-weight: 500;
  }
  &.email {
    width: 25%;
  }
`;

const Yes = styled.span`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 600;
  font-size: 32.0385px;
  line-height: 48px;
  /* identical to box height */

  text-align: center;
  display: block;
  color: #fe7f20;
  cursor: pointer;
`;

const Cancel = styled.span`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 600;
  font-size: 32.0385px;
  line-height: 48px;
  /* identical to box height */
  cursor: pointer;
  text-align: center;
  display: block;
  color: #ec2a2a;
`;

const DeletePlan = styled.p`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 48px;
  text-align: center;

  color: #4d4949;
`;

const Wrap = styled.p`
  display: flex;
  justify-content: space-evenly;
`;

const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border: 7px solid red;
  border-radius: 50%;
`;

const Text = styled.p`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 48px;
  text-align: center;

  color: #4d4949;
`;
const Title = styled.h1`
  margin: 0px auto;
  width: 90%;

  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 63px;
  text-align: center;
  /* identical to box height */

  color: #2b2929;
`;

const Createsub = styled.form`
  width: 90%;
  margin: 15px auto;
  display: flex;
  flex-direction: column;
  border-radius: 1px;
  gap: 10px;
`;

const InputWrapper = styled.div`
  width: 96%;
  margin: 0px auto;
`;

const Inputs = styled.input`
  width: 98%;
  background-color: #ffffff;
  border: none;
  outline: none;
  padding: 10px 0;
  border-radius: 4px;
  padding-left: 2%;
  border: 1px solid #828282;
`;

const Labels = styled.label`
  color: #333333;
`;

const MiddleInput = styled.div`
  display: flex;
  gap: 10px;
`;

const SubmitButton = styled.button`
 background: #F66D00;
  border-radius: 5px;
  width: 96%;
  height: 50px;
  margin: 10px auto;
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;

  /* identical to box height, or 34px */

  text-align: center;

  color: #ffffff;
  border:none;
`;

const MiddleInput2 = styled.div`
  border: none;
  padding: 10px;
  background-color: #f8f7f7;
`;

const Select = styled.select`
  font-size: 14px;
  border: none;
  width: 100%;
  background: #f8f7f7;
`;
