import React from "react";
import styled from "styled-components";

interface props {
  number: number;
  status: string;
  textColor: string;
  statusColor: string;
  BackColor: string;
  onClick?:any
}

export const Box = ({
  number,
  status,
  textColor,
  BackColor,
  onClick,
  statusColor
}: props) => {
  return (
    <BoxWrap onClick={onClick} backgroundColor={BackColor}>
       <NumberWrap textColor={statusColor}>{number}</NumberWrap>
       <StatusWrap textColor={textColor}>{status}</StatusWrap>
    </BoxWrap>
  );
};

const BoxWrap = styled.div<{backgroundColor: string}>`
  background-color: ${(property) => property.backgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  width: 250px;
  height: 120px;
  cursor: pointer;
  border: 1px solid #A5A5A5;
`;

const NumberWrap = styled.p<{ textColor: string }>`
    font-family: 'Red Hat Display';;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: ${(property) => property.textColor};
    margin-bottom: 10px;
`;

const StatusWrap = styled.p<{ textColor: string }>`
  color: ${(property) => property.textColor};
    font-family: 'Red Hat Display';;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
`;
