import React, {  useState } from "react";
import { useQueryClient } from "react-query";
import styled from "styled-components";
import axios from "axios";
import Feedback from "./Feedback";

export const CreateBikes = () => {
    const queryClient = useQueryClient();
    const batteries:any = queryClient.getQueryData("BATTERIES");
    const riders: any = queryClient.getQueryData("RIDERS");
  const Baseurl = process.env.REACT_APP_BASEURL;
  const getToken: any = localStorage.getItem("eftd-admin-token");
  const tokenObject: any = JSON.parse(getToken);
  const token = tokenObject?.data?.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [isLoading, setIsLoading] = useState(false);
  const [formdata, setFormdata] = useState({
    battery: "",
    ownership: "",
    bikeNumber: "",
    cartrackId: "",
    rider: ""
  });
  const [openModal, setOpenMOdal] = useState(false)
  const [feedback, setFeedback] = useState({
    message: '',
    status: '',
    open: false
  })

  // useEffect(() => {
  //   axios
  //     .get(`${Baseurl}/api/admin/bike/cartrack`, config)
  //     .then((res) => console.log(res))
  //     .catch((err) => console.log(err));
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  const handleChange = (e: any) => {
    setFormdata((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    e.preventDefault();

    axios
      .post(`${Baseurl}/api/admin/bike/cartrack`, [formdata], config)
      .then((res) => {
        setFeedback((prev)=>{
          return {...prev, open: true,status: 'success', message: 'Bike Created'}
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setFeedback((prev)=>{
          return {...prev, open: true,status: 'error', message: 'Error creating bike'}
        });
        setIsLoading(false);
      })
  };
  return (
    <Wrapper>
      <Title>Add Bike</Title>
      <Createsub onSubmit={handleSubmit}>
          <InputWrapper>
            <Labels>Rider </Labels>
            <Dropdown
              name="assignedRider"
              value={formdata?.rider}
              onChange={(e) => {
                setFormdata((prev) => {
                  return { ...prev, rider: e.target.value };
                });
              }}
            >
              <option>Select rider</option>
              {riders?.map((rider: any) => (
                <option value={rider._id}>{rider.fullName}</option>
              ))}
            </Dropdown>
          </InputWrapper>
          <InputWrapper>
            <Labels>Bike number </Labels>
            <Inputs
              name="bikeNumber"
              onChange={handleChange}
              type="text"
              placeholder="bike number"
            />
          </InputWrapper>
          <InputWrapper>
            <Labels>Battery </Labels>
            <Dropdown
              name="battery"
              value={formdata?.battery}
              onChange={(e) => {
                setFormdata((prev) => {
                  return { ...prev, battery: e.target.value };
                });
              }}
            >
              <option>Select Battery</option>
              {batteries?.map((battery: any) => (
                <option value={battery._id}>{battery.batteryNumber}</option>
              ))}
            </Dropdown>
          </InputWrapper>
          <InputWrapper>
            <Labels>Ownership</Labels>
            <Dropdown
              name="ownership"
              value={formdata?.ownership}
              onChange={(e) => {
                setFormdata((prev) => {
                  return { ...prev, rider: e.target.value };
                });
              }}
            >
              <option>Select Ownership</option>
              {['Company', 'External']?.map((rider: string) => (
                <option value={rider}>{rider}</option>
              ))}
            </Dropdown>
          </InputWrapper>
          <InputWrapper>
            <Labels>Cartrack ID </Labels>
            <Inputs
              name="cartrackId"
              onChange={handleChange}
              type="text"
              placeholder="carTrackID"
            />
          </InputWrapper>
        <SubmitButton disabled={isLoading}>{isLoading ? "Loading...": "Save"}</SubmitButton>
      </Createsub>
      <Feedback
        feedback={feedback.status}
        message={feedback.message}
        open={openModal}
        close={() => setOpenMOdal(false)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.aside`
  width: 100%;
`;

const Title = styled.h1`
  margin: 20px auto;
  width: 90%;
  text-align: center;

  font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  /* identical to box height */

  color: #2b2929;
`;

const Createsub = styled.form`
  background-color: #ffffff;
  width: 90%;
  margin: 15px auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;

const InputWrapper = styled.div`
  width: 90%;
  margin: 5px auto;
  display: flex;
  flex-direction: column;
  
`;

const Inputs = styled.input`
  width: 98%;
  background-color: #ffffff;
  border: 1px solid #828282;
  outline: none;
  border-radius: 4px;
  padding-left: 2%;
  font-size: 18px;
  height: 40px;
`;

const Labels = styled.label`
  color: #333333;
`;

const MiddleInput = styled.div`
  display: flex;
  gap: 10px;
`;

const SubmitButton = styled.button`
/* padding: 0px 150px; */
background: #F66D00;
border-radius: 5px;
  border: none;
  height: 50px;
  margin: 50px auto 20px;
  font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  /* identical to box height, or 34px */

  text-align: center;

  color: #ffffff;
  width: 90%;
`;
const Dropdown = styled.select`
  height: 40px;
  font-size: 16px;
  padding-left: 10px;
  background: #FFFFFF;
  color: #828282;
/* Gray 3 */

border: 1px solid #828282;
border-radius: 5px;
`;