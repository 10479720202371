import styled from "styled-components";
import { useQuery, useQueryClient } from "react-query";
import { NavHeader } from "../../components/NavHeader";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getDate, getTime } from "../../utils/utils";
import { Sidebar } from "../../components/Sidebar";
import { useState } from "react";
import axios from "axios";
import PaginationComponent from "../../components/PaginationComponent";

export const Activity = () => {
   const [currentPage, setCurrentPage] = useState(1);
   const Baseurl = process.env.REACT_APP_BASEURL;
    const getToken: any = localStorage.getItem("eftd-admin-token");
    const tokenObject: any = JSON.parse(getToken);
    const token = tokenObject?.data?.token;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  const [value, setValue] = useState<Date | null>(null);
  const [search, setSearch] = useState('')
  const getActivities =  async () => {
    const response = await  axios.get(`${Baseurl}/api/admin/activity?limit=10&page=${currentPage}&search=${search}`, config)
    return response?.data?.result
  }

  const {
    data: activities,
    refetch: refetchCount,
    isLoading
  } = useQuery(["ACTIVITIES",currentPage, search, value] ,()=>getActivities());
  return (
    <Body>
      <Sidebar />
      <Wrapper>
        <NavHeader title="Admin Activity" />
        
        <AdminAct>
          <h1>Admin Activities</h1>
        <DateWrapper>
          <InputWrapper>
            <Inputs placeholder="Search" value={search} onChange={(e)=>setSearch(e.target.value)} />
            <SearchIcon>
              <img width={"100%"} src="/searchicongrey.png" alt="" />
            </SearchIcon>
          </InputWrapper>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              inputFormat="dd/MM/yyyy"
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    svg: { color: "#626767" },
                    input: { color: "#626767" },
                    background: "#ffffff",
                    border: "1px solid #626767;",
                    borderRadius: "4px",
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </DateWrapper>

          {activities?.documents?.length > 0
            ? activities?.documents?.map((item: any) => (
                <Info>
                  <Name>{`${item?.admin?.name} ${item.type}`}</Name>
                  <Date>{`${getDate(item?.createdAt)} ${getTime(
                      item?.createdAt
                    )} `}</Date>
                </Info>
              ))
            : "No activity"}
        </AdminAct>
        <PaginationComponent totalPages={activities?.paging?.totalPages} currentPageNo={setCurrentPage}/>
      </Wrapper>
    </Body>
  );
};

const Body = styled.main`
  display: flex;
`;

const Wrapper = styled.aside`
  width: 85vw;
  overflow-y: auto;
  margin-left: 15vw;
  height: 100vh;
  background-color: #fafafa;
`;

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4%;
  width: 95%;
  margin: 10px auto;
  /* padding: 20px 0px; */
`;
const SearchIcon = styled.p`
  height: 20px;
  width: 20px;
  /* border-radius: 50%; */
  margin-left: 2%;
`;

const InputWrapper = styled.div`
  width: 90%;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 5px 20px;
  border: 1px solid #CED3D8;
`;
const Inputs = styled.input`
  width: 100%;
  background-color: #ffffff;
  border: none;
  outline: none;
  padding: 5px 0;
  border-radius: 8px;
  padding-left: 1%;
`;
const AdminAct = styled.div`
  width: 90%;
  /* height: 540px; */
  margin: 30px auto;
  padding: 20px 0px;

  background: #ffffff;
  border-radius: 24px;

  >h1 {
    width: 95%;
    margin: auto;
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid #E0E0E0;
  width: 95%;
  margin: 0 auto;
  padding: 10px 0px;
`;
const Day = styled.div`
  width: 152px;
  height: 52px;
  background: #f1f1f3;
  border-radius: 12px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  color: #333232;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2%;
`;
const Name = styled.p`
  width: 50%;
    font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 33px;
  /* identical to box height */

  color: #333232;
`;
const UpdateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 50%;
`;
const Update = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ebf0fc;
  border-radius: 12px;
    font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  /* line-height: 30px; */
  padding: 0px 20px;
`;

const Date = styled.p`
    font-family: 'Red Hat Display';;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 33px;
  /* identical to box height */

  color: #9c9696;
`;
