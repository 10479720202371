import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import styled from "styled-components";
import { convertedData } from '../utils/utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Delivery Analysis',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];


const datas: any = [{
    name: 'delivered',
    number: 2

  },
  {
    name: 'Cancelled',
    number: 2

  },
  {
    name: 'pickedup',
    number: 2

  },
  {
    name: 'processing',
    number: 2

  }
]

export function Linechart({info}: any) {
  const datas = convertedData(info)
  const daysArray = info?.deliveryAnalysis.map((item:any) => item.day);
  const data = {
    labels: daysArray,
    datasets: datas 
  };
  console.log({datas})
  return (
    <Wrapper>
          <div style={{height: '300px'}}>
         <Line options={options} data={data} />
         </div>
         <Info>
      {/* {
          datas.map((fields: any, index:any) => {

            return (
              <div key={index} style={{background: Number(index) % 2 === 0  ? '#FFD9BC': '#FDECDF'}} >
              <p  className="circle">{fields.label}</p>
              <p>{fields.number}</p>
              <p>{fields.number}</p>
              <p>{fields.number}</p>
              </div>
            )
          })
        } */}
      </Info>
    </Wrapper>

  )
}

const Wrapper = styled.section`
  /* height: 900px; */
  background: #ffffff;
  width: 45%;
  border-radius: 5px;
border: 1px solid var(--gray-6, #F2F2F2);


`

// const Title = styled.h1`
//   color: var(--e-ftd-orange, #FE7F1F);
// /* Small */
// font-family: Red Hat Display;
// font-size: 14px;
// font-style: normal;
// font-weight: 500;
// line-height: normal;

// `

const Legends = styled.div`
margin: 20px auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 300px;
  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;

  }
`
const Info = styled.div`
  width: 100%;
  margin: 20px auto;
  > div {
    margin: 0px auto;
    width: 300px;
    color: #000000;
    display: flex;
    align-items: center;
    
    

    
  }
  p {
    padding: 20px;
    width: 25%;
    border-left: 1px solid #FCCEAA;
  }
`