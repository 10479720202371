import { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import axios from "axios";
import BasicModal from "../../components/Modal";
import { Sidebar } from "../../components/Sidebar";
import { NavHeader } from "../../components/NavHeader";
import Spinner from "../../components/Spinner";
import { CreateBikes } from "../../components/CreateBikes";
import Feedback from "../../components/Feedback";
import { getDate } from "../../utils/utils";
import Filter from "../../components/Filter";

export const BikesPage = () => {
  const queryClient = useQueryClient();
  const [searchFilter, setSearchFilter] = useState<any>()
  const riders: any = queryClient.getQueryData("RIDERS");
  const riderDetails: any = queryClient.getQueryData("RidersObject");
  const bikes: any = queryClient.getQueryData("BIKES");
  const Baseurl = process.env.REACT_APP_BASEURL;
  const getToken: any = localStorage.getItem("eftd-admin-token");
  const tokenObject: any = JSON.parse(getToken);
  const token = tokenObject?.data?.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const [state, setState] = useState("");
  const [openFeedback, setOpenFeedback] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [info, setInfo] = useState<any>({});
  const [formdata, setFormData] = useState({
    ownership: "",
    battery: "",
  });
  const [rider, setRider] = useState("");

  const getCartrackBike = async () => {
    const response = await axios.get(
      `${Baseurl}/api/admin/bike/cartrack`,
      config
    );
    return response?.data?.result;
  };

  const { data: carTrackBikes, isLoading } = useQuery(
    "CARTRACKBIKES",
    getCartrackBike
  );

  const edit = (e: any, id: any): any => {
    e.preventDefault();
    const data = { ...formdata, rider };
    axios
      .patch(`${Baseurl}/api/admin/bike/${id}`, [data], config)
      .then((res) => {
        setFeedback("success");
        setOpenFeedback(true);
      })
      .catch((err) => {
        setFeedback("error");
        setOpenFeedback(true);
      });
  };

  const handleChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const content = (state: string) => {
    switch (state) {
      case "add": {
        return <CreateBikes />;
      }
      case "edit": {
        return (
          <FormWrap>
            <MiddleInput>
              <InputWrapper>
                <Labels>Rider </Labels>
                <Dropdown
                  name="rider"
                  value={rider}
                  onChange={(e) => setRider(e.target.value)}
                >
                  <option>Select rider</option>
                  {riders?.map((rider: any) => (
                    <option key={rider._id} value={rider._id}>
                      {rider.fullName}
                    </option>
                  ))}
                </Dropdown>
              </InputWrapper>
              <InputWrapper>
                <Labels>Battery </Labels>
                <Inputs
                  name="battery"
                  onChange={handleChange}
                  type="text"
                  placeholder="battery"
                />
              </InputWrapper>
            </MiddleInput>
            <MiddleInput>
              <InputWrapper>
                <Labels>Ownership </Labels>
                <Inputs
                  name="ownership"
                  onChange={handleChange}
                  type="text"
                  placeholder="ownership"
                />
              </InputWrapper>
              <InputWrapper>
                <Labels>CartrackId</Labels>
                <Inputs type="text" value={info?.cartrackId} />
              </InputWrapper>
            </MiddleInput>
            <MiddleInput>
              <InputWrapper>
                <Labels>BikeNumber</Labels>
                <Inputs type="text" value={info?.bikeNumber} />
              </InputWrapper>
            </MiddleInput>
            <SubmitButton onClick={(e) => edit(e, id)}>Edit</SubmitButton>
          </FormWrap>
        );
      }
    }
  };

  return (
    <Body>
      <Sidebar />
      <Wrapper>
        <NavHeader title="Bikes" />
        <Filter handleSearchFilter={setSearchFilter}/>
        <AddBikesSection
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "90%",
            margin: "auto",
            cursor:'pointer'
          }}
        >
          <p>View bikes locations</p>
          <button onClick={()=>{
            setState('add')
            setOpen(true)
          }}>Add Bike</button>
        </AddBikesSection>
        {/* <CreateBikes /> */}
        {/* <Tab>
          <Eftd stage={state} onClick={() => setState("eftd")}>
            eFTD Bikes
          </Eftd>
          <Cartrack stage={state} onClick={() => setState("cartrack")}>
            Car Track Bikes
          </Cartrack>
        </Tab> */}
        {/* {state === "cartrack" &&
          (isLoading ? (
            <Spinner componentlevel />
          ) : (
            <Table>
              <h1>Car track bikes</h1>
              <Row>
                <ID>Bike Number</ID>
                <ID>Ownership</ID>
                <ID>CarTrack ID</ID>
              </Row>
              {carTrackBikes?.map((item:any, index:any) => (
                <Row key={index}>
                  <ID>{item?.bikeNumber}</ID>
                  <Types type={item?.ownership}>{item?.ownership}</Types>
                  <ID>{item?.cartrackId}</ID>
                </Row>
              ))}
            </Table>
          ))} */}
        {!bikes ? (
          <Spinner componentlevel />
        ) : (
          <Table>
            <RowHeader>
              <IDHeader>Date Added</IDHeader>
              <IDHeader>Bike Number</IDHeader>
              <IDHeader>Ownership</IDHeader>
              <IDHeader>Assigned to</IDHeader>
              <IDHeader  className="edit">Action</IDHeader>
            </RowHeader>
            {bikes?.documents?.map((item: any, index: any) => (
              <Row key={index}>
                <ID2>{getDate(item?.createdAt)}</ID2>
                <ID2>{item?.bikeNumber}</ID2>
                <ID2>{item?.ownership}</ID2>
                <ID2>{riderDetails[item?.rider]}</ID2>

                <ID2
                className="edit"
                  onClick={() => {
                    setState('edit')
                    setOpen(true);
                    setId(item?.bikeNumber);
                    setInfo(item);
                  }}
                >
                  Edit details{" "}
                </ID2>
                <BasicModal
                  content={content(state)}
                  open={open}
                  close={() => setOpen(false)}
                  width={'600px'}
                />
              </Row>
            ))}
          </Table>
        )}
      </Wrapper>
      <Feedback
        feedback={feedback}
        open={openFeedback}
        close={() => setOpenFeedback(false)}
      />
    </Body>
  );
};
const Body = styled.main`
  display: flex;
`;
const Wrapper = styled.aside`
  width: 85vw;
  height: 200vh;
  overflow-y: scroll;
  margin-left: 15vw;
  background-color: #fafafa;
`;
const SubmitButton = styled.button`
  background: #fe7f20;
  border: 0.5px solid #fe7f20;
  border-radius: 18px;
  width: 80%;
  height: 47px;
  margin: 20px auto;
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
  /* identical to box height, or 34px */

  text-align: center;

  color: #ffffff;
`;

const AddBikesSection = styled.div`
  align-items: flex-end;

  p {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;

    /* New Orange */

    color: #f66d00;
  }
  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    border: none;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height */

    /* eFTD- White */

    color: #ffffff;
    font-weight: 900;

    background: #f66d00;
    border-radius: 5px;
  }
`;

const Tab = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: auto;
  padding: 16px;
  border-radius: 12px;
`;

const FormWrap = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const MiddleInput = styled.div`
  display: flex;
  gap: 10px;
`;
const Labels = styled.label`
  color: #a09e9e;
  padding-left: 2%;
`;

const InputWrapper = styled.div`
  width: 46%;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
`;

const Inputs = styled.input`
  width: 100%;
  background-color: #f8f7f7;
  border: none;
  outline: none;
  padding: 10px 0;
  border-radius: 8px;
  padding-left: 1%;
`;
const Table = styled.main`
  margin: 40px auto;
  width: 90%;
`;
const RowHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 16px;
  margin: 5px 0;

`;
const Row = styled.div`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  margin: 0px 0;
  border-bottom: 1px solid#E0E0E0;
`;

const ID = styled.p``;

const ID2 = styled.p`
  font-size: 16px;
  color: #333333;
  width: 20%;
  /* text-align: center;
  font-style: normal;
  font-weight: 400;
  max-width: 20%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; */
  &.edit {
    text-align: right;
  }
`;

const IDHeader = styled(ID2)`
  color: #333333;
  font-weight: 700;
  &.edit {
    text-align: right;
  }
`;

const ID3 = styled.p`
  width: 30%;
`;

const Types = styled.p<{ type: string }>`
  color: ${(props) => (props.type === "Hire-purchase" ? "#FF6610" : "#000000")};
`;

const ViewDetails = styled.p`
  cursor: pointer;
  color: #3f4196;
  width: 20%;
`;
const Dropdown = styled.select`
  height: 30px;
  font-size: 14px;
  border-radius: 8px;
  background-color: #f8f7f7;
  outline: none;
  border: none;
`;
const Cartrack = styled.p<{ stage: string }>`
  width: 50%;
  height: 4rem;
  background-color: ${(props) =>
    props.stage === "cartrack" ? "#f2f6ff" : "#ffffff"};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const Eftd = styled.p<{ stage: string }>`
  width: 50%;
  height: 4rem;
  background-color: ${(props) =>
    props.stage === "eftd" ? "#f2f6ff" : "#ffffff"};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
