import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import axios from "axios";
import Feedback from "./Feedback";

type SubType = {
  title: string;
  id?: string;
}

export const CreateSubscription = ({ title, id}: SubType) => {
  const [feedback, setFeedback] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const Baseurl = process.env.REACT_APP_BASEURL;
  const getToken: any = localStorage.getItem("eftd-admin-token");
  const tokenObject: any = JSON.parse(getToken);
  const token = tokenObject?.data?.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const getPlan = async () => {
    const response = await axios.get(`${Baseurl}/api/admin/subscription/getplan/${id}`, config)
    return response?.data?.data
   }
 
  

  const [data, setData] = useState({
    benefits: [],
    name:  "",
    amount:  0,
    validity:  0,
    description:  "",
    allowedDeliveries: 0,
    baseFee:0,
  });

  const {data: singlePlan } = useQuery(['PLAN', id], getPlan, {
    enabled: Boolean(id),
    onSuccess: (data) => {
      setData(prev => {
        return {...prev, ...data}
      })
    }
   })

  const handleChange = (e: any) => {
    setData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const createPlan = (e: any) => {
    e.preventDefault();
    const datas = {
      ...data,
      amount: data.amount * 100,
      baseFee: data.baseFee * 100,
      allowedDeliveries: Number(data.allowedDeliveries),
      validity: Number(data.validity)
    };
   
    axios
      .post(`${Baseurl}/api/admin/subscription/createplan`, datas, config)
      .then((res) => {
        setFeedback("success");
        setOpenModal(false);
      })
      .catch((err) => {
        setFeedback("failed");
        setOpenModal(false);
      });
  };

  const editPlan = (e: any) => {
    e.preventDefault();
    const datas = {
      ...data,
      amount: data.amount * 100,
      baseFee: data.baseFee * 100,
      allowedDeliveries: Number(data.allowedDeliveries),
      validity: Number(data.validity)
    };
   
    axios
      .put(`${Baseurl}/api/admin/subscription/editplan/${id}`, datas, config)
      .then((res) => {
        setFeedback("success");
        setOpenModal(false);
      })
      .catch((err) => {
        setFeedback("failed");
        setOpenModal(false);
      });
  };
  
  return (
    <Wrapper>
      <Title>{title} Subscription</Title>
      <Createsub>
          <InputWrapper>
            <Labels>Subscription name </Labels>
            <Inputs value={data.name} onChange={(e) => handleChange(e)} name="name" />
          </InputWrapper>
          <InputWrapper>
            <Labels>Subscription amount(in Naira) </Labels>
            <Inputs
              onChange={(e) => handleChange(e)}
              name="amount"
              type="number"
              value={data.amount}
            />
          </InputWrapper>
          <InputWrapper>
            <Labels>Order limit </Labels>
            <Inputs
              onChange={(e) => handleChange(e)}
              name="allowedDeliveries"
              type="number"
              value={data.allowedDeliveries}
            />
          </InputWrapper>
          <InputWrapper>
            <Labels>Validity ( in days )</Labels>
            <Inputs value={data.validity} onChange={(e) => handleChange(e)} name="validity" />
          </InputWrapper>
          <InputWrapper>
            <Labels>Description </Labels>
            <Inputs value={data.description} onChange={(e) => handleChange(e)} name="description" />
          </InputWrapper>
          <InputWrapper>
            <Labels>Base fee (in Naira) </Labels>
            <Inputs
              onChange={(e) => handleChange(e)}
              name="baseFee"
              type="number"
              value={data.baseFee}
            />
          </InputWrapper>
          <InputWrapper2>
            <Labels>Features </Labels>
            <TextAreas value={data.benefits} onChange={(e) => handleChange(e)} name="benefits" />
          </InputWrapper2>
       { title === "Create" && <SubmitButton onClick={createPlan}>Save</SubmitButton>}
       { title === "Edit" && <SubmitButton onClick={editPlan}>Save</SubmitButton>}
      </Createsub>
        <Feedback
        feedback={feedback}
        open={openModal}
        close={() => setOpenModal(false)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.aside`
  width: 100%;
  overflow-y: scroll;
`;

const Title = styled.h1`
  margin: 0px auto;
  width: 90%;

  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 63px;
  text-align: center;
  /* identical to box height */

  color: #2b2929;
`;

const Createsub = styled.form`
  width: 90%;
  margin: 15px auto;
  display: flex;
  flex-direction: column;
  border-radius: 1px;
`;

const InputWrapper = styled.div`
  width: 96%;
  margin: 0px auto;
`;

const InputWrapper2 = styled.div`
  width: 96%;
  margin: 0px auto;
`;
const Inputs = styled.input`
  width: 98%;
  background-color: #ffffff;
  border: none;
  outline: none;
  padding: 10px 0;
  border-radius: 4px;
  padding-left: 2%;
  border: 1px solid #828282;
`;

const TextAreas = styled.textarea`
  width: 98%;
  background-color: #ffffff;
  border: none;
  outline: none;
  border-radius: 4px;
  padding-left: 2%;
  max-width: 98%;
  border: 1px solid #828282;
`;

const Labels = styled.label`
  color: #a09e9e;
  font-size:14px;
`;

const MiddleInput = styled.div`
  display: flex;
  gap: 10px;
`;

const SubmitButton = styled.button`
 background: #F66D00;
  border-radius: 5px;
  width: 96%;
  height: 50px;
  margin: 10px auto;
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;

  /* identical to box height, or 34px */

  text-align: center;

  color: #ffffff;
  border:none;
`;
