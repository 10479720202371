import React from "react";
import axios from "axios";
import {Link} from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { toast } from "react-toastify";
import styled from "styled-components";
import { BsArrowLeft, BsEyeFill } from "react-icons/bs";
import colours from "../../styles/appColours";

function Login() {
  const Baseurl = process.env.REACT_APP_BASEURL;
  const gettoken = localStorage.getItem("eftd-admin-token");
  const navigate = useNavigate()
  const [passwordShown, setPasswordShown] = useState(false);
  const [error, setError]=useState('')
  const [isLoading, setIsLoading] = useState(false);
  const handleToggle = () => {
    setPasswordShown(!passwordShown);
  };

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      axios.post(`${Baseurl}/api/admin/auth/login`, formData)
      .then((res)=> {
        localStorage.setItem('eftd-admin-token', JSON.stringify(res))
         navigate("/dashboard");
      })
      .catch((err)=> {
        setError(err?.response?.data?.error)
      setIsLoading(false);
      })
     
    } catch (err:any) {
    setIsLoading(false);
    }
  };

  useEffect(() => {
    if (gettoken) {
      navigate("/placeorder");
    }
  }, [gettoken]);

  return (
    <Wrapper onSubmit={handleSubmit}>
      <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "30px",
            gap: "10px",
          }}
        >
          <img width={50} height={50} src="/logo2.svg" alt="" />

          <p style={{ color: "#333333", fontWeight: 700, fontSize: "2rem" }}>
            eFTD Africa
          </p>
        </div>
      <Top>
        <Title>Log in to your account</Title>
        <p>{error}</p>
        <Form>
          <InputWrapper>
            <Input
              type="text"
              name="email"
              onChange={(e)=>{
                handleChange(e)
                setError('')
              }}
              placeholder="Email"
              required
              pattern="[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[A-Za-z]{2,}$"
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              type={passwordShown ? "text" : "password"}
              name="password"
              onChange={(e)=>{
                handleChange(e)
                setError('')
              }}
              placeholder="Password"
              required
              title="Must contain at least one  number and one uppercase and lowercase letter, and at least 8 or more characters"
            />
            <EyeIcon onClick={handleToggle}>
              <BsEyeFill />
            </EyeIcon>
          </InputWrapper>
        </Form>
        <Button  style={{ color: "#ffffff", width: "100%", backgroundColor: "#000" }} disabled={isLoading}>{isLoading ? 'loading...' :'Log in'}</Button>
      </Top>
    </Wrapper>
  );
}

export default Login;

//styles
const Wrapper = styled.form`
 background-color: #f8f9fb;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  justify-content: center;
`;

const Button = styled.button`
  background-color: ${colours.secondary};
  height: 53px;
  width: 40vw;
  border-radius: 8px;
  border: none;
  color: ${colours.black};
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;

  @media (max-width: 425px) {
    width: 90vw;
  }
`;

export const Input = styled.input`
  display: block;
  height: 53px;
  width: 100%;
  max-width: 640px;
  font-size: 20px;
  border-radius: 8px;
  background-color: #f2f2f2;
  outline: none;
  border: none;
  :-webkit-autofill {
    background-color: #f2f2f2;
    box-shadow: 0 0 0px 1000px #ffffff inset;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  }

  &:autofill {
    box-shadow: 0 0 0px 1000px #ffffff inset;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  }
  @media (max-height: 700px) {
    /* height: 40px; */
    /* margin-bottom: 10px; */
  }
`;
const TermsLink = styled.p`
  color: ${colours.secondary};
  cursor: pointer;
`;

export const Form = styled.div`
    display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  gap: 20px;
  background-color: #ffffff;
  label {
    color: #bdbdbd;
  }
`;

export const TermsCon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  @media (max-width: 600px) {
    width: 90vw;
  }
`;

export const Title = styled.h1`
  font-weight: 500;
`;

export const Icon = styled.p`
  width: 40vw;
  text-align: left;
  cursor: pointer;
  margin-top: 30px;
  @media (max-width: 600px) {
    width: 90vw;
  }
`;

export const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  margin-right: 20px;
`;

const ForgotPassword = styled.p`
  color: #c32626;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`;
export const Links = styled.span`
  color: ${colours.secondary};
`;

const CreateAccount = styled.div`
  width: 100%;
  margin: 100px 0;
  text-align: center;
  @media (max-width: 600px) {
    margin: 50px 0;
  }
`;

export const Top = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 30px;
  margin-bottom: 50px;
  background-color: #ffffff;
  padding: 50px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const Bottom = styled.section`
  /* height: 35vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40vw;
  max-width: 100%;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  background-color: #f2f2f2;
  padding: 0 10px;
  margin-top: 10px;
  @media (max-width: 1200px) {
    width: 50vw;
  }
  @media (max-width: 600px) {
    width: 90vw;
  }
  @media (max-height: 700px) {
    height: 40px;
  }
`;

export const EyeIcon = styled.span`
  cursor: pointer;
`;