import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getDate } from "../utils/utils";
import { useNavigate } from "react-router-dom";

type Props = {
  data: any[];
  pageLimit: any;
  dataLimit: any;
  type: string;
};

export default function Pagination({
  data,
  pageLimit,
  dataLimit,
  type,
}: Props) {
  const navigate = useNavigate();
  const [pages] = useState(Math.round(data.length / dataLimit));
  const [currentPage, setCurrentPage] = useState(1);

  function goToNextPage() {
    setCurrentPage((page) => page + 1);
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
  }

  function changePage(event: any) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return data.slice(startIndex, endIndex);
  };

  const getPaginationGroup = () => {
    let total = Math.ceil(data.length / dataLimit);
    // let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    return new Array(total).fill(0).map((_, idx) => idx + 1);
  };

  const handleRedirect = (id:string) => {
    if ( type === "riders"){
      navigate( `/riders/${id}`)
    } else {
      navigate( `/pendingriders/${id}`)
    }
  }

  return (
    <div>
      {/* show the posts, 10 posts at a time */}
      {/* <div className="dataContainer"> */}
      <RowHeader >
          <ID className="date">Date</ID>
          <ID>Name</ID>
          <ID className="email">Email Address</ID>
          <Types>Phone Number</Types>
          <ViewDetails>Action</ViewDetails>
        </RowHeader>
      {getPaginatedData().map((item, idx) => (
        <Row key={idx}>
          <ID className="date">{getDate(item.createdAt)}</ID>
          <ID>{item.fullName}</ID>
          <ID className="email">{item.email}</ID>
          <Types>{item.phoneNumber}</Types>
          <ViewDetails onClick={()=>handleRedirect(item._id)}>View more </ViewDetails>
        </Row>
      ))}
      {/* show the pagiantion
        it consists of next and previous buttons
        along with page numbers, in our case, 5 page
        numbers at a time
      */}
      <div className="pagination">
        {/* previous button */}
        <button
          onClick={goToPreviousPage}
          className={`prev ${currentPage === 1 ? "disabled" : ""}`}
        >
          {'<'}
        </button>

        {/* show page numbers */}
        {getPaginationGroup().map((item, index) => (
          <button
            key={index}
            onClick={changePage}
            className={`paginationItem ${
              currentPage === item ? "active" : null
            }`}
          >
            <span>{item}</span>
          </button>
        ))}

        {/* next button */}
        <button
          onClick={goToNextPage}
          className={`next ${currentPage === pages + 1 ? "disabled" : ""}`}
        >
          {'>'}
        </button>
      </div>
    </div>
  );
}

const Row = styled.div`
border-bottom: 1px solid#E0E0E0;
background: var(--e-ftd-white, #FFF);
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding: 15px  10px;
  /* border-bottom: 1px solid #333; */
  margin: 0px 0;
`;
const RowHeader = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding: 10px  10px;
  margin: 0px 0;
  font-weight: 700;
`;

const ID = styled.p`
  text-align: left;
  width: 20%;
  font-size:14px;
  &.date {
    width: 20%;
  }
  &.email {
    width: 30%;
  }
`;

const Types = styled.p`
  color: #000000;
  text-align: left;
  width: 20%;
  font-size:14px;
`;

const ViewDetails = styled.p`
  cursor: pointer;
  color: #333;
  width: 10%;
  text-align: right;
  font-size:14px;
`;
