import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import { NavHeader } from "../../components/NavHeader";
import { useQueryClient } from "react-query";
import Spinner from "../../components/Spinner";
import Feedback from "../../components/Feedback";
import {
  Capitalize,
  formatAmount,
  getDate,
  getTime,
  switchOrderStatus,
} from "../../utils/utils";
import BasicModal from "../../components/Modal";
import VerifyImage from "../../assets/VerifyOrder.svg";
import { BsPerson } from "react-icons/bs";
import SvgIcon from "../../shared/SvgIcon";

export const SingleOrder = () => {
  const { orderID } = useParams();
  const route = useNavigate()
  const queryClient = useQueryClient();
  const riders: any = queryClient.getQueryData("RIDERS");
  const Riderobject: any = queryClient.getQueryData("RidersObject");
  const Baseurl = process.env.REACT_APP_BASEURL;
  const getToken: any = localStorage.getItem("eftd-admin-token");
  const tokenObject: any = JSON.parse(getToken);
  const token = tokenObject?.data?.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [order, setOrder] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [hscodeData, setHscodeData] = useState([]);
  const [feedbackModal, setFeedbackModal] = useState({
    open: false,
    type: "",
    message: "",
    loading: false,
  });
  const [descriptions, setDescription] = useState<any>([
    {
      description: "",
      hsCode: "",
      netWeight: "",
      grossWeight: "",
      unit: "PCS",
      price: 0,
      value: 1,
    },
  ]);
  const [deliveryInfo, setDeliveryInfo] = useState({
    recepientName: "",
    address1: "",
    address2: "",
    length: order?.packages ? order?.packages[0]?.dimensions?.length : "",
    height: order?.packages ? order?.packages[0]?.dimensions?.height : "",
    width: order?.packages ? order?.packages[0]?.dimensions?.width : "",
    countryCode: "",
    state: "",
    cityName: "",
    weight: "",
    email: "",
    code: "+234",
    phoneNumber: "",
    postalCode: "",
    monetaryAmount: "0",
    description: "",
    pickupRider: "",
    deliveryRider: "",
  });

  useEffect(() => {
    axios
      .get(`${Baseurl}/api/admin/orders?orderId=${orderID}`, config)
      .then((res) => {
        setOrder(res?.data?.data?.documents[0]);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [orderID]);

  const handleChange = (e: any) => {
    setDeliveryInfo((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDownload = (pdfUrl: string, name: string) => {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.setAttribute("target", "_blank"); 
    link.setAttribute("download", `${name}.pdf"`); // Optional: Add a filename for download
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const AddMore = () => {
    setDescription([
      ...descriptions,
      {
        description: "",
        hsCode: "",
        netWeight: "",
        grossWeight: "",
        unit: "PCS",
        price: 0,
        value: 1,
      },
    ]);
  };

  let removeFormFields = (i: any) => {
    if (descriptions.length > 1) {
      let newFormValues = [...descriptions];
      newFormValues.splice(i, 1);
      setDescription(newFormValues);
    } else {
      let lastFormValue = [...descriptions];
      lastFormValue.splice(0, lastFormValue.length - 1);
      setDescription(lastFormValue);
    }
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    setFeedbackModal({ ...feedbackModal, loading: true });
    const monetaryAmount = Number(order?.monetaryAmount) || 0;
    const {
      pickUpDetails,
      deliveryDetails,
      description,
      estimatedCost,
      lineItems,
      packages: orderPackage,
      pickupRider,
    } = order;
    const formatAddress = (deliveryDetails[0]?.fullAddress).slice(0, 44);
    const delDetails = [{ ...deliveryDetails[0], fullAddress: formatAddress }];
    const packages = [
      {
        weight: Number(deliveryInfo.weight) || orderPackage[0].weight,
        dimensions: {
          length:
            Number(deliveryInfo.length) || orderPackage[0]?.dimensions?.length,
          width:
            Number(deliveryInfo.width) || orderPackage[0]?.dimensions?.width,
          height:
            Number(deliveryInfo.height) || orderPackage[0]?.dimensions?.height,
        },
      },
    ];
    const itemdescription = deliveryInfo?.description || description;
    let request = {
      packages,
      pickUpDetails,
      monetaryAmount,
      deliveryDetails: delDetails,
      lineItems: descriptions,
      description: itemdescription,
      pickupRider: deliveryInfo?.pickupRider || pickupRider,
      estimatedCost,
    };
    axios
      .put(`${Baseurl}/api/admin/orders/${orderID}`, request, config)
      .then((res) => {
        setFeedbackModal((prev) => {
          return {
            ...prev,
            open: true,
            type: "success",
            message: "Order Successfully Updated",
            loading: false,
          };
        });
      })
      .catch((err) => {
        setFeedbackModal((prev) => {
          return {
            ...prev,
            open: true,
            type: "error",
            loading: false,
            message: err?.response?.data?.message || "Order Not Updated",
          };
        });
      });
  };

  const handleSaveHubAndSpoke = (e: any) => {
    e.preventDefault();
    setFeedbackModal({ ...feedbackModal, loading: true });
    const {
      pickUpDetails,
      deliveryDetails,
      description,
      estimatedCost,
      deliveryRider,
      pickupRider,
    } = order;
    const delDetails = [
      {
        ...deliveryDetails[0],
        itemWeight: deliveryInfo.weight || deliveryDetails[0].itemWeight,
      },
    ];
    const request = {
      pickUpDetails,
      deliveryDetails: delDetails,
      description,
      estimatedCost,
      pickupRider: deliveryInfo?.pickupRider || pickupRider,
      deliveryRider: deliveryInfo?.deliveryRider || deliveryRider,
    };
    axios
      .put(`${Baseurl}/api/admin/orders/${orderID}`, request, config)
      .then((res) => {
        setFeedbackModal((prev) => {
          return {
            ...prev,
            open: true,
            type: "success",
            message: "Order Successfully Updated",
            loading: false,
          };
        });
      })
      .catch((err) => {
        setFeedbackModal((prev) => {
          return {
            ...prev,
            open: true,
            type: "error",
            loading: false,
            message: err?.response?.message || "Order Not Updated",
          };
        });
      });
  };

  const handleVerify = (e: any) => {
    e.preventDefault();
    setFeedbackModal({ ...feedbackModal, loading: true });
    axios
      .put(`${Baseurl}/api/admin/orders/verify/${orderID}`, order, config)
      .then((res) => {
        setFeedbackModal((prev) => {
          return {
            ...prev,
            open: true,
            type: "success",
            message: "Order Successfully Verified",
            loading: false,
          };
        });
      })
      .catch((err) => {
        setFeedbackModal((prev) => {
          return {
            ...prev,
            open: true,
            type: "error",
            loading: false,
            message: err?.response?.data?.message || "Order Not Verified",
          };
        });
      });
  };

  const handleHScode = async (search: string) => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_HSCODE_URL}/hs-codes/search/query`,
      params: {
        query_string: search,
      },
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_HSCODE_key}`,
      },
    };
    try {
      const response = await axios.request(options);
      setHscodeData(response?.data?.data?.hs_codes);
    } catch (error) {
      console.error(error);
    }
  };

  let handleDescriptionChange = (i: any, e: any) => {
    let newFormValues: any = [...descriptions];
    newFormValues[i][e.target.name] = e.target.value;
    setDescription(newFormValues);
  };

  useEffect(() => {
    if (!search) {
      setHscodeData([]);
    } else {
      handleHScode(search);
    }
  }, [search]);

  const switchUpdate = (status: string) => {
    switch (status) {
      case "processing":
        return "Order placed by user";
      default:
        return "Updated by";
    }
  };

  return (
    <Body>
      <div className="sidebar">
        <Sidebar />
      </div>

      {loading ? (
        <Wrapper>
          <Spinner componentlevel={true} />{" "}
        </Wrapper>
      ) : (
        <Wrapper>
          <NavHeader title="Order Summary" />

          <Editdetails>
            <div className="orderid">
              <div style={{ display: "flex", gap: "20px" }}>
                <Profile onClick={()=>route(`/customers/${order?.userId?._id}`)}>
                  <p
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      background: "#000000",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <BsPerson style={{ color: "white", fontSize: "24px" }} />
                  </p>
                  <div>
                    <p className="name">
                      {order?.userId?.firstName ?  `${Capitalize(
                        order?.userId?.firstName
                      )} ${Capitalize(order?.userId?.lastName || "")}` :
                        `${Capitalize(order?.userId?.businessName || "")}`}
                    </p>
                    <p className="role">Customer</p>
                  </div>
                </Profile>
                <h2>
                  Order <span style={{ color: "#404653eb" }}> {orderID}</span>
                </h2>
              </div>
              <div className="orderidbtn">
                {order?.deliveryType !== "domestic" && (
                  <button
                    style={{
                      backgroundColor: "#F66D00",
                      borderColor: "#F66D00",
                      color: "#fff",
                      border: "none",
                    }}
                    onClick={() => setOpenModal(true)}
                    disabled={order?.orderStatus !== "pickup"}
                  >
                    {feedbackModal?.loading ? <Spinner /> : "Verify Order"}
                  </button>
                )}
                {!["delivered", "cancelled", "returned", "verified", "ordered"].includes(
                  order?.orderStatus
                ) && (
                  <button
                    style={{
                      border: "1px solid #F66D00",
                      backgroundColor: "#ffffff",
                      color: "#F66D00",
                    }}
                    onClick={
                      order?.deliveryType === "domestic"
                        ? handleSaveHubAndSpoke
                        : handleSave
                    }
                  >
                    Save Changes
                  </button>
                )}
                 {order?.orderStatus === "ordered" && (
                  <button
                    style={{
                      border: "1px solid #F66D00",
                      backgroundColor: "#ffffff",
                      color: "#F66D00",
                    }}
                    onClick={()=> handleDownload(order?.waybillInvoice, order?.deliveryDetails[0].recipientName )}
                  >
                    Download Waybill
                  </button>
                )}

              </div>
            </div>

            <div className="pickupdetails">
              <h4>Order Information</h4>
              <div className="grid orderinfo">
                <div>
                  <p>
                    <strong>Status </strong>
                  </p>
                  <p style={{ color: "#F66D00" }}>
                    {switchOrderStatus(order?.orderStatus)}
                  </p>
                </div>
                <div>
                  <p>
                    <strong
                      style={{
                        color:
                          order?.orderStatus !== "processing" ? "#b4b4b4" : "",
                      }}
                    >
                      Pickup Rider{" "}
                    </strong>
                  </p>
                  <p>
                    {order?.pickupRider ? (
                      <EditableDropdown
                        name="pickupRider"
                        onChange={handleChange}
                        value={deliveryInfo?.pickupRider}
                        disabled={order?.orderStatus !== "processing"}
                      >
                        <option>{Riderobject[order?.pickupRider]}</option>
                        {riders?.map((rider: any) => (
                          <option key={rider._id} value={rider._id}>
                            {rider.fullName}
                          </option>
                        ))}
                      </EditableDropdown>
                    ) : (
                      "Not available"
                    )}
                  </p>
                </div>
                <div>
                  <p>
                    <strong
                      style={{
                        color: ["delivered", "cancelled"].includes(
                          order?.orderStatus
                        )
                          ? "#b4b4b4"
                          : "",
                      }}
                    >
                      Delivery Rider{" "}
                    </strong>
                  </p>
                  <p>
                    {order?.deliveryRider ? (
                      <EditableDropdown
                        name="deliveryRider"
                        onChange={handleChange}
                        value={deliveryInfo?.deliveryRider}
                        disabled={["delivered", "cancelled"].includes(
                          order?.orderStatus
                        )}
                      >
                        <option>{Riderobject[order?.deliveryRider]}</option>
                        {riders?.map((rider: any) => (
                          <option key={rider._id} value={rider._id}>
                            {rider.fullName}
                          </option>
                        ))}
                      </EditableDropdown>
                    ) : (
                      '"Not available"'
                    )}
                  </p>
                </div>
                <div>
                  <p>
                    <strong
                      style={{
                        color: order?.orderStatus !== "pickup" ? "#b4b4b4" : "",
                      }}
                    >
                      Item Weight{" "}
                    </strong>
                  </p>
                  <p>
                    <EditableInput
                      onChange={handleChange}
                      name="weight"
                      type={"number"}
                      value={deliveryInfo?.weight}
                      disabled={order?.orderStatus !== "pickup"}
                      placeholder={
                        order?.deliveryType === "domestic"
                          ? `${order?.deliveryDetails[0]?.itemWeight} Kg`
                          : `${order?.packages[0]?.weight} Kg`
                      }
                    />
                  </p>
                </div>

                {order.deliveryType !== "domestic" && (
                  <div>
                    <p>
                      <strong
                        style={{
                          color: [
                            "processing",
                            "delivered",
                            "cancelled",
                            "verified",
                            "ordered",
                            "failed-after-payment",
                            "in-transit",
                          ].includes(order?.orderStatus)
                            ? "#b4b4b4"
                            : "",
                        }}
                      >
                        Width{" "}
                      </strong>
                    </p>
                    <p>
                      <EditableInput
                        onChange={handleChange}
                        name="width"
                        type={"number"}
                        value={deliveryInfo?.width}
                        placeholder={`${order?.packages[0]?.dimensions?.width}cm`}
                        disabled={[
                          "processing",
                          "delivered",
                          "cancelled",
                          "verified",
                          "ordered",
                          "failed-after-payment",
                          "in-transit",
                        ].includes(order?.orderStatus)}
                      />
                    </p>
                  </div>
                )}

                {order.deliveryType !== "domestic" && (
                  <div>
                    <p>
                      <strong
                        style={{
                          color: [
                            "processing",
                            "delivered",
                            "cancelled",
                            "verified",
                            "ordered",
                            "failed-after-payment",
                            "in-transit",
                          ].includes(order?.orderStatus)
                            ? "#b4b4b4"
                            : "",
                        }}
                      >
                        Length{" "}
                      </strong>
                    </p>
                    <p>
                      <EditableInput
                        onChange={handleChange}
                        name="length"
                        type={"number"}
                        value={deliveryInfo?.length}
                        placeholder={`${order?.packages[0]?.dimensions?.length}cm`}
                        disabled={[
                          "processing",
                          "delivered",
                          "cancelled",
                          "verified",
                          "ordered",
                          "failed-after-payment",
                          "in-transit",
                        ].includes(order?.orderStatus)}
                      />
                    </p>
                  </div>
                )}
                {order.deliveryType !== "domestic" && (
                  <div>
                    <p>
                      <strong
                        style={{
                          color: [
                            "processing",
                            "delivered",
                            "cancelled",
                            "verified",
                            "ordered",
                            "failed-after-payment",
                            "in-transit",
                          ].includes(order?.orderStatus)
                            ? "#b4b4b4"
                            : "",
                        }}
                      >
                        Height{" "}
                      </strong>
                    </p>
                    <p>
                      <EditableInput
                        onChange={handleChange}
                        name="height"
                        type={"number"}
                        value={deliveryInfo?.height}
                        placeholder={`${order?.packages[0]?.dimensions?.height}cm`}
                        disabled={[
                          "processing",
                          "delivered",
                          "cancelled",
                          "verified",
                          "ordered",
                          "failed-after-payment",
                          "in-transit",
                        ].includes(order?.orderStatus)}
                      />
                    </p>
                  </div>
                )}
                {order.deliveryType !== "domestic" && (
                  <div>
                    <p>
                      <strong
                        style={{
                          color: [
                            "processing",
                            "delivered",
                            "cancelled",
                            "verified",
                            "ordered",
                            "failed-after-payment",
                            "in-transit",
                          ].includes(order?.orderStatus)
                            ? "#b4b4b4"
                            : "",
                        }}
                      >
                        Description{" "}
                      </strong>
                    </p>
                    <p>
                      <EditableInput
                        onChange={handleChange}
                        name="description"
                        value={deliveryInfo?.description}
                        placeholder={order?.description}
                        disabled={[
                          "processing",
                          "delivered",
                          "cancelled",
                          "verified",
                          "ordered",
                          "failed-after-payment",
                          "in-transit",
                        ].includes(order?.orderStatus)}
                      />
                    </p>
                  </div>
                )}
                <div>
                  <p>
                    <strong>Delivery Estimate </strong>
                  </p>
                  <p>
                    &#8358;
                    {formatAmount(order?.estimatedCost / 100)}
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Total Cost {order?.deliveryType === "domestic" ? `(NGN)`: `(USD)`} </strong>
                  </p>
                  <p>{order?.deliveryType === "domestic" ? formatAmount((order?.monetaryAmount / 100) || 0): formatAmount((order?.monetaryAmount) || 0)}</p>
                </div>
                <div>
                  <p>
                    <strong>Extra Charge Comment </strong>
                  </p>
                  <p>{"Not available"}</p>
                </div>
                {order?.shipmentTrackingNumber && <div>
                  <p>
                    <strong>Tracking Number </strong>
                  </p>
                  <p>{order?.shipmentTrackingNumber}</p>
                </div>
                }
                {order?.deliveryType !== "domestic" &&
                  ![
                    "processing",
                    "delivered",
                    "cancelled",
                    "verified",
                    "ordered",
                    "failed-after-payment",
                    "in-transit",
                  ].includes(order?.orderStatus) && (
                    <div>
                      <p>
                        <strong>Get HScode </strong>
                      </p>
                      <p>
                        <input
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder="search"
                          style={{
                            borderCollapse: "collapse",
                            // width: "100px",
                            height: "5px",
                            borderRadius: "0.5em",
                            padding: "1em ",
                            border: "1px solid #c3c3c3",
                          }}
                        />
                        <button
                          onClick={() => handleHScode(search)}
                          style={{
                            backgroundColor: "transparent",
                            borderColor: "transparent",
                            marginLeft: "-2em",
                          }}
                        >
                          <img
                            src="/search.png"
                            alt="search"
                            style={{ marginBottom: "-0.3em" }}
                          />
                        </button>
                      </p>
                    </div>
                  )}
              </div>
              {order?.deliveryType !== "domestic" &&
                ![
                  "processing",
                  "delivered",
                  "cancelled",
                  "verified",
                  "ordered",
                  "failed-after-payment",
                  "in-transit",
                ].includes(order?.orderStatus) && (
                  <div>
                    <p>
                      <strong>Item Description</strong>
                    </p>
                    <div
                      style={{
                        width: "494px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>
                        <div className="deliverydetails">
                          {descriptions.map((items: any, index: any) => (
                            <Details
                              key={index}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "20px",
                              }}
                            >
                              <Summary>
                                Item Description
                                <img src="/dropdown.svg" alt="drop" />
                                <div
                                  style={{
                                    position: "absolute",
                                    left: "49%",
                                  }}
                                >
                                  <button
                                    onClick={AddMore}
                                    style={{
                                      backgroundColor: "transparent",

                                      border: "1px solid #c3c3c3",

                                      alignItems: "center",
                                      marginRight: "5px",
                                      borderRadius: "5px",
                                      // padding: "1em",
                                    }}
                                  >
                                    <img
                                      src="/add.png"
                                      alt="search"
                                      style={{ padding: "auto 5px" }}
                                    />
                                  </button>
                                  <button
                                    onClick={() => removeFormFields(index)}
                                    style={{
                                      backgroundColor: "transparent",

                                      border: "1px solid #c3c3c3",
                                      alignItems: "center",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <img
                                      src="/minus.png"
                                      alt="search"
                                      style={{ padding: "auto 5px" }}
                                    />
                                  </button>
                                </div>
                              </Summary>
                              <div className="detailsDropdown">
                                <div className="editItem">
                                  <label>{Object.keys(items)[0]}</label>

                                  <input value={items.description} />
                                </div>
                                <div
                                  className="editItem"
                                  style={{ cursor: "pointer", color: "" }}
                                >
                                  {hscodeData.map((data: any) => {
                                    return (
                                      <Predictions
                                        onClick={() => {
                                          setDescription((prev: any) => {
                                            const updatedArray = [...prev]; // Create a shallow copy of the previous state array
                                            const updatedItem =
                                              updatedArray[index]; // Get the object to be updated

                                            // Update the specific properties of the object
                                            updatedItem.hsCode = data?.hs_code;
                                            updatedItem.description =
                                              data?.sub_category;
                                            return updatedArray;
                                          });
                                          setSearch("");
                                        }}
                                      >
                                        {data?.keywords}
                                      </Predictions>
                                    );
                                  })}
                                </div>
                                <div className="editItem">
                                  <label>{Object.keys(items)[1]}</label>

                                  <input value={items.hsCode} />
                                </div>

                                <div className="editItem">
                                  <label>{Object.keys(items)[2]}</label>

                                  <input
                                    name="netWeight"
                                    onChange={(e) =>
                                      handleDescriptionChange(index, e)
                                    }
                                    value={descriptions[index].netWeight}
                                  />
                                </div>
                                <div className="editItem">
                                  <label>{Object.keys(items)[3]}</label>

                                  <input
                                    name="grossWeight"
                                    onChange={(e) =>
                                      handleDescriptionChange(index, e)
                                    }
                                    value={descriptions[index].grossWeight}
                                  />
                                </div>
                                <div className="editItem">
                                  <label>{Object.keys(items)[5]} (USD)</label>

                                  <input
                                    name="price"
                                    type={"number"}
                                    onChange={(e) =>
                                      handleDescriptionChange(index, e)
                                    }
                                    value={descriptions[index].price}
                                  />
                                </div>
                                <div className="editItem">
                                  <label>
                                    {Object.keys(items)[6]}/number of pieces
                                  </label>

                                  <input
                                    name="value"
                                    type={"number"}
                                    onChange={(e) =>
                                      handleDescriptionChange(index, e)
                                    }
                                    value={descriptions[index].value}
                                  />
                                </div>
                              </div>
                            </Details>
                          ))}
                        </div>
                      </p>
                    </div>
                  </div>
                )}
            </div>
          </Editdetails>
          <StatusDetails>
            <div className="status">
              <h4>Status Update</h4>
              <div className="grid">
                {order?.order_status_history.map((status: any) => (
                  <div>
                    <p key={status} style={{ color: "#F66D00" }}>
                      {switchOrderStatus(status?.status)}
                    </p>
                    <p>{`${switchUpdate(status?.status)} ${
                      status?.admin?.name || status?.rider?.fullName || " "
                    } at ${getDate(status?.time)} ${getTime(
                      status?.time
                    )} `}</p>
                  </div>
                ))}
              </div>
            </div>
          </StatusDetails>

          <Orderdetails>
          {order?.completedFeedback && 
           <div className="pickupdetails pickup ">
              <h4>Customer Feedback</h4>
              <div className="grid">
                <div>
                  <p>
                    <strong>Delivery Service </strong>
                  </p>
                  <p>
                    <SvgIcon color="#F66D00" name={order?.deliveryServiceRating ? 'thumbsup' : 'thumbsdown'}/>
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Delivery Staff</strong>
                  </p>
                  <p>
                  <SvgIcon color="#F66D00" name={order?.deliveryStaffRating ? 'thumbsup' : 'thumbsdown'}/>
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Pickup/ Delivery Rider </strong>
                  </p>
                  <p>
                  <SvgIcon color="#F66D00" name={order?.riderRating ? 'thumbsup' : 'thumbsdown'}/>
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Ratings</strong>
                  </p>
                  <p>
                    {order?.feedbackRating}
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Comment </strong>
                  </p>
                  <p>
                    {order?.feedbackSuggestion}
                  </p>
                </div>
              </div>
            </div>}
            <div className="pickupdetails pickup ">
              <h4>Pickup Details</h4>
              <div className="grid">
                <div>
                  <p>
                    <strong>Name </strong>
                  </p>
                  <p>
                    {order?.pickUpDetails
                      ? order?.pickUpDetails[0]?.senderName
                      : "Not available"}
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Phone Number </strong>
                  </p>
                  <p>
                    {order?.pickUpDetails
                      ? order?.pickUpDetails[0]?.phoneNumber
                      : "Not available"}
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Address </strong>
                  </p>
                  <p>
                    {order?.pickUpDetails
                      ? order?.pickUpDetails[0]?.fullAddress
                      : "Not available"}
                  </p>
                </div>
                <div>
                  <p>
                    <strong>State </strong>
                  </p>
                  <p>
                    {order?.pickUpDetails
                      ? order?.pickUpDetails[0]?.state
                      : "Not available"}
                  </p>
                </div>
              </div>
            </div>
            <div className="deliverydetails delivery ">
              <h4>Delivery Details</h4>
              <div className="grid">
                <div>
                  <p>
                    <strong>Name </strong>
                  </p>
                  <p>
                    {order?.deliveryDetails
                      ? order?.deliveryDetails[0]?.recipientName
                      : "Not available"}
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Phone Number </strong>
                  </p>
                  <p>
                    {order?.deliveryDetails
                      ? order?.deliveryDetails[0]?.phoneNumber
                      : "Not available"}
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Address </strong>
                  </p>
                  <p>
                    {order?.deliveryDetails
                      ? order?.deliveryDetails[0]?.fullAddress
                      : "Not available"}
                  </p>
                </div>
                <div>
                  <p>
                    <strong>State </strong>
                  </p>
                  <p>
                    {order?.deliveryDetails[0]?.state ||
                      order?.deliveryDetails[0]?.cityName ||
                      "Not available"}
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Item Description </strong>
                  </p>
                  <p>
                    {order?.deliveryDetails[0]?.itemDescription ||
                      order?.description ||
                      "Not available"}
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Extra Details </strong>
                  </p>
                  <p>
                    {order?.deliveryDetails[0]?.additionalDetails ||
                      "Not available"}
                  </p>
                </div>
              </div>
            </div>
            {order?.lineItems.length > 0 && (
              <div className="deliverydetails delivery ">
                <h4>Item Description</h4>
                {order?.lineItems.map((items: any, index: any) => {
                  return (
                    <div className="grid">
                      <div>
                        <p>
                          <strong>Description </strong>
                        </p>
                        <p>{items?.description}</p>
                      </div>
                      <div>
                        <p>
                          <strong>HsCode</strong>
                        </p>
                        <p>{items?.hsCode}</p>
                      </div>
                      <div>
                        <p>
                          <strong>Price (USD)</strong>
                        </p>
                        <p>{items?.price}</p>
                      </div>
                      <div>
                        <p>
                          <strong>Gross Weight </strong>
                        </p>
                        <p>{items?.grossWeight}</p>
                      </div>
                      <div>
                        <p>
                          <strong>Net Weight</strong>
                        </p>
                        <p>{items?.netWeight}</p>
                      </div>
                      <div>
                        <p>
                          <strong>Number of Items(PCS)</strong>
                        </p>
                        <p>{items?.value}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </Orderdetails>
          {/* <Orderdetails>
            <div className="pickupdetails pickup ">
              <h4>Item Description</h4>
              <div className="grid">
                <div>
                  <p>
                    <strong>Name </strong>
                  </p>
                  <p>
                    {order?.pickUpDetails
                      ? order?.pickUpDetails[0]?.senderName
                      : "Not available"}
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Phone Number </strong>
                  </p>
                  <p>
                    {order?.pickUpDetails
                      ? order?.pickUpDetails[0]?.phoneNumber
                      : "Not available"}
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Address </strong>
                  </p>
                  <p>
                    {order?.pickUpDetails
                      ? order?.pickUpDetails[0]?.fullAddress
                      : "Not available"}
                  </p>
                </div>
                <div>
                  <p>
                    <strong>State </strong>
                  </p>
                  <p>
                    {order?.pickUpDetails
                      ? order?.pickUpDetails[0]?.state
                      : "Not available"}
                  </p>
                </div>
              </div>
            </div>
          </Orderdetails> */}
          <Feedback
            message={feedbackModal?.message}
            feedback={feedbackModal?.type}
            open={feedbackModal?.open}
            close={() =>
              setFeedbackModal((prev) => {
                return { ...prev, open: false };
              })
            }
          />
          <BasicModal
            content={
              <ModalContent>
                <img src={VerifyImage} alt="verify order" />
                <h1>Are you sure you want to verify this order?</h1>
                <p>
                  Verifying order sends price to customer and this action cannot
                  be undone
                </p>
                <div className="orderidbtn">
                  <button
                    style={{
                      backgroundColor: "#F66D00",
                      borderColor: "#F66D00",
                      color: "#fff",
                      border: "none",
                    }}
                    onClick={handleVerify}
                  >
                    {feedbackModal?.loading ? <Spinner /> : "Yes, Verify"}
                  </button>
                  <button
                    style={{
                      border: "1px solid #F66D00",
                      backgroundColor: "#ffffff",
                      color: "#F66D00",
                    }}
                    onClick={() => setOpenModal(false)}
                  >
                    No, Cancel
                  </button>
                </div>
              </ModalContent>
            }
            open={openModal}
            close={() => setOpenModal(false)}
            width={"400px"}
          />
        </Wrapper>
      )}
    </Body>
  );
};

const Body = styled.main`
  display: flex;
  width: 100%;
  /* height: 100vh; */
  justify-content: space-between;
  margin: 0%;
  background-color: #fafafa;

  .sidebar {
    width: 10%;
  }

  @media (max-width: 1024px) {
    .sidebar {
      width: 20%;
      background-color: #ffffff;
    }
  }
`;
const Wrapper = styled.main`
  width: 90%;
  /* height: 100vh; */
  /* overflow-y: scroll; */
  background-color: #fafafa;

  margin-left: 5%;
  /* gap: 20px; */
  @media (max-width: 1024px) {
    width: 80%;
    margin: 0%;
  }
  .international {
    display: grid;
    grid-template-columns: 1fr 2fr;
    row-gap: 20px;
    column-gap: 80px;
    @media (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 20px;
    column-gap: 80px;
    @media (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  h4 {
    margin: 2em auto;
  }
`;

const ModalContent = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  align-items: center;
  padding: 50px 0px;

  p {
    width: 80%;
    margin: auto;
    text-align: center;
    font-size: 16px;
  }

  .orderidbtn {
    display: flex;
    gap: 20px;

    button {
      padding: 7px 30px;
      border-radius: 4px;
    }
  }
`;

const EditableInput = styled.input`
  border: none;
  color: #333333;
  outline: none;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    /* color: #4f4f4f; */
    opacity: 1; /* Firefox */
  }
  :disabled {
    color: #b4b4b4;
  }
`;
const Predictions = styled.p`
  padding: 10px 20px;
  border: 0.5px solid #ffffff;
  cursor: pointer;
  background: #000000;
  opacity: 0.8;
  color: #ffffff;
  width: 100%;
  @media screen and (max-width: 600px) {
    padding-left: 20px;
  }
`;
const Summary = styled.summary`
  color: #141414;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  height: 16px;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid #c3c3c3;
  padding: 10px;
  border-radius: 0.5em;
`;

const Details = styled.details`
  width: 400px;
  border-radius: 1em;
  /* padding: 0.5em; */
  .detailsDropdown {
    width: 96%;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 8px;
    border-radius: 5px;
    background-color: #fbfbfb;
  }
  .editItem {
    width: 90%;
    margin: 5px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .editItem label {
    text-transform: capitalize;
  }
  .editItem input {
    width: 100%;
    border-radius: 4px;
    /* background-color: transparent; */
    padding: 10px;
    border: 1px solid #c3c3c3;
  }
`;
const EditableDropdown = styled.select`
  border: none;
  color: #333333;
  outline: none;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #4f4f4f;
    opacity: 1; /* Firefox */
  }

  :disabled {
    color: #b4b4b4;
  }
`;

const Orderdetails = styled.section`
  width: 100%;
  background-color: #fafafa;

  .pickup {
    background-color: #ffffff;

    width: 90%;
    margin: 1em auto;
    padding: 20px;
    border-radius: 0.5em;
  }
  .delivery {
    background-color: #ffffff;
    width: 90%;
    margin: 1em auto;

    padding: 20px;
    border-radius: 0.5em;
  }

  > h2 {
    /* text-transform: uppercase; */
  }
  > div {
    /* display: flex; */
    > div {
      /* width: 50%; */
      > p {
        margin-top: 10px;
        > strong {
          font-family: "Red Hat Display";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 21px;

          /* Gray 2 */

          color: #4f4f4f;
        }
      }
    }
  }
`;

const StatusDetails = styled.section`
  width: 100%;
  background-color: #fafafa;

  .status {
    background-color: #ffffff;

    width: 90%;
    margin: 1em auto;
    padding: 20px;
    border-radius: 0.5em;
  }
  > p {
    font-size: 14px;
    /* line-height: 19px; */
    color: #4f4f4f;
    /* margin-top: 10px; */
  }

  span {
    color: #f66d00;
    font-weight: 500;
  }
`;

const Editdetails = styled.section`
  background: #ffffff;
  width: 90%;
  margin: 1em auto;
  padding: 20px;
  border-radius: 0.5em;

  .orderid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1em;
    margin-bottom: 2em;
    /* text-align: center; */
    padding: 0%;
  }
  .orderidbtn {
    width: 30%;
    display: flex;
    /* margin: 1em; */
  }
  .orderidbtn button {
    width: 160px;
    height: 50px;
    margin-left: 1em;
    font-size: 12px;
    font-weight: 800;
    padding: 0%;
    border-radius: 0.5em;
  }
  .orderidbtn button:hover {
    font-size: 14px;
  }

  .orderidbtn button:disabled {
    opacity: 0.4;
  }
  .orderinfo {
    width: 100%;
    /* margin: 1em auto; */
    /* padding: 20px; */
  }
  h2 {
    /* text-transform: uppercase; */
    text-align: center;
    padding-top: 1em;
  }
  > div {
    > div {
      > p {
        margin-top: 10px;
      }
      h4 {
        margin-top: 10px;
      }
    }
  }
`;

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  width: 200px;
  height: 45px;
  background: #04030b;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  border: none;
  margin-top: 20px;
`;

const Button2 = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  width: 200px;
  height: 35px;
  background: #ffffff;
  border: 1px solid #f66d00;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #f66d00;
`;

const Profile = styled.div`
  display: flex;
  gap: 10px;
  cursor:pointer;
  .name {
    color: #000000;
    font-weight: 600;
    text-transform: capitalize;
  }
  .role {
    color: #f66d00;
  }
`;
