import React from 'react'
import { Pricing } from './Pricing';

export const InterCountry = () => {
  return (
    <>
      <Pricing />
      <Pricing />
      <Pricing />
      <Pricing />
      <Pricing />
      <Pricing />
    </>
  );
}
